<div [ngClass]="{'container-lg': containerLg, 'container-sm': containersm}">
  <nz-list class="demo-loadmore-list" [nzLoading]="initLoading">
    <nz-list-item *ngFor="let item of products">
      <ng-container>
        <div class="cont-img">
          <nz-list-item-extra *ngIf="item.path">
            <img alt="logo" [src]="item.path" />
          </nz-list-item-extra>
          <nz-list-item-extra *ngIf="!item.path">
            <img alt="logo" src="../../../assets/img/market/10.jpg" />
          </nz-list-item-extra>
        </div>
        <nz-list-item-meta [nzDescription]="item.description.slice(0, desc)">
          <nz-list-item-meta-title>
            <a href="https://ng.ant.design">{{ item.name }}</a>
          </nz-list-item-meta-title>
        </nz-list-item-meta>
      </ng-container>
      <!-- <nz-skeleton *ngIf="item.loading" [nzAvatar]="true" [nzActive]="true" [nzTitle]="false"
        [nzLoading]="true"></nz-skeleton> -->
    </nz-list-item>
    <div class="loadmore" nz-list-load-more>
      <span class="fewer" *ngIf="productNumber != 10"><button nz-button *ngIf="!loadingMore" (click)="fewerItems()">Vedi
          meno</button></span>
      <button nz-button *ngIf="!loadingMore" (click)="onLoadMore()">Vedi più elementi</button>
    </div>
  </nz-list>
</div>