import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent implements OnInit {
  @Input() position: 'left' | 'right' = 'right';
  @Input() isColorCollapse: boolean = false
  @Input() header = '';
  @Input() nzGhost = true;
  @Input() headerArray = new Array();
  @Input() active = true;

  isPanelCollapsed: boolean = true;


  constructor() { }

  ngOnInit() {
  }

  togglePanelCollapse(event:Event): void {    
    event.stopPropagation()    
    this.isPanelCollapsed = !this.isPanelCollapsed;
  }

}
