import { Component } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, NonNullableFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  
  validateForm: FormGroup<{
    // userName: FormControl<string>;
    // email: FormControl<string>;
    current_password: FormControl<string>;
    password: FormControl<string>;
    password_confirmation: FormControl<string>;
  }>;

  // formProject: FormGroup = this.fmb.group({
  //   name: [null, Validators.required],
  //   category: [null, Validators.required],
  //   technologies: [[], Validators.required],
  //   start_date: [null, Validators.required],
  //   end_date: [null],
  //   description: [null, Validators.required],
  //   market_id: [null, Validators.required],
  //   company_id: [null, Validators.required]
  // });

  constructor(
    private fb: NonNullableFormBuilder,
    private fmb: FormBuilder,
    private authService: AuthService
    ) {
    this.validateForm = this.fb.group({
      // userName: ['', [Validators.required], [this.userNameAsyncValidator]],
      // email: ['', [Validators.email, Validators.required]],
      current_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [this.confirmValidator]],
    });
  }

  ngOnInit(): void {}

  submitForm(): void {
    let obj = {
      current_password: this.validateForm.value.current_password,
      password: this.validateForm.value.password,
      password_confirmation: this.validateForm.value.password_confirmation
    }
    this.authService.updatePassword(obj).subscribe(res => {
      this.ngOnInit();
    })
    console.log('submit', this.validateForm.value);
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.validateForm.controls.password_confirmation.updateValueAndValidity());
  }

  userNameAsyncValidator: AsyncValidatorFn = (control: AbstractControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

    confirmValidator: ValidatorFn = (control: AbstractControl) => {
      if (!control.value) {
        return { error: true, required: true };
      } else if (control.value !== this.validateForm.controls.password.value) {
        return { password_confirmation: true, error: true };
      }
      return {};
    };
}
