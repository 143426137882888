import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';
@Component({
  selector: 'app-distributor-lots',
  templateUrl: './distributor-lots.component.html',
  styleUrls: ['./distributor-lots.component.scss']
})
export class DistributorLotsComponent {
  @Input() actor!: number;
  status_card: any;
  placeholder: string = 'Seleziona lotto';
  selectedField!: string;
  fields: any;
  form: FormGroup;

  constructor(
    private _productorService: ComponentService,
    private _loggingService: LoggingService,
    private router: Router,
    private fb: FormBuilder,
    private _utils: Utils
  ) {
    this.form = this.fb.group({
      lotto: ''
    });
  }

  ngOnInit() {
    this.getMyBatches();

  }


  getMyBatches() {
    this._productorService.getMyBatchesTracking().subscribe(r => {
      this.fields = r.data;
      this.form.patchValue({ lotto: this.fields[0] });
      this.selectedField = this.fields[0];
      this.getMyBatch();
    })
  }

  getMyBatch() {

    switch (this.actor) {
      case 6:
        this.form.value.lotto = this.selectedField;
        this._productorService.distributorMyBatch(this.form.value.lotto).subscribe((res: { data: any; }) => {
          this.status_card = res.data.steps;
          console.log('this.status_card', this.status_card);
    })
        break;

        case 5:
        this.form.value.lotto = this.selectedField;
        this._productorService.getYourTransformation(this.form.value.lotto).subscribe((res: { data: any; }) => {
          this.status_card = res.data.steps;
          console.log('this.status_card', this.status_card);
    })
        break;

      default:
        break;
    }


  }

  explodeWidget() {
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-distributor-lots'
    }
    this._loggingService.logClick(data).subscribe(r => {
      console.log(r);

    })
  }

  explodeWidget2() {
    this._utils.handleWidgetClick('/app/your-treatments', 23);
    this.router.navigate(['app/your-treatments']);
  }
}
