import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { BehaviorSubject } from 'rxjs';
import { ComponentService } from 'src/app/components/component.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipping-status',
  templateUrl: './shipping-status.component.html',
  styleUrls: ['./shipping-status.component.scss']
})
export class ShippingStatusComponent {
  percentage = 70;
  startAddress: string = 'Viale Africa, 31';
  endAddress: string = 'Via Garibaldi, 514';
  formShipment!: FormGroup;
  temperatureWithDegree: string = '';
  size: NzButtonSize = 'small';
  placeholder: string = 'Seleziona un mezzo';
  temperatureInput: any;
  selectedField!: string;
  product: any;
  fields: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  citrus_type = [
    { icon: "/assets/icon/mini-orange.svg", category: 1 },
    { icon: "/assets/icon/mini-lemon.svg", category: 2 }
  ];
  array_select = [
    { value: "Vagone refrigerato", label: 'Vagone refrigerato' },
    { value: "Furgone", label: 'Furgone' },
    { value: "Moto ape", label: 'Moto ape' },
    { value: "Camion refrigerato", label: 'Camion refrigerato' },
  ];

  constructor(
    private fb: FormBuilder,
    private _services: ComponentService
  ) {

    this.formShipment = this.fb.group({
      lotto: [null],
      mezzo: [null],
      temperatura: [null],
    });

    this.formShipment.get('temperatura')?.valueChanges.subscribe(value => {
      this.temperatureInput = value ? `${value}°` : '';
    });
  }

  ngOnInit() {
    this.getData();
  }


  // patchForm() {
  //   this.formShipment.value.lotto = this.product.batch_id;
  //   this.formShipment.value.temperatura = this.product.temperature;
  //   this.formShipment.value.mezzo = this.product.transport
  // }

  patchForm() {
    this.formShipment.patchValue({
      lotto: this.product.batch_id,
      mezzo: this.product.transport,
      temperatura: this.product.temperature
    });
  }


  getData() {
    this._services.lastDistributor().subscribe(res => {
      this.product = res.data;
      console.log('dati della GET', res.data);

      // this.formShipment.value.lotto = res.data.batch_id;
      // this.formShipment.value.temperatura = res.data.temperature;
      // this.formShipment.value.mezzo = res.data.transport;

      this.formShipment.patchValue({
        lotto: res.data.batch_id,
        mezzo: res.data.transport,
        temperatura: res.data.temperature
      });
    })
  }

  closeModal() {
    this.formShipment.reset();
  }



  getIconPath(category: number): string {
    const citrus = this.citrus_type.find(type => type.category == category);
    return citrus ? citrus.icon : '';
  }

  save() {
    const temperatureValue = this.formShipment.value.temperatura;
    const mezzoSelezionato = this.formShipment.value.mezzo;

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    if (!mezzoSelezionato) {
      Toast.fire({
        icon: "error",
        title: "Seleziona un mezzo di trasporto"
      });
      return;
    }

    if (!temperatureValue) {
      Toast.fire({
        icon: "error",
        title: "Campo temperatura vuoto"
      });
      return;
    }

    if (temperatureValue > 40) {
      Toast.fire({
        icon: "error",
        title: "La temperatura non può essere superiore ai 40°"
      });
      return;
    }

    if (temperatureValue < -40) {
      Toast.fire({
        icon: "error",
        title: "La temperatura non può essere inferiore ai -40°"
      });
      return;
    }



    this._services.updateLastShippingDistributor(this.product.batch_id, { transport: this.formShipment.value.mezzo, temperature: this.formShipment.value.temperatura }).subscribe(res => {
      Toast.fire({
        icon: "success",
        title: "Mezzo inserito correttamente"
      });
      this.getData();
    })

  }


}
