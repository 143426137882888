import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { ComponentService } from 'src/app/components/component.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-your-treatments',
  templateUrl: './your-treatments.component.html',
  styleUrls: ['./your-treatments.component.scss'],
})
export class YourTreatmentsComponent implements OnInit {
  searchControl: string = '';
  searchForDate: string | null = null;

  date: any = this.setCurrentDate();
  rangeDate = null;
  searchDebounceTimer: any = 600;
  treatments: any;
  isVisible: boolean = false;
  isOkLoading = false;
  notes: string | null = null;
  selectedItem: any;

  productsType = [
    {
      value: 'Arance',
      path: '../../../../assets/icon/modal-orange-icon.svg',
    },
    {
      value: 'Limoni',
      path: '../../../../assets/icon/modal-lemon-icon.svg',
    },
  ];

  constructor(
    private _componentService: ComponentService,
    private fb: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.getYourTreatments(this.formatDate(this.date), null);
  }

  onChange(result: Date): void {
    this.date = result;
    this.getYourTreatments(this.formatDate(this.date), this.searchForDate);
  }

  setCurrentDate(): Date {
    const currentDate = moment().toDate();
    return currentDate;
  }

  formatDate(date: Date | string | null): string | null {
    if (!date) return null;
    return moment(date).format('YYYY-MM-DD');
  }

  getYourTreatments(date: Date | string | null, searchValue: string | null) {
    console.log('date:', date, ' search value:', searchValue);
    this._componentService
      .getYourTreatments(date, searchValue)
      .subscribe((response) => {
        if (response.code === 200) {
          this.treatments = response.data;
        }
      });
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  onSearch(searchValue: string) {
    clearTimeout(this.searchDebounceTimer);
    this.searchDebounceTimer = setTimeout(() => {
      if (searchValue.length >= 3) {
        this.getYourTreatments(this.formatDate(this.date), searchValue);
      } else if (searchValue.length === 0) {
        this.getYourTreatments(this.formatDate(this.date), null);
      }
    }, 600);
  }

  noteWriting(searchValue: string) {
    clearTimeout(this.searchDebounceTimer);
    this.searchDebounceTimer = setTimeout(() => {
      if (searchValue.length >= 3) {
        this.notes = searchValue;
      } else if (searchValue.length === 0) {
        this.notes = null;
      }
    }, 1600);
  }

  openModal(treat: any) {
    this.isVisible = true;
    this.selectedItem = treat;
    console.log(treat);
    this.notes = treat.data.Nota;
  }

  closeModal() {
    this.isVisible = false;
  }

  handleOk() {
    let obj = {
      note: this.notes,
    };
    this._componentService
      .addNotesToTreatment(this.selectedItem.id, obj)
      .subscribe((response) => {
        Swal.fire({
          title: "Nota aggiornata ",
          text: "Nota modificata",
          html: '<img src="assets/icon/success-icon.svg" alt="Custom Icon">',
          showConfirmButton: false,
          timer: 2000
        });
        this.closeModal();
        this.ngOnInit();
      });
  }

  getProductImagePath(category?: string): string {
    const desc = category === '1' ? 'Arance' : 'Limoni';
    const product = this.productsType.find((p) => p.value === desc);
    return product ? product.path : '';
  }

  transform(value: string | null): string | null {
    if (!value) {
      return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
