<div *ngIf="show" class=" container">
  <div class=" title">
    <h4 class="header-widget">Scarto del giorno</h4>
  </div>
  <div class="d-flex align-items-center">
    <div class="data-padding pb pl">
      <div class="d-flex flex-column">
        <span class="text-mini-widget">Arance</span>
        <div>
          <span class="data-mini-widget">{{data.arancia}}</span> kg
        </div>
      </div>
    </div>
    <div class=" pb">
      <div class="d-flex flex-column">
        <span class="text-mini-widget">Limoni</span>
        <div>
          <span class="data-mini-widget">{{data.limone}}</span> kg
        </div>
      </div>
    </div>
  </div>
</div>