<div *ngIf="showWidget">
  <div class="d-flex justify-content-between widget-container">
    <div>
      <h4  class="header-widget">Meteo</h4>
    </div>
    <div class="select-field d-flex align-items-center">
      <nz-select
      [nzPlaceHolder]="placeholder"
      [(ngModel)]="selectedField"
      (ngModelChange)="getWeather($event)">
      <nz-option *ngFor="let field of fields"
        [nzLabel]="field.label"
        [nzValue]="field"
        class="color">
        {{ field.label }}
      </nz-option>
    </nz-select>
    </div>
    <span style="cursor: pointer;" (click)="explodeWidget2()">
      <img src="/assets/img/arrow.svg" alt="freccia-puntatrice">
    </span>
  </div>
  <div class="d-flex flex-row align-items-center lh-1">
    <span class="fs-1">{{temp_max|async}}°</span>
    <span class="fs-5">/ {{temp_min|async}}°</span>
  </div>
  <div>
    <span class="fs-12">{{ sky | async}}</span>
  </div>
</div>
