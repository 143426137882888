import { Component, OnInit } from '@angular/core';
import { ChartData, ChartEvent, ChartOptions, ChartType, Plugin, PluginChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/helpers/utils';


@Component({
  selector: 'app-doughnut-and-pie-chart',
  templateUrl: './doughnut-and-pie-chart.component.html',
  styleUrls: ['./doughnut-and-pie-chart.component.scss'],

})
export class DoughnutAndPieChartComponent implements OnInit {

  text: any;
  legendData: any;
  showWidget = false;
  noData!: string;

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ['#EF7F00', '#FAC36A'],
        hoverOffset: 4,
      }
    ]
  };
  public doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '70%',
    radius: '85%',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        // position: 'top',
        display: false
      },
    },

  };

  public doughnutChartPlugins: Plugin[] = [{
    id: 'customPlugin',
    beforeDraw: (chart) => {
      const width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      const fontSize = Math.min(height, width) * 0.15;

      ctx.font = fontSize + 'px sans-serif';
      ctx.textBaseline = 'middle';

      const text = this.text,
        textWidth = ctx.measureText(text).width,
        textX = (width - textWidth) / 2,
        textY = height / 2;

      ctx.fillText(text, textX, textY);

      const subText = "Ettari coltivati";
      const subTextFontSize = fontSize * 0.3;
      ctx.font = subTextFontSize + 'px sans-serif';
      const subTextWidth = ctx.measureText(subText).width;
      const subTextX = (width - subTextWidth) / 2;
      const subTextY = textY + fontSize * 0.75;

      ctx.fillText(subText, subTextX, subTextY);

      ctx.save();
    }
  }];


  constructor(private _chartService: ChartService, private _loggingService: LoggingService, private router: Router,
    private _utils:Utils) { }

  ngOnInit() {
    this.getPlantsWidgetData();
  }

  getPlantsWidgetData() {
    this._chartService.getPlantsWidgetData().subscribe(r => {
      this.text = r.total;
      if (this.text > 0) {
        this.doughnutChartData.datasets[0].data = r.data.widgetData.datasets.data;
        this.doughnutChartData.labels = r.data.widgetData.labels;
        this.doughnutChartData.datasets[0].backgroundColor = r.data.widgetData.datasets.backgroundColor;
        this.legendData = r.data.percentage;
        this.showWidget = true;
      }else {
        // this.noData = 'No data'
        this.doughnutChartData.datasets[0].data = [0];
        this.doughnutChartData.labels = ['Nessun dato'];
        // this.doughnutChartData.datasets[0].backgroundColor = r.data.widgetData.datasets.backgroundColor;
        this.legendData = [
          {
            name: "Nessun dato",
            color: "rgb(250, 195, 106)",
            total: 0,
            percentage: 0
          }
        ]
        this.showWidget = true;
      }

      // this.legendData = r.data.percentage;

    });
  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 1);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-doughnut-and-pie-chart'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);

    })
  }

}
