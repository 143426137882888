import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/shared/models/user-role.enum';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { ComponentService } from '../component.service';
import Swal from 'sweetalert2';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  role_id: string = localStorage.getItem('role_id') ?? '';

  isVisible: boolean = false;
  isOkLoading = false;
  type: any;
  selectedbatch = null;
  selectedRole = null;
  dataForm!: FormGroup;
  userId: any;
  widgetsFromPredict : [] = [];

  productsType = [
    {
      id: 1,
      path: '../../../../../../assets/icon/modal-orange-icon.svg',
      selected: false
    },
    {
      id: 2,
      path: '../../../../../../assets/icon/modal-lemon-icon.svg',
      selected: false
    }
  ]

  batchToSelect: any;
  roleNaleLabel!: any;
  roleToSelect: any;
  currentRole: any
  recevingUser: any;

  // siderWidth = '100%'
  public sections: {
    icon: string | null;
    label: string;
    link: string;
    role_id?: string[] | null;
  }[] = [
      {
        icon: '../../../assets/icon/dashboard.svg',
        label: 'Dashboard',
        link: '/app/dashboard',
        role_id: ['1', '2', '3', '4', '5', '6'],
      },
      {
        icon: '../../../assets/icon/quaderno-attivita.svg',
        label: 'Quaderno delle attività',
        link: '/app/producer/reports',
        role_id: ['2'],
      },
      {
        icon: '../../../assets/icon/statistiche.svg',
        label: 'Statistiche',
        link: '/app/statistics',
        role_id: ['1', '2'],
      },
      {
        icon: '../../../assets/icon/prodotti.svg',
        label: 'Prodotti',
        link: '/app/vendor/your-products',
        role_id: ['4'],
      },
      {
        icon: '../../../assets/icon/storico_lotti.svg',
        label: 'Storico lotti',
        link: '/app/lot-history',
        role_id: ['1', '2', '3', '4', '5', '6'],
      },
      {
        icon: '../../../assets/icon/monitoraggio_spedizioni.svg',
        label: 'Monitoraggio spedizioni',
        link: '/shipment-tracking',
        role_id: ['2', '3', '4', '5', '6'],
      },
      {
        icon: '../../../assets/icon/trattamenti.svg',
        label: 'Trattamenti',
        link: '/app/your-treatments',
        role_id: ['5'],
      },
      {
        icon: '../../../assets/icon/feedback.svg',
        label: 'Feedback',
        link: '/app/feedback',
        role_id: ['1', '2', '3', '4', '5', '6'],
      },
      {
        icon: '../../../assets/icon/magazzino.svg',
        label: 'Magazzino',
        link: '/app/warehouse/' + this.role_id,
        role_id: ['5', '6'],
      },
      {
        icon: '../../../assets/icon/trasferisci_dati.svg',
        label: 'Trasferimento dati',
        link: 'trasferimento',
        role_id: ['2', '5', '6'],
      },
    ];

  public sections2: {
    icon: string | null;
    label: string;
    link?: string;
    role_id?: string[] | null;
    callback?: () => void;
  }[] = [
      { icon: '../../../assets/icon/logout.svg', label: 'Esci', callback: () => this.logout() },
    ];

  public admin_sections: {
    icon: string | null;
    label: string;
    link: string;
  }[] = [
      { icon: './../../assets/icon/gestione_utenti.svg', label: 'Gestione utenti', link: '/admin/user-management' },
      {
        icon: './../../assets/icon/gestioni_widgets.svg',
        label: 'Gestione widgets',
        link: '/admin/widget-management',
      },
    ];

  currentUser: User | undefined = undefined;
  roles = UserRole;

  constructor(
    private menuService: MenuService,
    private _componentService: ComponentService,
    private router: Router,
    private _auth: AuthService,
    private fb: FormBuilder,
    private _utils: Utils
  ) {
    this.currentUser = this._auth.currentUser.value;
  }

  ngOnInit(): void {
    this.getUser()
    this.getUserRole();
    this.dataForm = this.fb.group({
      lotto: [null, Validators.required],
      company: [null, Validators.required],
    });

    if (this.role_id == '2') {
      this.sections.splice(1, 0, {
        icon: 'file-text',
        label: 'Quaderno delle attività',
        link: '/app/producer/reports',
      });
      this.sections.splice(1, 0, {
        icon: '../../../assets/icon/monitoraggio_spedizioni.svg',
        label: 'Monitoraggio spedizioni',
        link: '/shipment-tracking',
      });
    }
    if (this.role_id == '5') {
      this.sections.splice(1, 0, {
        icon: 'file-text',
        label: 'Storico lotti',
        link: '/app/producer/historical-lots',
      });
    }
  }

  goToPath(url: string) {
    if (url == 'trasferimento') {
      this.showModal()
    } else {
      this.router.navigate([url]);
    }

  }

  isPathActive(url: string): boolean {
    if (this.router.url.startsWith(url)) {
      return true;
    }
    return false;
  }

  logout() {
    this._auth.logout().subscribe((res) => {
      this.deleteCookie('access_token');
      this._auth.currentUser.next(null);

      this.router.navigate(['/auth/login']);
    });
  }

  deleteCookie(name: string) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() - 1);
    document.cookie = '';
  }

  onSectionClick(section: {
    icon: string | null;
    label: string;
    link?: string;
    role_id?: string;
    callback?: () => void;
  }) {
    if (section.callback) {
      section.callback();
    } else if (section.link) {
      this.router.navigate([section.link]);
    }
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {

    if (this.dataForm.valid) {
      let data = { batch_id: this.dataForm.value.lotto, receiver_id: this.dataForm.value.company }
      this._componentService.transferData(data).subscribe(r => {
        this.isOkLoading = true;
        this.isVisible = false;
        this.isOkLoading = false;
        Swal.fire({
          title: "Inviato",
          text: "Lotto inviato con successo",
          showConfirmButton: false,
          icon: "success",
          timer: 2000
        });
      })
    } else {
      Swal.fire({
        title: "Campi mancanti",
        text: "Compila tutti i campi",
        showConfirmButton: false,
        icon: "warning",
        timer: 2000
      });
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  selectProductType(id: number): void {
    this.productsType.forEach(p => p.selected = false);
    const product = this.productsType.find(p => p.id === id);
    if (product) {
      product.selected = true;
      this.type = product.id
      this._componentService.getReadyProducts(this.type).subscribe(r => {
        this.batchToSelect = r.data.products;
        this.roleNaleLabel = r.data.successiveRoleName;
        this.roleToSelect = r.data.receiverRoleName;

      })
    }
  }

  getUserRole() {
    this._componentService.getUserRole().subscribe(r => {
      this.currentRole = r
      switch (this.currentRole) {
        case 2:
          this.recevingUser = 'Selezione e stoccaggio'
          break;
        case 5:
          this.recevingUser = 'Distributore'
          break;
        case 6:
          this.recevingUser = 'Venditore'
          break;

        default:
          break;
      }

    })
  }

  getUser() {
    this._auth.getUser().subscribe(r => {
      this.userId = r.data.id;
    })
  }

  goToProfile() {
    this.router.navigate(['app/user-profile/' + this.userId]);
  }

  postInteractionEnginePredictComponent(user:any){
    const data = {
      user_id : user.id,
      role_id : localStorage.getItem('role_id'),
      device_type : this._utils.getEnhancedDeviceType()
    };
    this._componentService.postInteractionEnginePredictComponent(data).subscribe(response => {
      if (response.code === 200) {
        this.widgetsFromPredict = response.data.Shortcuts;
      }
    })
  }

}
