<!-- Template desktop -->
<ng-template #desktopTemplate>

</ng-template>

<!-- Template tablet -->
<ng-template #tabletTemplate>
  <div>
    <p>Questo è un template tablet</p>
  </div>
</ng-template>

<!-- Template mobile -->
<ng-template #mobileTemplate>
  <div>
    <p>Questo è un template mobile</p>
  </div>
</ng-template>

<div [ngSwitch]="layoutType">
  <ng-container *ngSwitchCase="'desktop'" [ngTemplateOutlet]="desktopTemplate"></ng-container>
  <ng-container *ngSwitchCase="'tablet'" [ngTemplateOutlet]="tabletTemplate"></ng-container>
  <ng-container *ngSwitchCase="'mobile'" [ngTemplateOutlet]="mobileTemplate"></ng-container>
  <ng-container *ngSwitchDefault>
    <p>Layout non riconosciuto</p>
  </ng-container>
</div>



<!-- <div class="container">
  <div class="row">
    <div *ngFor="let item of widget" class="col-md-{{ getComponent(item.value).columns || 4 }} mb-3">
      <div class="card bd-rad d-flex" [style.background-image]="getComponent(item.value).background"
        [style]="getComponent(item.value).additionalStyles">
        <div class="card-body">
          <div *ngComponentOutlet="getComponent(item.value)?.component || null"></div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="container" style="margin:auto;">
  <div class="d-flex flex-wrap">
    <div *ngFor="let item of widget" class="mb-3 me-4">
      <div class="card bd-rad d-flex" [style.background-image]="getComponent(item.value).background"
        [style]="getComponent(item.value).additionalStyles" [ngClass]="getComponent(item.value).customClass">
        <div class="card-body">
          <div *ngComponentOutlet="getComponent(item.value)?.component || null"></div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div style="width:80%; background-color: #ffff; border-radius: 1rem;" *ngIf="role_id==5">
   <app-widget-products-coming-soon></app-widget-products-coming-soon>
</div> -->

<!-- <div style="width:30%; background-color: #ffff; border-radius: 1rem;" *ngIf="role_id==5">
  <app-widget-in-stock></app-widget-in-stock>
</div> -->

<!-- <div style=" width:50%; background-color: #ffff; border-radius: 1rem;" *ngIf="role_id==5">
  <app-widget-latest-transformations></app-widget-latest-transformations>
</div> -->


<div class="d-flex flex-row" *ngIf="role_id==2">
  <div>
    <app-container1 [widgetsFromPredict]="widgetsFromPredict" ></app-container1>
  </div>
</div>

<div *ngIf="role_id==5">
  <app-transformer-home1></app-transformer-home1>
</div>

<div *ngIf="role_id==6">
  <app-transporter-home></app-transporter-home>
</div>

<div *ngIf="role_id==4">
  <app-salePoint-home></app-salePoint-home>
</div>

<div *ngIf="role_id==3">
  <app-consumer></app-consumer>
</div>
