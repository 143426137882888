<div *ngIf="loaded" class="latest-shipment-container ">
  <div class="col-12 timeline">

    <div class="d-flex justify-content-between header">
      <div>
        <h4 class="header-widget">Ultima spedizione</h4>
      </div>
      <div class="d-flex align-items-center">
        <span class="current-lotto-title">Lotto:</span>
        <span class="current-lotto">{{shipment?.lotto}}</span>
      </div>
      <div class="current-state-container d-flex align-items-center">
        <span class="current-lotto-title">Stato:</span>
        <span class="shipment-status">{{shipment?.status}}</span>
      </div>
      <span style="cursor: pointer;" (click)="explodeWidget()">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>

    <div class="row">
      <!-- <div class="col-12 d-flex align-items-center padding-b current-lotto-container">
        <div class="d-flex align-items-center">
          <span class="current-lotto-title">Lotto:</span>
          <span class="current-lotto">{{shipment?.lotto}}</span>
        </div>
        <div class="current-state-container d-flex align-items-center">
          <span class="current-lotto-title">Stato:</span>
          <span class="shipment-status">{{shipment?.status}}</span>
        </div>
      </div> -->

      <div class=" col-12 d-flex  align-items-center">
        <div class="steps" *ngFor="let item of shipment?.steps; let i = index; let last = last;">
          <div class="line-container d-flex flex-column">
            <div [ngClass]="{'actor-name-no-wait': item.status != 'wait', 'actor-name-wait': item.status == 'wait'}">
              {{item.name}}
            </div>
            <div class="actor  d-flex align-items-center">

              <div class="actor-img"
                [ngClass]="{'relative': item.status != 'progress', 'border-finish-color': item.status == 'progress', 'border-wait-color': item.status != 'progress'}">
                <img [src]="item.path" alt="">
                <div *ngIf="item.status == 'finish'" class="overlay">
                  <i class="fas fa-check"></i>
                </div>
                <div *ngIf="item.status == 'wait'" class="overlay-wait">
                </div>
              </div>
              <div [ngClass]="{'transit': item.transit}">
                <div [ngClass]="{
                  'progress-line': !last,
                  'progress-line-finish-color': item.status == 'finish',
                  'progress-line-wait-color': item.status != 'finish',
                  'progress-line-transporter': !last && actor == 'distributor_id',
                  'progress-line-venditore': !last && actor == 'venditore_id',
                  'progress-line-transformer': !last && actor == 'selection_user_id',
                  'progress-line-producer': !last && actor == 'producer_id'
                }">
                </div>
              </div>

            </div>


            <div class="info d-flex flex-column" [ngClass]="{'hidden': item.status == 'wait'}">
              <span class="step-shipment-status">{{ item.shipmentStatus }}</span>
              <span class="step-shipment-date">
                {{ item.shipmentStatus == 'Spedito'
                ? (item.shippingDate | date:'dd/MM/yyyy')
                : (item.deliveryDate | date:'dd/MM/yyyy') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
