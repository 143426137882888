import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar.service';
import { ComponentService } from '../component.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-lotHistoryMobile',
  templateUrl: './lotHistoryMobile.component.html',
  styleUrls: ['./lotHistoryMobile.component.scss']
})
export class LotHistoryMobileComponent implements OnInit {

  batch: any;

  mobileIcon!: string;
  mobiletype!: string;

  roles = [
    { role: 'Produttore', id: 2, selected: true },
    { role: 'Selezione e stoccaggio', id: 5, selected: false },
    { role: 'Distributore', id: 6, selected: false },
    { role: 'Punto vendita', id: 4, selected: false },
  ];

  data: any

  currentRole: any;

  constructor(
    private sidebarService: SidebarService,
    private _componentService: ComponentService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.sidebarService.hideSidebar();
    this.currentRole = 2
    this.route.queryParams.subscribe(params => {
      this.batch = params['batch_id'];
    });
    this.getMyHistoryBatchTimeline();
  }

  ngOnDestroy(): void {
    this.sidebarService.showSidebar();
  }

  selectProductType(id: number): void {
    console.log(id);

    this.roles.forEach(p => p.selected = false);
    const roleId = this.roles.find(p => p.id === id);
    if (roleId) {
      roleId.selected = true;
      this.currentRole = roleId.id
      this.getMyHistoryBatchTimeline()
    }
  }

  getBatches() {
    this._componentService.getMyBatchesOnTimeLine().subscribe(r => {
      this.data = r.data
    })
  }

  getMyHistoryBatchTimeline(batch?: any) {
    if (batch) {
      this.batch = batch
    }

    this._componentService.getMyHistoryBatchTimeline(this.currentRole, this.batch).subscribe(r => {
      this.data = [r.data]
      this.mobiletype = r.data.mobile_type;
      this.mobileIcon = r.data.mobile_icon;
    }, er => {

    }
    )
  }


}
