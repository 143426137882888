import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-sellerUpcomingProducts',
  templateUrl: './sellerUpcomingProducts.component.html',
  styleUrls: ['./sellerUpcomingProducts.component.scss']
})
export class SellerUpcomingProductsComponent implements OnInit {

  constructor(
    private _loggingService: LoggingService,
    private router: Router,
    private _componentService: ComponentService,
    private _utils: Utils
  ) { }

  cols = ['Lotto', 'Arrivo', 'Produttore', 'Prodotto', 'Quantitá', 'Stato'];

  data: any;

  ngOnInit() {
    this.getData();
  }

  getData() {
    this._componentService.getArrivingProduct().subscribe(r => {
      console.log('ARRIVING', r);
      this.data = r.data;
    })
  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 1);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-doughnut-and-pie-chart'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);

    })
  }

}
