import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from '../helpers/interceptor.service';
import { Credentials } from '../models/credentials.model';
import { FormattedResponse } from '../models/formatted-response';
import { HttpContextConfig } from '../models/http-context-config';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private http: HttpClient) { }

  currentUser: BehaviorSubject<User | any> = new BehaviorSubject(null);
  
  // getReports(params?: any): Observable<FormattedResponse<any>> {
  //   return this.http.get<FormattedResponse<any>>(`${environment.api}/reports`, { params });
  // }

  // getReportById(id: string): Observable<FormattedResponse<any>> {
  //   return this.http.get<FormattedResponse<any>>(`${environment.api}/reports/${id}`);
  // }

  getUserFeedback(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/rating-user`, { params });
  }

  // createReport(params: any): Observable<FormattedResponse<any>> {
  //   return this.http.post<FormattedResponse<any>>(`${environment.api}/reports`, params);
  // }

  // updateReport(reportId: string, params: any): Observable<FormattedResponse<any>> {
  //   return this.http.put<FormattedResponse<any>>(`${environment.api}/reports/${reportId}`, params);
  // }

  // deleteReport(reportId: any): Observable<FormattedResponse<null>> {
  //   return this.http.delete<FormattedResponse<null>>(`${environment.api}/reports/${reportId}`);
  // }
  
}
