import { Component, OnInit } from '@angular/core';
import { ComponentService } from 'src/app/components/component.service';

@Component({
  selector: 'app-SalesStatus',
  templateUrl: './SalesStatus.component.html',
  styleUrls: ['./SalesStatus.component.scss']
})
export class SalesStatusComponent implements OnInit {

  data: any;
  show: boolean = false

  constructor(
    private _componentService: ComponentService
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this._componentService.getVenditeState().subscribe(r => {
      this.data = r.data;
      
      this.show = true;
    })
  }

}
