import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  // sessionId: BehaviorSubject<any> = new BehaviorSubject(null);

constructor(private http: HttpClient) { }

logClick(data: any) {
  let sessionId = localStorage.getItem('session_id');
  let newData = {
    page_url: data.url,
    widget_name: data.clickOn,
    session_id: sessionId
  }
  console.log(data);
  
  return this.http.post<any>(`${environment.api}/log-interaction-engine-data`,newData);
}

interacionEngineSessionData(data: any) {
  // this.sessionId.next(data.session_id);
  
  
  return this.http.post<any>(`${environment.api}/log-interaction-engine-session-info`,data);
}

// getSessionId(id: string) {
    
//   this.sessionId.next(id);
// }

}
