<p *ngIf="municipalityLabel" class="top-field">Campo : <strong>{{ municipalityLabel}} </strong></p>

<div class="historical-weather">
  <div class="rounded-container-white">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="col-8" id="title-lighter">Storico metereologico</h5>
      <div *ngFor="let item of filtersWeather">
        <form [formGroup]="filtersForm">
          <nz-select [formControlName]="item.formControlName" [nzPlaceHolder]="item.placeholder"
            class="select-list custom-select">
            <ng-container *ngIf="isObservable(item.options); else staticOptions">
              <ng-container *ngIf="item.options | async as asyncOptions">
                <nz-option *ngFor="let opt of asyncOptions" [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
              </ng-container>
            </ng-container>
            <ng-template #staticOptions>
              <nz-option *ngFor="let opt of item.options" [nzLabel]="opt.label" [nzValue]="opt"></nz-option>
            </ng-template>
          </nz-select>
        </form>
      </div>
    </div>

    <div *ngIf="batch_history == null" class="disappearing-line">
      <p>
        Seleziona un lotto per vederne lo storico
      </p>
    </div>

    <div *ngIf=" (batch_history !== null && batch_history?.total_month > 0 ) || showMonths ">
      <div *ngIf="batch_info">
        <p class="title-info">
          Lotto <span class="span-heavier"> {{ batch_info.batch_unique_identifier }}</span>
        </p>
        <p>
          Seminato il: <span class="span-heavier"> {{ batch_info.sowing_date ?? 'Non definito' }} </span>
          Raccolto il: <span class="span-heavier"> {{ batch_info.reaping_date ?? 'Non definito' }} </span>
        </p>
      </div>
      <!-- month -->
      <div *ngIf="showMonths" class="month-buttons">
        <button *ngFor="let m of months; let i = index" (click)="selectMonth(m, i, $event)"
          (keydown)="selectMonth(m, i, $event)" [ngClass]="{
    'month-button': true,
    'active-month-button': activeMonth?.value === m.value,
    'adjacent-month-button': isAdjacentMonth(i) }">
          {{ m.label }}
        </button>
      </div>

      <!-- days -->

      <div
        *ngIf="batch_history?.data && batch_history?.data?.monthly_data && batch_history?.total_month > 0 ; else noDataTemplate"
        class="weather-days-container">
        <button class="nav-button" (click)="scrollDays(-1)" [disabled]="skip === 0">
          <img src="../../../../assets/icon/previsioni/expand_circle_left.svg" alt="Icon" class="icon">
        </button>
        <div #dayCardsContainer class="day-cards-container">
          <div #dayCardsInner class="day-cards-inner">
            <app-weather-day-card *ngFor="let day of batch_history?.data?.monthly_data; let i = index"
              [dayWeather]="day" (click)="selectDay(day, i, $event)" (keydown)="selectDay(day, i, $event)"
              [isSelected]="selectedDay?.id === day?.id"></app-weather-day-card>
          </div>
        </div>
        <button class="nav-button" (click)="scrollDays(1)" [disabled]="skip + take >= batch_history?.total_month">
          <img src="../../../../assets/icon/previsioni/expand_circle_right.svg" alt="Icon" class="icon">
        </button>
      </div>

      <!-- Template alternativo per quando non ci sono dati -->
      <ng-template #noDataTemplate>
        <div class="no-data-message">
          <p>Non ci sono dati disponibili per questo periodo.</p>
        </div>
      </ng-template>


      <!-- weather-istances -->
      <div *ngIf=" batch_history !== null && batch_history?.total_month > 0 &&selectedDay ">
        <section class="weather-section">
          <div class="section-header">
            <h6 class="section-title-lighter">Dettagli del giorno</h6>
            <p *ngIf="activeMonth != null">
              <!-- {{ selectedDay.created_at | date: 'fullDate'  }} -->
              {{ selectedDay.created_at | date:'dd' }} {{ selectedDay.created_at | date:'MMMM' | capitalizeFirst }} {{
              selectedDay.created_at | date:'yyyy'}}
            </p>
          </div>
          <div class="weather-instances-external">
            <div class="weather-instances-container ">
              <div class="d-flex" *ngFor="let weather of visibleWeatherInstances">
                <div class="weather-details-multiple justify-content-center align-content-center align-items-center">
                  <p>{{ weather.created_at | date:'HH:mm' }}</p>
                  <p class="text-weather-details-focus">{{ weather.temp_main }}C</p>
                  <img [src]="getIconPath(weather.weather_main)" alt="{{ weather.weather_main }}"
                    class="weather-icon" />
                </div>
              </div>
            </div>

            <!-- vento  -->
            <div class="weather-instance d-flex">
              <div class="weather-details">
                <img src="../../../../assets/icon/previsioni/wind.svg" alt="vento">
                <p>
                  Vento
                </p>
                <p class="text-weather-details-focus">
                  {{ selectedDay.wind_speed }} km/h
                </p>
              </div>
            </div>
            <!-- vento fine -->
            <!-- umidità -->
            <div class="weather-instance d-flex">
              <div class="weather-details">
                <img src="../../../../assets/icon/previsioni/humidity.svg" alt="umidità">
                <p>
                  Umidità
                </p>
                <p class="text-weather-details-focus">
                  {{ selectedDay.humidity }} %
                </p>
              </div>
            </div>
            <!-- umidità fine  -->
            <!-- pressione -->
            <div class="weather-instance d-flex">
              <div class="weather-details">
                <img src="../../../../assets/icon/previsioni/pressure.svg" alt="pressione">
                <p>
                  Pressione
                </p>
                <p class="text-weather-details-focus">
                  {{ selectedDay.pressure }} mbar
                </p>
              </div>
            </div>
            <!-- pressione fine -->
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
