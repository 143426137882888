import { Component } from '@angular/core';

@Component({
  selector: 'app-historical-lots-detail',
  templateUrl: './historical-lots-detail.component.html',
  styleUrls: ['./historical-lots-detail.component.scss']
})
export class HistoricalLotsDetailComponent {

}
