import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

constructor(private http: HttpClient) { }

getPlantsWidgetData() {
  return this.http.get<any>(`${environment.api}/widget/plantes`);
}

getproductionData(year: any) {
  return this.http.get<any>(`${environment.api}/widget/production_widget?year=${year}`);
}

getWaterData(town: any) {
  return this.http.get<any>(`${environment.api}/widget/water/${town}`);
}

getSunData(town: any) {
  return this.http.get<any>(`${environment.api}/widget/soil/${town}`);
}

getMunicipality() {
  return this.http.get<any>(`${environment.api}/my-municipality`);
}

getMarketMunicipality() {
  return this.http.get<any>(`${environment.api}/market-municipality`);
}

getMarketPriceData(town: any) {
  return this.http.get<any>(`${environment.api}/market-price/${town}`);
}

getAlertFms() {
  return this.http.get<any>(`${environment.api}/alert-plant`);
}

getTransformerMunicipality() {
  return this.http.get<any>(`${environment.api}/transformer-municipality`);
}

getTransformerWaterData(prov: any) {
  return this.http.get<any>(`${environment.api}/transformer-water-consumed/${prov}`);
}

getTransformerQuantityData(prov: any) {
  return this.http.get<any>(`${environment.api}/transformer-rejected-quantity/${prov}`);
}

getTransformerenergyData(prov: any) {
  return this.http.get<any>(`${environment.api}/energetic-consumption/${prov}`);
}

getTransformerinventoryStockData() {
  return this.http.get<any>(`${environment.api}/inventory-stock`);
}

getTransformerproductsComingSoonData() {
  return this.http.get<any>(`${environment.api}/latest-product`);
}

getLatestTransformationData() {
  return this.http.get<any>(`${environment.api}/batch-transformation`);
}

getWidgetVenditoreInStockData() {
  return this.http.get<any>(`${environment.api}/in-magazzino`);
}

getproductionDataStatistics(year: any, plante_id?: any) {
  return this.http.get<any>(`${environment.api}/get-production-trend?year=${year}&plante_id=${plante_id}`);
}

getMunicipalityByProvince(munId: any) {
  return this.http.get<any>(`${environment.api}/get-municipality-by-province/${munId}`);
}


}
