import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartOptions, ChartType } from 'chart.js';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  selectedYear!: string;
  showWidget = false;
  isEmpty!: boolean;
  years = ['2021', '2022', '2023']

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        label: 'Arance',
        data: [],
        backgroundColor: 'rgba(236, 160, 37, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 25
      },
      {
        label: 'Limoni',
        data: [],
        backgroundColor: 'rgba(255, 216, 156, 0.8)',
        borderColor: 'rgba(255, 216, 156, 0.8)',
        borderWidth: 1,
        borderRadius: 25
      }
    ]
  };
  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    // indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };
  public barChartType: any = 'bar';
  public barChartLegend = true;


  constructor(
    private _chartService: ChartService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils:Utils
  ) { }

  ngOnInit() {
    // this._loggingService.getSessionId()
    // console.log('USER SESSION ID',this._loggingService.sessionId);
    this.getData();
  }



  getData() {
    this.showWidget = false;
    let data;
    if (this.selectedYear) {
      data = this.selectedYear
    } else {
      data = null
    }
    this._chartService.getproductionData(data).subscribe(r => {
      this.isEmpty = r.data.isEmpty;
      if (!this.isEmpty) {
        this.barChartData.labels = r.data.widgetData.labels;
        this.barChartData.datasets[0].data = r.data.widgetData.datasets.data[0];
        this.barChartData.datasets[1].data = r.data.widgetData.datasets.data[1];
        this.showWidget = true;
      } else {
        this.barChartData.labels = ['Nessun dato'];
        this.barChartData.datasets[0].data = [0];
        this.barChartData.datasets[1].data = [0];
        this.showWidget = true;
      }

    })

  }

  explodeWidget() {
    this._utils.handleWidgetClick('app/statistics', 8);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-bar-chart'
    }
    this._loggingService.logClick(data).subscribe(r => {
      console.log(r);
      this.router.navigate(['app/statistics'])
    })
  }

}
