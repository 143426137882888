import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentService } from '../component.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-userProfile',
  templateUrl: './userProfile.component.html',
  styleUrls: ['./userProfile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userInfoForm!: FormGroup;

  userInfo: any;

  roleTab: any;

  constructor(
    private fb: FormBuilder,
    private _componentService: ComponentService,
    
  ) { }

  ngOnInit() {
    this.getUserInfo()
    this.userInfoForm = this.fb.group({
      name: new FormControl(null, Validators.required),
      surname: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
    
  }

  getUserInfo() {
    this._componentService.getUserInfo().subscribe(r => {
      this.userInfo = r.data;
      this.populateForm()
      console.log(r);
      switch (this.userInfo.role_id) {
        case 2:
          this.roleTab = 'Le mie piantagioni'
          break;

        case 5:
          this.roleTab = 'Il mio impianto'
          break;

        case 6:
          this.roleTab = 'La mia sede'
          break;

        case 4:
          this.roleTab = 'Il mio negozio'
          break;

        default:
          break;
      }

    })
  }

  populateForm() {
    if (this.userInfo) {
      this.userInfoForm.patchValue({
        name: this.userInfo.first_name,
        surname: this.userInfo.last_name,
        email: this.userInfo.email,
      });
    }
  }

  // saveUserInfo() {
  //   if (this.userInfoForm.valid) {
  //     this._componentService.updateUserInfo(this.userInfoForm.value).subscribe(r => {
  //       Swal.fire({
  //         title: "Aggiornato",
  //         text: "Profilo aggiornato con successo",
  //         showConfirmButton: false,
  //         icon: "success",
  //       });

  //     })
  //   } else {
  //     Swal.fire({
  //       title: "Errore",
  //       text: "Campi mancanti o errati",
  //       showConfirmButton: false,
  //       icon: "warning",
  //     });
  //   }

  // }

  saveUserInfo() {
    console.log(this.userInfoForm.value);
    let data
    if (this.userInfoForm.valid) {
      data =
      {
        first_name: this.userInfoForm.value.name,
        last_name: this.userInfoForm.value.surname,
        email: this.userInfoForm.value.email,
        password: this.userInfoForm.value.password,
      }
      this._componentService.updateUserInfo(data).subscribe(r => {
        console.log(r);
        Swal.fire({
          title: "Aggiornato",
          text: "Profilo aggiornato con successo",
          showConfirmButton: false,
          icon: "success",
          timer: 2000
        });

      }, er => {
        Swal.fire({
          title: "Errore",
          text: er.message,
          showConfirmButton: false,
          icon: "warning",
          timer: 2000
        });

      }
    )
    }else {
      Swal.fire({
        title: "Errore",
        text: "Campi mancanti o errati",
        showConfirmButton: false,
        icon: "warning",
        timer: 2000
      });
    }

  }

  resetForm() {
    this.userInfoForm.reset()
  }


}
