import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { ReportFMSService } from 'src/app/shared/services/report_fms.service';

@Component({
  selector: 'app-widget-weather',
  templateUrl: './widget-weather.component.html',
  styleUrls: ['./widget-weather.component.scss']
})
export class WidgetWeatherComponent {
  temp_min: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  temp_max: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sky: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  temperature: any;
  skyDescription: string = '';
  selectedField: { id: null, label: null } | any;

  fields: any;
  showWidget: boolean = false;
  placeholder: string = 'Provincia';

  weatherTranslations: { [key: string]: string } = {
    'Thunderstorm': 'Temporale',
    'Drizzle': 'Pioviggine',
    'Rain': 'Pioggia',
    'Snow': 'Neve',
    'Mist': 'Nebbia',
    'Smoke': 'Fumo',
    'Haze': 'Foschia',
    'Dust': 'Polvere',
    'Ash': 'Cenere',
    'Squall': 'Tempesta',
    'Tornado': 'Tornado',
    'Clear': 'Sereno',
    'Clouds': 'Nuvoloso'
  };


  constructor(
    private reportService: ReportFMSService,
    private translate: TranslateService,
    private _chart: ChartService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils: Utils
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('it');
  }

  ngOnInit(): void {
    this.getMunicipality();
  }

  getMunicipality() {
    this._chart.getMunicipality().subscribe(r => {
      if (r.data.length > 0) {
        this.fields = r.data;
        const obj = this.fields[0]; // è un problema spararci l'intero oggetto? se no html fa le bizze con la label
        this.selectedField = obj;
        this.getWeather()
      } else {
        this.placeholder = 'Nessun dato'
        this.temp_max.next('Nessun dato');
        this.temp_min.next('Nessun dato');
        this.showWidget = true;
      }

    })
  };



  translateWeather(description: string): string {
    switch (true) {
      case description.includes('Thunderstorm'):
        return this.weatherTranslations['Thunderstorm'];
      case description.includes('Drizzle'):
        return this.weatherTranslations['Drizzle'];
      case description.includes('Rain'):
        return this.weatherTranslations['Rain'];
      case description.includes('Snow'):
        return this.weatherTranslations['Snow'];
      case description.includes('Mist'):
        return this.weatherTranslations['Mist'];
      case description.includes('Smoke'):
        return this.weatherTranslations['Smoke'];
      case description.includes('Haze'):
        return this.weatherTranslations['Haze'];
      case description.includes('Dust'):
        return this.weatherTranslations['Dust'];
      case description.includes('Ash'):
        return this.weatherTranslations['Ash'];
      case description.includes('Squall'):
        return this.weatherTranslations['Squall'];
      case description.includes('Tornado'):
        return this.weatherTranslations['Tornado'];
      case description.includes('Clear'):
        return this.weatherTranslations['Clear'];
      case description.includes('Clouds'):
        return this.weatherTranslations['Clouds'];
      default:
        return 'Non disponibile';
    }
  }

  getWeather(selectedField: any = null): void {
    this.selectedField = selectedField ?? this.selectedField;

    this.reportService.weather({ city: this.selectedField.label }).subscribe(res => {

      this.temperature = res.data;
      let max = Math.floor(this.temperature.main.temp_max);
      let min = Math.floor(this.temperature.main.temp_min);
      this.temp_max.next(max);
      this.temp_min.next(min);
      this.skyDescription = this.translateWeather(this.temperature.weather[0].main);
      this.sky.next(this.skyDescription);
      this.showWidget = true;
    });
  }

  explodeWidget() {

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-weather'
    }
    this._loggingService.logClick(data).subscribe(r => {
      console.log(r);

    })
  }

  explodeWidget2() {
    this._utils.handleWidgetClick('/app/statistics', 5);

    this.router.navigate(['app/statistics'], {
      queryParams: {
        tab: 2,
        municipality_id: this.selectedField.id,
        municipality_desc: this.selectedField.label
      }
    });
  }

}
