<div class="container1">
  <div>
    <div class="row d-flex mb-4">
      <!-- Left Column - Approx 29% -->
      <div class="col-3 d-flex flex-column vertical-gap">
        <div class="d-flex flex-column align-items-stretch">
          <div class="card bd-rad bg-color h-100 app-doughnut-pie"
            *ngIf="widget.includes('app-doughnut-and-pie-chart'); else noData">
            <div class="card-body">
              <app-doughnut-and-pie-chart></app-doughnut-and-pie-chart>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-stretch h-100">
          <div class="card bd-rad bg-color bg-weather app-weather" *ngIf="widget.includes('app_meteo'); else noData">
            <div class="card-body">
              <app-widget-weather></app-widget-weather>
            </div>
          </div>
        </div>
      </div>

      <!-- Middle Column - 42% -->
      <div class="col-6 d-flex flex-column vertical-gap middle-column">
        <div class="d-flex flex-column align-items-stretch h-100">
          <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app_report_fms'); else noData">
            <div class="card-body">
              <app-widget-report-fms [is_dashboard]="is_dashboard"></app-widget-report-fms>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div style="width:14.3rem ; " class="card bd-rad color-sun vertical-gap sun-chart"
            *ngIf="widget.includes('app-sun-chart'); else noData">
            <div class="card-body bg-sun bd-rad">
              <app-sun-chart></app-sun-chart>
            </div>
          </div>
          <div style="width: 14.3rem; " class="card bd-rad color-water water-chart"
            *ngIf="widget.includes('app-water-chart'); else noData">
            <div class="card-body bg-water bd-rad">
              <app-water-chart></app-water-chart>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Column - 29% -->
      <div class="col-3 d-flex flex-column vertical-gap right-column">
        <div class="card  bd-rad box-alert">
          <div class="card-body">
            <app-alert></app-alert>
          </div>
        </div>
        <div class="card h-100 bd-rad bg-color" *ngIf="widget.includes('app-line-chart'); else noData">
          <div class="card-body-alt">
            <app-line-chart></app-line-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex mb-4">
      <!-- Main Chart Column - 71% -->
      <div class="col-8 main-chart-column">
        <div class="card border-none" *ngIf="widget.includes('app-bar-chart'); else noData">
          <div class="card-body">
            <app-bar-chart></app-bar-chart>
          </div>
        </div>
      </div>

      <!-- Feedback Column - 29% -->
      <div class="col-4 feedback-column">
        <div class="card h-100 bd-rad bg-feedback" *ngIf="widget.includes('feedback'); else noData">
          <div class="card-body">
            <app-widget-feedback></app-widget-feedback>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Latest Shipments - 71% -->
      <div class="col-8 latest-shipments-column">
        <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-latestShipments'); else noData">
          <app-latestShipments [actor]="'producer_id'"></app-latestShipments>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="card bd-rad bg-no-data h-100">
    <div class="card-body">
      <app-no-data></app-no-data>
    </div>
  </div>
</ng-template>