<h2>Storico lotti</h2>

<span class="fs-14">Tutti i lotti</span>

<nz-tabset>
    <nz-tab *ngFor="let actor of actors" [nzTitle]="actor.lable">
        <ng-template nz-tab>
            <h2>{{ actor.lable }}</h2>
            <p>{{ actor.content }}</p>
        </ng-template>
    </nz-tab>
</nz-tabset>