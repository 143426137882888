<div class="row transporter-home-2-container">
  <div class="col-12 mb-3  in-corso">
    <div class="card bd-rad bg-color">
      <div class="card-body">
        <app-shipping-status></app-shipping-status>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card bd-rad bg-feedback h-100" *ngIf="widget.includes('feedback'); else noData">
      <div class="card-body">
        <app-widget-feedback></app-widget-feedback>
      </div>
    </div>
  </div>


  <ng-template #noData>
    <div class="card bd-rad bg-no-data h-100 mb-1">
      <div class="card-body">
        <app-no-data></app-no-data>
      </div>
    </div>
  </ng-template>
</div>