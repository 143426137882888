<div class="feedback-list-container">
  <div class="row">
    <div class="col-12 header d-flex justify-content-between align-items-center">
      <div class="title-header">Feedback</div>
      <div class="select-search-container d-flex justify-content-between align-items-center">
        <form nz-form [nzLayout]="'inline'" [formGroup]="form">
          <div class="select-container form-container d-flex justify-content-between align-items-center">
            <span class="filters">Filtra per:</span>
            <div class=" d-flex justify-content-between align-items-center">
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzPlaceHolder="Tipo" nzBorderless formControlName="type" (ngModelChange)="submitForm()">
                    <nz-option [nzValue]="true" nzLabel="Ricevuti"></nz-option>
                    <nz-option [nzValue]="false" nzLabel="Emessi"></nz-option>
                  </nz-select>
                </nz-form-control>

              </nz-form-item>
              <nz-form-item>
                <nz-select nzPlaceHolder="Lotto" nzBorderless formControlName="lotto" (ngModelChange)="submitForm()">
                  <!-- <nz-option [nzValue]="null" nzLabel="Lotto"></nz-option> -->
                  <nz-option [nzValue]="null" nzLabel="Nessuno"></nz-option>
                  <nz-option *ngFor="let lotto of userLotti" [nzValue]="lotto.id"
                    [nzLabel]="lotto.batch_unique_identifier"></nz-option>
                </nz-select>
              </nz-form-item>
              <nz-form-item>
                <nz-select class="actor" nzPlaceHolder="Operatori di filiera" nzBorderless formControlName="actor"
                  (ngModelChange)="submitForm()">
                  <!-- <nz-option [nzValue]="null" nzLabel="Operatori di filiera"></nz-option> -->
                  <nz-option [nzValue]="null" nzLabel="Nessuno"></nz-option>
                  <nz-option *ngFor="let role of operatorsToSelectModal" [nzValue]="role" [nzLabel]="role"></nz-option>
                </nz-select>
              </nz-form-item>
            </div>
          </div>

          <div class="search-container ">
            <div class="example-input">
              <input type="search" (keyup.enter)="submitForm()" formControlName="search" nz-input placeholder="Cerca" />
              <ng-template #prefixTemplateUser><span nz-icon nzType="search"></span></ng-template>
            </div>
          </div>

        </form>
      </div>
    </div>

    <div *ngIf="showFeedbackList" class="col-8 list">
      <div class="feedback row" *ngFor="let item of feedbackList" (click)="updateFeedback(item)">
        <div class="col-10">
          <div class="col-12 top d-flex align-items-center">
            <div class="img">
              <span *ngIf="item.img">
                <img src="item.img" alt="ext">
              </span>
              <span *ngIf="!item.img">
                <span>{{ item.name.charAt(0) }}</span>
              </span>
            </div>
            <div class="name">
              {{item.name}}
            </div>
            <div class="circle"></div>
            <span class="icon" [ngSwitch]="item.role">
              <span class="icon" *ngSwitchCase="'selezione e stoccaggio'">
                <img src="../../../assets/icon/trasformatore.svg" alt="trasformatore">
              </span>
              <span class="icon" *ngSwitchCase="'distributore'">
                <img src="../../../assets/icon/distributore.svg" alt="distributore">
              </span>
              <span class="icon" *ngSwitchCase="'venditore'">
                <img src="../../../assets/icon/venditore.svg" alt="venditore">
              </span>
              <span class="icon" *ngSwitchCase="'produttore'">
                <img src="/assets/icon/prod.svg" alt="produttore">
              </span>
              <span class="icon" *ngSwitchCase="'consumatore'">
                <img src="../../../assets/icon/consumatore.svg" alt="consumatore">
              </span>
              <p *ngSwitchDefault>

              </p>
            </span>
            <span class="role">{{item.role}}</span>
          </div>
          <div class="col-12 d-flex align-items-center m-bottom">
            <div class="vote"><nz-rate [ngModel]="item.rating" nzAllowHalf nzDisabled="true"></nz-rate></div>
            <span class="title">{{item.title}}</span>
          </div>

          <div class="col-12 m-bottom">
            <span class="date">
              Feedback lasciato il {{ item.created_at | date: 'dd/MM/yyyy' }}
            </span>
          </div>

          <div class="col-12">
            <p class="text">
              {{item.feedback}}
            </p>
          </div>

        </div>
        <div class="col-2">
          <span class="lotti">Lotto:</span>
          <div class="lotto-num">
            <span>{{item.batch.batch_unique_identifier}}</span>
          </div>
        </div>
      </div>

      <div class="mt-3 d-flex justify-content-end">
        <nz-pagination [nzPageIndex]="dataFeedback.skip" [nzPageSize]="dataFeedback.take" [nzTotal]="count"
          (nzPageIndexChange)="handlePageEventNotification($event)" nzSimple>
        </nz-pagination>
      </div>

    </div>

    <div *ngIf="!showFeedbackList" class="col-8 list">
      <h3>Nessun dato</h3>
    </div>

    <div class="col-4 right-side">
      <div class="col-12 p-b-2">
        <div class="new-feedback d-flex justify-content-around align-items-center">
          <div class="new-feedback-content d-flex align-items-center">
            <span class="add-new d-flex justify-content-around  align-items-center" (click)="addFeedback()">
              +
            </span>
            <span class="add-new-text">
              Nuovo feedack
            </span>
          </div>
        </div>
      </div>

      <div class="col-12 p-b-2">
        <div class="overall-rating d-flex flex-column justify-content-around align-items-center">
          <span class="overall-rating-text">
            Valutazione complessiva
          </span>
          <div class="vote"><nz-rate [ngModel]="overallRating" nzAllowHalf nzDisabled="true"></nz-rate></div>
        </div>
      </div>

      <div class="col-12">
        <div class="operators d-flex flex-column justify-content-around ">
          <span class="operatori-title">
            Operatori di filiera
          </span>
          <div *ngFor="let item of operators">
            <div class="operator d-flex align-items-center">
              <span class="icon">
                <img [src]="item.img" alt="ext">
              </span>
              <span class="operator-name">{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <p>
      Seleziona l'operatore della filiera e il numero di lotto alla quale vuoi lasciare un feedback:
    </p>

  </ng-container>
</nz-modal> -->
<div class="modal-container">
  <nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
      <h3 style="padding-bottom: 2.31rem;">
        Aggiungi Feedback
      </h3>
      <p>Seleziona l'operatore della filiera e il numero di lotto alla quale vuoi lasciare un feedback:</p>
      <form class="addFeedbackForm" nz-form [nzLayout]="'inline'" [formGroup]="addFeedbackForm">
        <div class="col-12 p-b-2">
          <div class="d-flex align-items-center justify-content-between delect-add-feedback">
            <div class="">
              <nz-select class="select" nzPlaceHolder="Seleziona operatore" formControlName="operator"
                [nzDisabled]="mod" (ngModelChange)="activeSelect()">
                <nz-option *ngFor="let item of operatorsToSelectModal" [nzValue]="item" [nzLabel]="item"></nz-option>
              </nz-select>
            </div>
            <div *ngIf="loadLottiSelect">
              <nz-select nzPlaceHolder="Seleziona lotto" formControlName="lotto" [nzDisabled]="isDisabled"
                [nzDisabled]="mod">
                <nz-option *ngFor="let item of lottiOnModal" [nzValue]="item" [nzLabel]="item"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>

        <div class="col-12  p-b-2 d-flex flex-column">
          <span class="rate-title">
            Dai una tua valutazione:
          </span>
          <span>
            <nz-rate formControlName="vote"></nz-rate>
          </span>
        </div>

        <div class="col-12  p-b-2 d-flex flex-column">
          <span class="rate-title">
            Dai un titolo al tuo feedback:
          </span>
          <nz-input-group>
            <input type="text" formControlName="title" nz-input placeholder="" />
          </nz-input-group>
        </div>

        <div class="col-12  p-b-2 d-flex flex-column">
          <span class="rate-title">
            Aggiungi il tuo feedback:
          </span>
          <nz-input-group>
            <textarea formControlName="description" nz-input rows="2"></textarea>
          </nz-input-group>
        </div>

        <!-- <div class="col-12  p-b-2 d-flex">
          <button nz-button nzType="primary" nzBlock (click)="handleOk()">Invia</button>
        </div> -->
      </form>


    </ng-container>
  </nz-modal>
</div>