import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ReportFMSService } from 'src/app/shared/services/report_fms.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-activity-type',
  templateUrl: './activity-type.component.html',
  styleUrls: ['./activity-type.component.scss']
})
export class ActivityTypeComponent {
  @Output() reloadReports: EventEmitter<void> = new EventEmitter<void>();
  @Input() report?: any;

  content: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  content2: any;
  size: NzButtonSize = 'small';
  formReport!: FormGroup;
  category = 1;
  data$!: Observable<any>;

  citrus_type = [
    { icon: "/assets/icon/mini-orange.svg", category: 1 },
    { icon: "/assets/icon/mini-lemon.svg", category: 2 }
  ];

  activity_list = [
    { type: 'Lotto', data: '003456' },
    { type: 'Coltivazione', data: 'Arancia Tarocco' },
    { type: 'Causale', data: 'Malattia fungina' },
    { type: 'Prodotto', data: 'Fungicida ABC' },
    { type: 'Campo', data: 'Principale' },
    { type: 'Quantità', data: '10 litri' },
    { type: 'Superficie', data: '0.8' },
  ];

  private activityUnits: { [key: string]: string } = {
    'Trattamento fungicida': '(L)',
    'Irrigazione a goccia': '(L/h)',
    'Monitoraggio delle malattie delle piante': '(Piante)',
    'Preparazione del terreno': 'Ettari (ha)',
    'Trattamento insetticida': 'Litri (L)',
    'Concimazione del terreno': '(kg)',
  };

  @ViewChild('exampleModal') exampleModal!: ElementRef;


  constructor(
    private fb: FormBuilder,
    private reportService: ReportFMSService
  ) {
    this.formReport = this.fb.group({
      note: [null]
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['report']) {
      this.content.next(this.report);
    }
  }

  getIconPath(category: number): string {
    const citrus = this.citrus_type.find(type => type.category == category);
    return citrus ? citrus.icon : '';
  }

  setModal2(data: any) {
    this.content2 = data;
    this.exampleModal.nativeElement.classList.add('show');
    this.exampleModal.nativeElement.style.display = 'block';

    if (this.content2.note) {
      this.formReport.patchValue({ note: this.content2.note });
    }
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.classList.add('show'); // Add the 'show' class for visibility
    }

    document.body.classList.add('modal-open');
  }

  closeModal2() {
    this.exampleModal.nativeElement.classList.remove('show');
    this.exampleModal.nativeElement.style.display = 'none';
    document.body.classList.remove('modal-open');

    const backdrop = document.getElementById('backdrop');
    if (backdrop) {
      backdrop.classList.remove('show');
    }
  }

  getContent() {
    return this.content2;
  }

  insertNote(id: any) {
    const noteValue = this.formReport.value.note?.trim();
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    
    if (!noteValue) {
      Toast.fire({
        icon: "error",
        title: "Campo nota vuoto"
      });
      return;
    }
  
    this.reportService.updateReport(id, { note: noteValue }).subscribe(res => {
      Toast.fire({
        icon: "success",
        title: "Nota inserita correttamente"
      });
      this.reloadReports.emit();
    });
  
    this.closeModal2();
  }

  removeNote(id: any) {
    this.reportService.removeNote(id).subscribe(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "Nota rimossa"
      });
      this.reloadReports.emit();
    })
  }

  getUnitOfMeasure(activity: string): string {
    return this.activityUnits[activity] || 'Unità sconosciuta';
  }

}
