import { Component, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { ChartService } from '../../../../../components/charts/chart.service';
import { BaseChartDirective } from 'ng2-charts';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent {
  title = 'ng2-charts-demo';
  selectedProvince!: string;
  province: any;
  showWidget = false;
  isEmpty!: boolean;
  alert!: string;
  selectedField: { id: null, label: null } = { id: null, label : null};

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Arance',
        fill: false,
        tension: 0.5,
        borderColor: '#EF7F00',
        backgroundColor: 'rgba(255,0,0,0.3)',
        pointRadius: 0

      },
      {
        data: [],
        label: 'Limoni',
        fill: false,
        tension: 0.5,
        borderColor: '#FAC36A',
        backgroundColor: 'rgba(255,0,0,0.3)',
        pointRadius: 0

      }
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
    // clip: 1
    indexAxis: 'x',


  };
  public lineChartLegend = true;


  constructor(
    private _chartService: ChartService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils: Utils
  ) {
  }

  ngOnInit() {
    // this.getMarketPriceWidgetData()
    // this.getAlert();
    this.getMunicipality()
  }

  getData() {
    this.getMarketPriceWidgetData()
  }


  getMunicipality() {
    this._chartService.getMarketMunicipality().subscribe(r => {
      this.province = r.data
      this.selectedProvince = this.province[0].id;
      this.selectedField = this.province[0];
      this.getData()

    })
  }

  getMarketPriceWidgetData() {
    this.showWidget = false;
    this._chartService.getMarketPriceData(this.selectedProvince).subscribe(r => {
      this.isEmpty = r.data.isEmpty
      if (!this.isEmpty) {
        this.lineChartData.datasets[0].data = r.data.widgetData.datasets.data[0];
        this.lineChartData.datasets[1].data = r.data.widgetData.datasets.data[1];
        this.lineChartData.labels = r.data.widgetData.labels;
        this.showWidget = true;
      }else{
        this.lineChartData.datasets[0].data = [0];
        this.lineChartData.datasets[1].data = [0];
        this.lineChartData.labels = ['Nessun dato'];
        this.showWidget = true;
      }


    });
  }

  explodeWidget2() {
    this._utils.handleWidgetClick('/app/statistics', 4);

    this.router.navigate(['app/statistics'], {
      queryParams: {
        tab: 3,
        municipality_id: this.selectedField.id,
        municipality_desc: this.selectedField.label
      }
    });
  }

  explodeWidget() {
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-line-chart'
    }
    this._loggingService.logClick(data).subscribe(r =>{

    })
  }

}
