<div class="sun-container">
  <div class="d-flex justify-content-between">
    <div class="margin-title">
      <h4 class="header-widget mycl">{{ title }}</h4>
    </div>
    <div class="select-field mycl2">
      <nz-select [nzPlaceHolder]="placeholder" [(ngModel)]="selectedField" (ngModelChange)="getData()">
        <nz-option *ngFor="let field of fields" [nzLabel]="field.label" [nzValue]="field.id" class="color">
          {{field}}</nz-option>
      </nz-select>
    </div>
  </div>
  <div class="data-container d-flex justify-content-between">
    <div class="label-data-pair" *ngFor="let item of data">
      <div class="label">
        <span class="fs-12">{{ item.label }}</span>
      </div>
      <div class="data">
        <p class="fs-22">{{ item.value }}</p>
      </div>
    </div>
  </div>
</div>