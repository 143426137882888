import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { BreakpointObserver , Breakpoints , BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { merge, Observable, timer } from 'rxjs';
import { delay, finalize, map, scan } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { toast } from 'src/app/app.component';

interface SyncStep {
  id: number;
  title: string;
  description: string;
  async: false;
  percentage: null;
}

interface AsyncStep {
  id: number;
  title: string;
  description: string;
  async: true;
  percentage: number;
}

type Step = SyncStep | AsyncStep;

function mockAsyncStep(): Observable<number> {
  const subStep1 = timer(0).pipe(map(() => 25));
  const subStep2 = subStep1.pipe(delay(0));
  const subStep3 = subStep2.pipe(delay(0));
  const subStep4 = subStep3.pipe(delay(0));
  return merge(subStep1, subStep2, subStep3, subStep4).pipe(scan((a, b) => a + b));
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm!: FormGroup;
  passwordMismatch: boolean = false;
  cities: any;
  optionsCities: any;
  showProdForm: boolean = false;
  showConsForm: boolean = false;
  layoutType?: string;
  containerLg?: boolean;
  containersm?: boolean;
  typeValue: any;
  roleValue: any;
  stepsForm: any = 0;
  isDone: boolean = false;
  currentStep = 0;
  currentImg: string = '../../../assets/img/register/immagine-register-1.png';

  data = {
    "name": null,
    "lastName": null,
    "email": null,
    "password": null,
    "password_confirmation": null,
    "role": null,
    "type": null,
    "company": null,
    "vatNumber": null
  }

  constructor(private router: Router, private fb: FormBuilder, private breakpointObserver: BreakpointObserver, private _userService: UserService) { }

  ngOnInit() {

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
    ]).subscribe(result => {
      this.layoutType = this.determineLayoutType(result);
    });

    this.buildForm('consumatore');
  }

  determineLayoutType(result: BreakpointState): string {
    switch (true) {
      case result.breakpoints[Breakpoints.XSmall]:
        this.containerLg = false
        this.containersm = true
        return 'mobile';
      case result.breakpoints[Breakpoints.Small] || result.breakpoints[Breakpoints.Medium]:
        return 'tablet';
      default:
        this.containersm = false
        this.containerLg = true
        
        return 'desktop';
    }
  }

  buildForm(type: string) {

        this.registerForm = this.fb.group({
          email: new FormControl(null, [Validators.required, Validators.email]),
          password: [null, [Validators.required]],
          confirmPassword: [null, [Validators.required]],
          name: new FormControl(null, Validators.required),
          lastName: new FormControl(null, Validators.required),
          company: new FormControl(null, Validators.required),
          vatNumber: new FormControl(null, Validators.required),
          // birthplace: new FormControl(null, Validators.required),
          // prov: new FormControl(null, Validators.required),
          // via: new FormControl(null, Validators.required),
          // description: new FormControl(null),
          // type: new FormControl('produttore'),
          // items: this.fb.array([])
        });
        this.showProdForm = true
    
  }

  // valida la conferma password
  updateConfirmValidator() {
    if (this.registerForm.value.password == this.registerForm.value.confirmPassword) {
      this.passwordMismatch = false;
      
    }else{
      this.passwordMismatch = true;
    }
  }


  // submitForm() {
  //   console.log(this.registerForm.value);

  //   let data = {
  //     "name": this.registerForm.value.firstName,
  //     "email": this.registerForm.value.email,
  //     "password": this.registerForm.value.password,
  //     "password_confirmation": this.registerForm.value.confirmPassword,
  //     "role": this.registerForm.value.type,
  //   }

  //   this._userService.register(data).subscribe(r => {
  //     this.router.navigate([`/auth/login`]);
  //   })
    
  // }

  steps: Step[] = [
    {
      id: 1,
      title: '',
      description: `This step is synchronous.`,
      async: false,
      percentage: null
    },
    {
      id: 2,
      title: '',
      description: `This step is asynchronous.`,
      async: true,
      percentage: 0
    },
    {
      id: 3,
      title: '',
      description: `This step is asynchronous.`,
      async: true,
      percentage: 0
    }
  ];
  current = 0;
  processing = false;

  pre(): void {
    if (this.typeValue == 'consumatore') {
      this.current -= 2;
      this.currentImg = '../../../assets/img/register/immagine-register-1.png'
    this.stepsForm = this.stepsForm - 2
    }else if (this.typeValue == 'to-next') {
      this.current -= 1;
      this.currentImg = '../../../assets/img/register/immagine-register-1.png'
      this.stepsForm = this.stepsForm - 1
    }
    
  }

  next(): void {
    this.loadingAndStep();


    if (this.typeValue == 'consumatore') {
      this.stepsForm = this.stepsForm + 2
      if (this.stepsForm == 2) {
        this.currentImg = '../../../assets/img/register/immagine-register-2.png'
      }
      this.current = this.current + 1
      this.data.role = this.typeValue;
    }else if (this.typeValue == 'to-next') {
      this.stepsForm = this.stepsForm + 1
      if (this.stepsForm == 2) {
        this.currentImg = '../../../assets/img/register/immagine-register-2.png'
      }
      this.data.role = this.roleValue;
      // this.currentStep = this.currentStep +1
    }
    
  }

  done(): void {
    // this.loadingAndStep();
    this.data.name = this.registerForm.value.name;
    this.data.lastName = this.registerForm.value.lastName;
    this.data.email = this.registerForm.value.email;
    this.data.password = this.registerForm.value.password;
    this.data.company = this.registerForm.value.company;
    this.data.vatNumber = this.registerForm.value.vatNumber;
    this.data.password_confirmation = this.registerForm.value.confirmPassword;


    if (this.registerForm.valid) {
      
      this._userService.register(this.data).subscribe({
        next: r => {
          this.isDone = true;
          this.router.navigate(['auth/registration-done']);
        },
        error: error => {
          let errorMessage = 'Si è verificato un errore.';
      
          if (error.error) {
            const errorObj = JSON.parse(error.error);
            errorMessage = '';
      
            for (const key in errorObj) {
              if (errorObj.hasOwnProperty(key)) {
                errorMessage += `${key}: ${errorObj[key].join(', ')}\n`;
              }
            }
          }
      
          toast.fire({
            title: 'Campi mancanti',
            text: errorMessage,
            icon: 'warning'
          });
        }
      });
    } else {
      toast.fire({
        title: 'Campi mancanti',
        text: 'Compila tutti i campi',
        icon: 'warning'
      })
    }

    
    
  }

  trackById(_: number, item: Step): number {
    return item.id;
  }

  loadingAndStep(): void {
    if (this.current < this.steps.length) {
      const step = this.steps[this.current];
      if (step.async) {
        this.processing = true;
        mockAsyncStep()
          .pipe(
            finalize(() => {
              step.percentage = 0;
              this.processing = false;
              this.current += 1;
            })
          )
          .subscribe(p => {
            step.percentage = p;
          });
      } else {
        this.current += 1;
      }
    }
  }

  toLogin() {
    this.router.navigate(['auth/login']);
  }





















}
