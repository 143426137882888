import { NgModule } from '@angular/core';

import { TrasformerRoutingModule } from './trasformer-routing.module';
import { YourTreatmentsComponent } from './your-treatments/your-treatments.component';
import { SharedModule } from 'src/app/shared/shared/shared.module';

@NgModule({
  declarations: [YourTreatmentsComponent],
  imports: [
    SharedModule,
    TrasformerRoutingModule
  ],
})
export class TrasformerModule {}
