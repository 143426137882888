<div *ngIf="showWidget" class="piantagioni-container align-items-center">
  <div class="d-flex justify-content-between widget-container">
    <h4 class="header-widget">Piantagioni</h4>
    <span style="cursor: pointer;" (click)="explodeWidget()">
      <img src="/assets/img/arrow.svg" alt="">
    </span>
  </div>
  <div *ngIf="doughnutChartData.datasets[0].data">
    <div class="box-chart">
      <canvas id="doughnutChart" baseChart [data]="doughnutChartData" [type]="'doughnut'"
        [options]="doughnutChartOptions" [plugins]="doughnutChartPlugins">
      </canvas>

    </div>

    <div class="legenda row" style="margin-top: 0.1rem">
      <div class="col-12 d-flex justify-content-between" *ngFor="let item of legendData">
        <div class="d-flex flex-row">
          <div class="color-spot" [style.background-color]="item.color"></div>
          <div class=""><strong>{{item.name}}</strong></div>
        </div>
        <div class="d-flex flex-row justify-content-end flex-wrap">
          <div class="me-2"><strong>({{item.percentage}}%)</strong></div>
          <div><strong>{{item.total}}</strong> ha</div>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="!showWidget" class="piantagioni-container align-items-center">
  <h1>{{noData}}</h1>
</div>
