<div>
  <div class="qr-container">
    <div class="product-title d-flex align-items-center">
      <div class="icon-tytle">
        <img [src]="receivedData.icon" alt="">
      </div>
      <div class="title">
        <!-- {{receivedData.type}} -->
        <span [ngSwitch]="receivedData.type">
          <div *ngSwitchCase="'fruits'">
            Frutta
          </div>
          <div *ngSwitchCase="'snacks'">
            Snack e spuntini
          </div>
          <div *ngSwitchCase="'sweets'">
            Dolci
          </div>
          <div *ngSwitchCase="'soft_drinks'">
            Bevande
          </div>
          <div *ngSwitchCase="'confitures'">
            Marmellate e conserve
          </div>
          <div *ngSwitchCase="'sauces'">
            Salse e condimenti
          </div>
          <div *ngSwitchDefault>

          </div>
        </span>
      </div>
    </div>

    <div class="goBack d-flex align-items-center" (mouseup)="goBack()">
      <div>
        <img src="../../../../../assets/icon/arrow-left-green.svg" alt="">
      </div>
      <div class="back-text">Torna indietro</div>
    </div>

    <div class="qr-list-container d-flex align-items-center">
      <div class="qr-list" *ngFor="let item of qrList">
        <div class="qr-card">
          <div class="type">
            {{ item.product_name }}
          </div>
          <div class="lotto">
            Lotto: {{ item.batch_unique_identifier }}
          </div>
          <div class="qr-path">
            <img [src]="item.image" alt="qrcode" class="qr-image" >
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="d-flex align-items-center justify-content-around">
        <img src="../../../../../assets/icon/logo-citrace-color-1.svg" alt="">
      </div>
    </div>
  </div>
