<!-- <div class="row">
    <div class="col-md-6">
        <div class="card bd-rad bg-color h-100"
            *ngIf="widget.includes('app-widget-latest-transformations'); else noData">
            <div class="card-body">
                <app-widget-latest-transformations></app-widget-latest-transformations>
            </div>
        </div>
    </div>
    <div class="col-md-9 d-flex justify-content-between">
        <div class="card bg-quantity bd-rad mb-1" *ngIf="widget.includes('app-quantity-rejected'); else noData">
            <div class="card-body mini-widget-h">
                <app-quantity-rejected></app-quantity-rejected>
            </div>
        </div>
        <div class="card color-energy bd-rad mb-1">
            <div class="card-body bg-energy mini-widget-h" *ngIf="widget.includes('app-consumed-energy'); else noData">
                <app-consumed-energy></app-consumed-energy>
            </div>
        </div>
        <div class="card color-water bd-rad">
            <div class="card-body bg-water bd-rad mini-widget-h"
                *ngIf="widget.includes('app-widget-water-transformer'); else noData">
                <app-widget-water-transformer></app-widget-water-transformer>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-widget-in-stock'); else noData">
            <div class="card-body">
                <app-widget-in-stock></app-widget-in-stock>
            </div>
        </div>
    </div>
</div>

<div class="row mt-4">
    <div class="col-md-9">
        <div class=" bd-rad  h-100" *ngIf="widget.includes('app-latestShipments'); else noData">
            <div class="">
                <app-latestShipments [actor]="'selection_user_id'"></app-latestShipments>
            </div>
        </div>
    </div>
</div>

<div class="row mt-4">
    <div class="col-md-9">
        <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-widget-products-coming-soon'); else noData">
            <div class="card-body">
                <app-widget-products-coming-soon></app-widget-products-coming-soon>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card bd-rad bg-feedback h-100" *ngIf="widget.includes('feedback'); else noData">
            <div class="card-body">
                <app-widget-feedback></app-widget-feedback>
            </div>
        </div>
    </div>

</div> -->






<div class="d-flex direction">
    <div class="d-flex flex-column cont-1">
        <div class=" col-md-12 d-flex justify-content-between ">
            <div class="quantity">
                <div class="card bg-quantity bd-rad mb-1 "
                    *ngIf="widget.includes('app-quantity-rejected'); else noData">
                    <div class="card-body mini-widget-h">
                        <app-quantity-rejected></app-quantity-rejected>
                    </div>
                </div>
            </div>

            <div class="energy">
                <div class="card color-energy bd-rad mb-1 ">
                    <div class="card-body bg-energy mini-widget-h"
                        *ngIf="widget.includes('app-consumed-energy'); else noData">
                        <app-consumed-energy></app-consumed-energy>
                    </div>
                </div>
            </div>

            <div class="water">
                <div class="card color-water bd-rad ">
                    <div class="card-body bg-water bd-rad mini-widget-h"
                        *ngIf="widget.includes('app-widget-water-transformer'); else noData">
                        <app-widget-water-transformer></app-widget-water-transformer>
                    </div>
                </div>
            </div>

        </div>


        <div class="row mt-3">
            <div class="col-md-12 mb-3">
                <div class=" bd-rad  h-100" *ngIf="widget.includes('app-latestShipments'); else noData">
                    <div class="">
                        <app-latestShipments [actor]="'selection_user_id'"></app-latestShipments>
                    </div>
                </div>
            </div>
        </div>

        <div class=" mb-3">
            <div class="card bd-rad bg-color" *ngIf="widget.includes('app-distributor-lots'); else noData">
                <div class="card-body">
                    <app-distributor-lots [actor]="5"></app-distributor-lots>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="card bd-rad bg-color h-100"
                *ngIf="widget.includes('app-widget-products-coming-soon'); else noData">
                <div class="card-body">
                    <app-widget-products-coming-soon></app-widget-products-coming-soon>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column cont-2">
        <div class="col-md-12 mb-3">
            <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-widget-in-stock'); else noData">
                <div class="card-body">
                    <app-widget-in-stock></app-widget-in-stock>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="card bd-rad bg-feedback h-100" *ngIf="widget.includes('feedback'); else noData">
                <div class="card-body">
                    <app-widget-feedback></app-widget-feedback>
                </div>
            </div>
        </div>


    </div>

</div>











<ng-template #noData>
    <div class="card bd-rad bg-no-data h-100 mb-1">
        <div class="card-body">
            <app-no-data></app-no-data>
        </div>
    </div>
</ng-template>
