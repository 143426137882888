<footer class="footer">
    <div class="mb-sm-5 d-flex flex-row justify-content-evenly align-items-center flex-wrap">
        <p>Darwin Technologies S.r.l.</p>
    </div>

    <hr>

    <div class="px-xl-5 d-flex flex-row flex-wrap justify-content-center">
        <span class="col-12 text-center my-1 my-xl-0 col-xl-auto">
            © All rights reserved {{ currentYear }}
        </span>
    </div>
</footer>
