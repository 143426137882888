import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NonNullableFormBuilder, FormControl } from '@angular/forms';
import { ComponentService } from '../component.service';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit {

  form!: FormGroup;
  addFeedbackForm!: FormGroup;
  isVisible = false;
  operator: any;
  lotto: any;
  rateValue!: 0;
  operatorsToSelect: any;
  operatorsToSelectModal: any;
  isDisabled = true;
  showFeedbackList: boolean = false;
  count: any;
  mod: boolean = false;
  create: boolean = false;
  // lottiOnModal: BehaviorSubject<any> = new BehaviorSubject<any>('')
  // lottiOnModal: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  loadLottiSelect: boolean = true;
  lottiOnModal: any;
  dataFeedback = {
    is_receiver: true,
    lotto_id: null,
    operator_role_name: null,
    search: null,
    skip: 1,
    take: 5
  }


  userLotti: any;


  filter: any;
  pageIndex: number = 1;
  pageSize: number = 2;
  total: BehaviorSubject<any> = new BehaviorSubject<any>(0)
  inputValue?: string;
  options: string[] = [];
  overallRating: any;

  feedbackList: any;

  operators: any = [];

  constructor(
    private fb: FormBuilder,
    private _componentAervice: ComponentService
  ) { }

  ngOnInit() {
    this.getAllFeedback();
    this.getUserBatch();
    this.handleRoles();
    this.form = this.fb.group({
      type: new FormControl(true),
      lotto: new FormControl(null),
      actor: new FormControl(null),
      search: new FormControl(null)
    });
  }

  getAllFeedback() {
    this._componentAervice.getAllFeedback(this.dataFeedback).subscribe(r => {
      this.feedbackList = r.data.feedbacks;
      this.count = r.total

      if (this.feedbackList.length > 0) {

        this.overallRating = r.data.ratingAverage;
        this.operatorsToSelect = r.data.allRoles
        this.operators = []
        r.data.allRoles.forEach((element: any) => {
          switch (element) {
            case 'produttore':
              this.operators.push(
                {
                  img: '../../../assets/icon/prod.svg',
                  name: 'Produttore'
                }
              )
              break;

            case 'consumatore':
              this.operators.push(
                {
                  img: '../../../assets/icon/consumatore.svg',
                  name: 'Consumatore'
                }
              )
              break;

            case 'venditore':
              this.operators.push(
                {
                  img: '../../../assets/icon/venditore.svg',
                  name: 'Venditore'
                }
              )
              break;

            case 'selezione e stoccaggio':
              this.operators.push(
                {
                  img: '../../../assets/icon/trasformatore.svg',
                  name: 'Selezione e stoccaggio'
                }
              )
              break;

            case 'distributore':
              this.operators.push(
                {
                  img: '../../../assets/icon/distributore.svg',
                  name: 'Distributore'
                }
              )
              break;

            default:
              break;
          }
        });
        this.showFeedbackList = true
      } else {
        this.showFeedbackList = false
        this.feedbackList = 'Nessun dato'
      }



    })
  }

  getUserBatch() {
    this._componentAervice.getUserBatch({ is_receiver: this.dataFeedback.is_receiver }).subscribe(r => {
      this.userLotti = r.data

    })
  }

  handlePageEventNotification(page: number): void {
    this.dataFeedback.skip = page

    this.pageIndex = page;
    this.getAllFeedback();
  }

  // onInput(event: Event): void {
  //   const value = (event.target as HTMLInputElement).value;
  //   this.options = value ? [value, value + value, value + value + value] : [];
  // }

  submitForm() {
    this.dataFeedback.is_receiver = this.form.value.type;
    this.dataFeedback.lotto_id = this.form.value.lotto;
    this.dataFeedback.operator_role_name = this.form.value.actor;
    this.dataFeedback.search = this.form.value.search;
    this.getAllFeedback();
    this.getUserBatch();
  }

  addFeedback() {
    this.mod = false;
    this.create = true;

    this.isDisabled = true;

    this.handleRoles();

    this.addFeedbackForm = this.fb.group({
      id: '',
      operator: new FormControl('', Validators.required),
      lotto: new FormControl('', Validators.required),
      vote: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required)
    });

    this.isVisible = true;

  }

  handleOk(): void {
    if (this.addFeedbackForm.valid) {
      let data =
      {
        id: this.addFeedbackForm.value.id,
        receiving_user_role_name: this.addFeedbackForm.value.operator,
        feedback: this.addFeedbackForm.value.description,
        title: this.addFeedbackForm.value.title,
        rating: this.addFeedbackForm.value.vote,
        batch_id: Number(this.addFeedbackForm.value.lotto),
      }

      if (this.mod) {
        this._componentAervice.updateFeedback(data).subscribe(r => {
          this.mod = false
          this.getAllFeedback();
          Swal.fire({
            title: "Feedback aggiornato",
            text: "Feedback aggiornato con successo",
            html: '<img src="assets/icon/success-icon.svg" alt="Custom Icon">',
            // showCloseButton: true,
            showConfirmButton: false,
            timer: 2000
          });
          this.getUserBatch();
        })
      } else if (this.create) {
        this._componentAervice.createNewFeedback(data).subscribe(r => {
          this.getAllFeedback();
          if (r.message == "Feedback già presente") {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              }
            });

            Toast.fire({
              icon: "error",
              title: "Feedback già presente",
            });
          } else {
            Swal.fire({
              title: "Feedback aggiunto",
              text: "Feedback salvato con successo",
              html: '<img src="assets/icon/success-icon.svg" alt="Custom Icon">',
              // showCloseButton: true,
              showConfirmButton: false,
              timer: 2000
            });
            this.create = false
            this.getUserBatch();
          }
        })
      }


      this.isVisible = false;

    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });

      Toast.fire({
        icon: "error",
        title: "Campi mancanti",
        text: "Compila tutti i campi",
      });
    }

  }

  handleCancel(): void {
    this.mod = false;
    this.create = false;
    this.isVisible = false;
  }

  activeSelect() {
    this.loadLottiSelect = false

    this._componentAervice.getBatchByRoleInTimeLine(this.addFeedbackForm.value.operator).subscribe(r => {
      // this.lottiOnModal = ''
      // this.lottiOnModal.next(r.data)
      this.loadLottiSelect = true
      this.lottiOnModal = r.data

      this.isDisabled = false;
    })

  }

  handleRoles() {
    this._componentAervice.handleRoles().subscribe(r => {
      this.operatorsToSelectModal = r.data
    })
  }

  updateFeedback(feedback: any) {
    this.mod = true;
    if (!this.form.value.type) {
      this.handleRoles();
      this.addFeedbackForm = this.fb.group({
        id: feedback.id,
        operator: new FormControl(feedback.role, Validators.required),
        lotto: new FormControl(feedback.batch.batch_unique_identifier, Validators.required),
        vote: new FormControl(feedback.rating, Validators.required),
        title: new FormControl(feedback.title, Validators.required),
        description: new FormControl(feedback.feedback, Validators.required)
      });
      this.activeSelect();
      this.isVisible = true;
    }


  }

}
