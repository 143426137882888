import { Component, OnInit } from '@angular/core';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-water-chart',
  templateUrl: './water-chart.component.html',
  styleUrls: ['./water-chart.component.scss']
})
export class WaterChartComponent implements OnInit {

  selectedField!: string;
  fields: any;

  title: string = 'Acqua';
  data: any
  showWidget: boolean = false;
  placeholder: string = 'Seleziona una provincia';

  constructor(
    private _chart: ChartService,
    private _loggingService: LoggingService, 
    private router: Router
  ) { }

  ngOnInit() {
    this.getMunicipality()
  }

  getMunicipality() {
    this._chart.getMunicipality().subscribe(r => {

      if (r.data.length > 0) {
        this.fields = r.data
        this.selectedField = this.fields[0].id
        this.getData()
      }else{
        this.placeholder = 'Nessun dato'
        this.data = [
          { label: 'Oggi', value: 'Nessun dato' },
        ];
        this.showWidget = true;
      }


    })
  }

  getData() {
    this._chart.getWaterData(this.selectedField).subscribe(r => {
      
      this.data = [
        { label: 'Oggi', value: r.data ? r.data + 'Lt' : 'Nessun dato' },
      ];
      this.showWidget = true;
    })
  }

  explodeWidget() {
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-water-chart'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);
      
    })
  }

}
