<div class="container">
  <div class="d-flex justify-content-between align-items-center header">
    <div class="title">Storico lotti</div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="header-select">
        <nz-select nzPlaceHolder="Lotto" nzBorderless class="wide-select" [(ngModel)]="batch"
          (ngModelChange)="getMyHistoryBatchTimeline(batch)">
          <nz-option *ngFor="let batch of bethes" [nzValue]="batch.batch_id" [nzLabel]="batch.batch_id"></nz-option>
        </nz-select>
      </div>
      <!-- <div class="search">
        <input type="search" [(ngModel)]="search" (keydown.enter)="searchData(search)" nz-input placeholder="Cerca" />
      </div> -->
    </div>
  </div>

  <div class="d-flex align-items-center arrow-back" (click)="goTo()">
    <div>
      <img src="../../../assets/icon/arrow-left-green.svg" alt="">
    </div>
    <div class="all-lots">Tutti i lotti</div>
  </div>

  <div class="roles d-flex align-items-center">
    <div class="role" *ngFor="let role of roles" (click)="selectProductType(role.id)"
      [ngClass]="{'selected-role' : role.selected}">
      {{role.role}}
    </div>
  </div>

  <div class="storico-container">
    <div *ngFor="let item of data">
      <!-- {{item | json}} -->
      <div *ngIf="item.activities" class="d-flex align-items-center activities-container">
        <div *ngFor="let act of item.activities">
          <div class="d-flex align-items-center activities">
            <div class="circle" [ngStyle]="{'background-color': act.color}"></div>
            <div class="act">{{act.activity}}</div>
          </div>
        </div>
      </div>

      <div class="lotto-container d-flex justify-content-between align-items-center">
        <div class="lotto-cont">
          Lotto <span class="lotto">{{item.batch}}</span>
        </div>

        <div class="d-flex align-items-center">
          <div class="lotto-type-icon">
            <img [src]="item.icon" alt="">
          </div>
          <div class="lotto">{{item.type}}</div>
        </div>
      </div>


      <div class="row">
        <div *ngIf="item.dateActions.length > 0" class="grid-container d-flex flex-column">
          <div *ngFor="let day of item.dateActions; let i = index">
            <span class="date">
              {{day.date}}
            </span>

            <div class="row" *ngIf="i === 0">
              <div class="col-1"></div>
              <div class="col-2 border-left d-flex flex-column align-items-center justify-content-center"></div>
              <div *ngIf="item.delivered" class="delivered label col-6">
                Consegnato presso {{item.delivered}}
              </div>
              <div class="col-3"></div>
            </div>

            <div class="row" *ngFor="let action of day.actions">
              <div class="col-1"></div>
              <div class="col-2 border-left d-flex flex-column align-items-center justify-content-center">
                <div class="line-circle-container d-flex align-items-center">
                  <div class="circle-line"></div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="col-6 step-container">
                <div class="d-flex justify-content-between align-items-center">
                  <div class=" action-title margin-steps" [ngStyle]="{'background-color': action.actionColor}">
                    {{action.action}}
                  </div>
                  <div *ngIf="action.establishment" class="label">Stabilimento: {{action.establishment}}</div>
                </div>

                <div *ngIf="action.selectionCriteria" class="criterials-container">
                  <div class="selectionCriteria">Criteri di selezione</div>
                  <ul class="action-content margin-steps" *ngFor="let criterials of action.selectionCriteria">
                    <li>
                      <span class="label">{{criterials.label}}: </span>
                      <span class="value">{{criterials.value}}</span>
                    </li>
                  </ul>
                </div>

                <div class="action-content margin-steps" *ngFor="let step of action.steps">
                  <span class="label">{{step.label}}: </span>
                  <span class="value">{{step.value}}</span>
                </div>
              </div>
              <div class="col-3 notes-container ">
                <div *ngIf="action.notes">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div>
                        <img src="../../../assets/icon/note-icone.svg" alt="">
                      </div>
                      <div class="note">Note</div>
                    </div>
                    <div class="note-text">{{action.notes}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="i === item.dateActions.length - 1">
              <div class="col-1"></div>
              <div class="col-2 border-left d-flex flex-column align-items-center justify-content-center"></div>
              <div *ngIf="item.shipped" class="delivered label col-6">
                Spedito presso {{item.shipped}}
              </div>
              <div class="col-3"></div>
            </div>

          </div>
          <div class=" grid-row">

          </div>
        </div>
        <div *ngIf="item.dateActions.length == 0">
          <h4>Lotto in arrivo</h4>
        </div>
      </div>

    </div>
  </div>

</div>