<div class="shipment-tracking-container">
  <div class="row">
    <div class="col-12 header d-flex justify-content-between align-items-center">
      <div class="title">Monitoraggio spedizioni</div>
      <div class="select-search-container d-flex justify-content-between align-items-center">
        <form nz-form [nzLayout]="'inline'" [formGroup]="form" class="wide-form">
          <div class="select-container form-container d-flex justify-content-between align-items-center">
            <span class="filters">Filtra per lotto:</span>
            <div class="d-flex justify-content-between align-items-center">
              <nz-form-item>
                <nz-select nzPlaceHolder="Lotto" nzBorderless formControlName="lotto" (ngModelChange)="submitForm()"
                  class="wide-select">
                  <nz-option *ngFor="let lotto of batchToSelect" [nzValue]="lotto" [nzLabel]="lotto"></nz-option>
                </nz-select>
              </nz-form-item>
            </div>
          </div>
        </form>

      </div>
    </div>

    <div class="col-12 timeline">

      <div class="row">
        <div class="col-12 d-flex align-items-center padding-b">
          <div class="current-lotto-container d-flex flex-column">
            <span class="current-lotto-title">Lotto</span>
            <span class="current-lotto">{{shipment?.lotto}}</span>
          </div>
          <div class="current-state-container d-flex flex-column">
            <span class="current-lotto-title">Stato</span>
            <span class="shipment-status">{{shipment?.status}}</span>
          </div>
        </div>

        <div class=" col-12 d-flex  align-items-center">
          <div class="steps" *ngFor="let item of shipment?.steps; let i = index; let last = last;">
            <div class="line-container d-flex flex-column">
              <div [ngClass]="{'actor-name-no-wait': item.status != 'wait', 'actor-name-wait': item.status == 'wait'}">
                {{item.name}}
              </div>
              <div class="actor  d-flex align-items-center">

                <div class="actor-img"
                  [ngClass]="{'relative': item.status != 'progress', 'border-finish-color': item.status == 'progress', 'border-wait-color': item.status != 'progress'}">
                  <img [src]="item.path" alt="">
                  <div *ngIf="item.status == 'finish'" class="overlay">
                    <i class="fas fa-check"></i>
                  </div>
                  <div *ngIf="item.status == 'wait'" class="overlay-wait">
                  </div>
                </div>
                <div [ngClass]="{'transit': item.transit}">
                  <div [class.progress-line]="!last"
                    [ngClass]="{'progress-line-finish-color': item.status == 'finish', 'progress-line-wait-color': item.status != 'finish'}">
                  </div>
                </div>

              </div>


              <div class="info d-flex flex-column" [ngClass]="{'hidden': item.status == 'wait'}">
                <span class="step-shipment-status">{{ item.shipmentStatus }}</span>
                <span class="step-shipment-date">
                  {{ item.shipmentStatus == 'Spedito'
                  ? (item.shippingDate | date:'dd/MM/yyyy')
                  : (item.deliveryDate | date:'dd/MM/yyyy') }}
                </span>



                <!-- <span class="details" nz-button nzType="link" (click)="openDetails(i)">
                  Dettagli
                </span> -->

                <span class="details" nz-button nzType="link" nz-popover [(nzPopoverVisible)]="item.visible"
                  (click)="openDetails(item, i)" nzPopoverTrigger="click" nzPopoverPlacement="bottom"
                  [nzPopoverContent]="item.visible ? contentTemplate : NoContentTemplate">
                  Dettagli
                </span>

                <!-- <span class="details" nz-button nzType="link" nz-popover [(nzPopoverVisible)]="visible"
                  (click)="openDetails(item)" nzPopoverTrigger="click">
                  Dettagli
                </span> -->

              </div>

            </div>


            <!-- <div *ngIf="selectedItemIndex === i && visible" class="show-other-details">
              <div class="other-info d-flex flex-column">
                <div class="other-details-actor-container d-flex justify-content-between align-items-center">
                  <div class="other-details-actor">{{item.name}}</div>
                  <span class="x" (click)="closeDetail()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <mask id="mask0_2062_16807" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                        width="24" height="24">
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2062_16807)">
                        <path
                          d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                          fill="#5B6671" />
                      </g>
                    </svg>
                  </span>
                </div>

                <div *ngFor="let site of item?.otherInfo" class="actual-role d-flex flex-column">
                  <div>Spedito: {{ item.shippingDate | date:'dd/MM/yyyy' }}</div>
                  <span [ngSwitch]="item?.name">
                    <span *ngSwitchCase="'Produttore'">
                      <div>Campo: {{site.field}}</div>
                    </span>
                    <span *ngSwitchCase="'Selezione e stoccaggio'">
                      <div>Sede: {{site.site}}</div>
                    </span>
                    <span *ngSwitchCase="'Distributore'">
                      <div>Mezzo: {{site.shippingType}}</div>
                    </span>
                    <span *ngSwitchCase="'Punto Vendita'">
                      <div>Punto: {{site.marketPoint}}</div>
                    </span>
                    <span *ngSwitchCase="'Consumatore'">
                      <div>Sede: {{site.consSite}}</div>
                    </span>
                    <span *ngSwitchDefault>

                    </span>
                  </span>
                </div>

                <div class="pre-role d-flex flex-column">
                  <div>
                    <span class="prod-details-title">Lotto: </span>
                    <span class="prod-details-data">{{shipment?.lotto}}</span>
                  </div>
                  <div>
                    <span class="prod-details-title">Prodotto: </span>
                    <span class="prod-details-data">{{shipment?.cultivar}}</span>
                  </div>
                  <div>
                    <span class="prod-details-title">Quantità: </span>
                    <span class="prod-details-data">{{shipment?.quantity}} kg</span>
                  </div>
                </div>
              </div>
            </div> -->










          </div>
        </div>

        <ng-template #NoContentTemplate></ng-template>

        <div class="show-other-details" [ngClass]="{'none': !visible}">
          <ng-template #contentTemplate>
            <div *ngIf="item?.status != 'wait' && visible">
              <div class="other-info d-flex flex-column">
                <div class="other-details-actor-container d-flex justify-content-between align-items-center">
                  <div class="other-details-actor">{{item?.name}}</div>
                  <span class="x" (click)="closeDetail()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <mask id="mask0_2062_16807" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                        width="24" height="24">
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2062_16807)">
                        <path
                          d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                          fill="#5B6671" />
                      </g>
                    </svg>
                  </span>
                </div>

                <div *ngFor="let site of item?.otherInfo" class="actual-role d-flex flex-column">
                  <div *ngIf="item?.shippingDate">Spedito: {{ item?.shippingDate | date:'dd/MM/yyyy' }}</div>
                  <div *ngIf="item?.shippingDate==null">Spedito: Non ancora spedito</div>

                  <span [ngSwitch]="item?.name">
                    <span *ngSwitchCase="'Produttore'">
                      <div>Campo: {{site.field}}</div>
                    </span>
                    <span *ngSwitchCase="'Selezione e stoccaggio'">
                      <div>Sede: {{site.site}}</div>
                    </span>
                    <span *ngSwitchCase="'Distributore'">
                      <div>Mezzo: {{site.shippingType}}</div>
                    </span>
                    <span *ngSwitchCase="'Punto Vendita'">
                      <div>Punto: {{site.marketPoint}}</div>
                    </span>
                    <span *ngSwitchCase="'Consumatore'">
                      <div>Sede: {{site.consSite}}</div>
                    </span>
                    <span *ngSwitchDefault>

                    </span>
                  </span>
                </div>

                <div class="pre-role d-flex flex-column">
                  <div class="other-details-actor">Prodotto</div>
                  <div>
                    <span class="prod-details-title">Lotto: </span>
                    <span class="prod-details-data">{{shipment?.lotto}}</span>
                  </div>
                  <div>
                    <span class="prod-details-title">Prodotto: </span>
                    <span class="prod-details-data">{{shipment?.cultivar}}</span>
                  </div>
                  <div>
                    <span class="prod-details-title">Quantità: </span>
                    <span class="prod-details-data">{{shipment?.quantity}} kg</span>
                  </div>
                </div>
              </div>
            </div>

          </ng-template>
        </div>





      </div>

    </div>

  </div>
</div>



<!-- <ng-template #contentTemplate>
  <div class="other-info d-flex flex-column">
    <div>Spedito</div>
    <div>campo</div>
  </div>
  <a (click)="clickMe()">Chiudi</a>
</ng-template> -->