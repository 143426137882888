import { Component, OnInit } from '@angular/core';
import { ComponentService } from '../component.service';
import { Router, ActivatedRoute  } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lotHistoryTimeline',
  templateUrl: './lotHistoryTimeline.component.html',
  styleUrls: ['./lotHistoryTimeline.component.scss']
})
export class LotHistoryTimelineComponent implements OnInit {

  search!: string;
  selectedRoleId: any;
  currentRole: any;
  batch: any;

  roles = [
    {role: 'Produttore', id: 2, selected: false},
    {role: 'Selezione e stoccaggio', id: 5, selected: false},
    {role: 'Distributore', id: 6, selected: false},
    {role: 'Punto vendita', id: 4, selected: false},
  ];



  data: any;
  bethes: any;


  constructor(
    private _componentService: ComponentService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getBatches()
    this.batch = this.route.snapshot.paramMap.get('lotto');    
    this.getRole()
    
  }

  getRole() {
    this._componentService.getUserRole().subscribe(r => {
      this.currentRole = r;
      this.firstRole() 
      this.getMyHistoryBatchTimeline()     
    })
  }

  getBatches() {
    this._componentService.getMyBatchesOnTimeLine().subscribe(r => {
      this.bethes = r.data      
    })
  }

  searchData(data: string) {
    this.batch = data
    this.getMyHistoryBatchTimeline();
  }

  firstRole(): void {
    this.roles.forEach(p => p.selected = false);
    const roleId = this.roles.find(p => p.id === this.currentRole);
    if (roleId) {
      roleId.selected = true;
      this.selectedRoleId = roleId.id
    } 
  }

  selectProductType(id: number): void {
    this.roles.forEach(p => p.selected = false);
    const roleId = this.roles.find(p => p.id === id);
    if (roleId) {
      roleId.selected = true;
      this.currentRole = roleId.id
      this.getMyHistoryBatchTimeline()
    } 
  }

  goTo() {
      this.router.navigate(['/lot-history']);

  }

  getMyHistoryBatchTimeline(batch? : any) {
    if (batch) {
      this.batch = batch
    }
    
    this._componentService.getMyHistoryBatchTimeline(this.currentRole, this.batch).subscribe(r => {
      // console.log('TIMELINE',r.data);
      this.data = [r.data]
    }, er => {
      Swal.fire({
        title: "Lotto inesistente",
        text: "Il lotto inserito è inesistente",
        showConfirmButton: false,
        icon: "warning",
      });
      
    }
  )
  }

}
