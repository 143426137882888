<div *ngIf="showWidget">

  <div class="d-flex justify-content-between align-items-center">
    <div>
      <h4 class="header-widget">Andamento Produzione</h4>
    </div>
    <div class="d-flex align-items-center">
      <div class="select-year">
        <nz-select class="test" nzPlaceHolder="Seleziona un anno" [(ngModel)]="selectedYear"
          (ngModelChange)="getData()">
          <nz-option nzLabel="In corso" [nzValue]="null"></nz-option>
          <nz-option *ngFor="let year of years" [nzLabel]="year" [nzValue]="year" class="color">{{year}}</nz-option>
        </nz-select>
      </div>
      <span class="arrow-icon" style="cursor: pointer;" (click)="explodeWidget()">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>
  </div>

  <div *ngIf="barChartData.datasets[0].data" style="display: block">
    <canvas id="barChart" baseChart [data]="barChartData" [options]="barChartOptions" [type]="barChartType"
      [legend]="barChartLegend">
    </canvas>
  </div>

  <div *ngIf="!barChartData.datasets[0].data" style="display: block">
    <canvas id="barChart" baseChart [options]="barChartOptions" [type]="barChartType" [legend]="barChartLegend">
    </canvas>
  </div>

  <!-- <div *ngIf="isEmpty" class="no-data">
    <h2>No data</h2>
  </div> -->

</div>