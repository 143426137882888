import { Component, OnInit } from '@angular/core';
import { ComponentService } from 'src/app/components/component.service';

@Component({
  selector: 'app-DiscardOfTheDay',
  templateUrl: './DiscardOfTheDay.component.html',
  styleUrls: ['./DiscardOfTheDay.component.scss']
})
export class DiscardOfTheDayComponent implements OnInit {

  data: any;
  show: boolean = false;

  constructor(
    private _componentService: ComponentService
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this._componentService.scartoDelGiorno().subscribe(r => {
      console.log('LOGGGGGGG', r);
      this.data = r.data;
      this.show = true;
    })
  }

}
