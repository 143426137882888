<div class="consumer-container">
  <div class="d-flex header">
    <div class=" left-col">
      <div class="d-flex flex-column">
        <div class="header-icon">
          <img src="../../../../../assets/icon/logo-citrace-color-1.svg" alt="">
        </div>
        <div class="header-text">
          <div>
            Benvenuto su <span>CiTrace</span>
          </div>
          <p>
            La piattaforma che ti permette di tracciare la filiera dei tuoi prodotti
          </p>
        </div>
        <div class="button-header">
          Scopri la storia del prodotto
        </div>
      </div>
    </div>
    <div class=" center-column">
      <div class="timeline-exemple">
        <img src="../../../../../assets/img/consumatore-mobile.png" alt="">
        <div class="triangle"></div>
      </div>
    </div>
    <div class=" header-left-img">
      <img src="../../../../../assets/img/login.svg" alt="">
    </div>

  </div>
  <div class="middle-container">
    <div class="middle row">
      <div class="img-middle col-6">
      </div>
      <div class="middle-text d-flex flex-column col-6">
        <div class="big-text">
          Conosci tutta la vita dell’agrume che porti a casa
        </div>
        <div class="d-flex">
          <div class="icon">
            <img src="../../../../../assets/img/icon-orange.svg" alt="">
          </div>
          <div class="middle-botton">
            Che cos’é CiTrace?
          </div>
        </div>
        <p>
          Citrace è una piattaforma integrata di tracciabilità per la filiera agroalimentare, specificamente progettata
          per il settore delle arance e dei limoni. La piattaforma facilita la raccolta, la gestione e la condivisione
          di informazioni dettagliate sui prodotti lungo tutte le fasi della filiera, dalla produzione alla vendita al
          dettaglio.
        </p>
      </div>
    </div>
  </div>
  <div class="bottom-container">
    <div class="d-flex flex-column">
      <div class="bottom-title">
        Vuoi conoscere la storia dei prodotti che stai mangiando?
      </div>
      <p>Seleziona la categoria che ti interessa</p>

      <div class="card-container">
        <div class="card-list " *ngFor="let card of products">
          <div class="cons-card" (mouseup)="goToProd(card.qrPats, card)">
            <div class="card-icon">
              <img [src]="card.icon" alt="">
            </div>
            <div class="card-type">
              <span [ngSwitch]="card.type">
                <div *ngSwitchCase="'fruits'">
                  Frutta
                </div>
                <div *ngSwitchCase="'snacks'">
                  Snack e spuntini
                </div>
                <div *ngSwitchCase="'sweets'">
                  Dolci
                </div>
                <div *ngSwitchCase="'soft_drinks'">
                  Bevande
                </div>
                <div *ngSwitchCase="'confitures'">
                  Marmellate e conserve
                </div>
                <div *ngSwitchCase="'sauces'">
                  Salse e condimenti
                </div>
                <div *ngSwitchDefault>

                </div>
              </span>
            </div>
            <div class="card-quantity">{{card.quantity}} prodotti</div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="footer">
    <div class="d-flex align-items-center justify-content-around">
      <img src="../../../../../assets/icon/logo-citrace-color-1.svg" alt="">
    </div>
  </div>
</div>