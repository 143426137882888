import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FeedbackService } from 'src/app/shared/services/feedback.service';

@Component({
  selector: 'app-widget-feedback',
  templateUrl: './widget-feedback.component.html',
  styleUrls: ['./widget-feedback.component.scss'],
})
export class WidgetFeedbackComponent {
  elements = [
    {
      label: 'Selezione e Stoccaggio',
      image: '/assets/icon/stoccaggio.svg',
      stars: '/assets/img/stelle/tre.svg',
    },
    {
      label: 'Distributore',
      image: '/assets/icon/distribuzione.svg',
      stars: '/assets/img/stelle/cinque.svg',
    },
    {
      label: 'Punto vendita',
      image: '/assets/icon/vendita.svg',
      stars: '/assets/img/stelle/quattro.svg',
    },
    {
      label: 'Consumatore',
      image: '/assets/icon/consumatore.svg',
      stars: '/assets/img/stelle/cinque.svg',
    },
  ];

  feedback: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  noData!: boolean;

  imagePaths: any = {
    'Selezione e stoccaggio': '/assets/icon/stoccaggio.svg',
    Distributore: '/assets/icon/distribuzione.svg',
    Produttore: '/assets/icon/prod.svg',
    Venditore: '/assets/icon/vendita.svg',
    Consumatore: '/assets/icon/consumatore.svg',
  };

  constructor(
    private feedbackService: FeedbackService,
    private auth: AuthService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils: Utils
  ) {}

  ngOnInit(): void {
    this.getFeedbackUser();
  }

  getFeedbackUser() {
    this.feedbackService
      .getUserFeedback({ receiving_user_id: this.auth.currentUser.value.id })
      .subscribe((res) => {
        console.log('Feedback', res.data);
        if (res.data.length > 0) {
          this.feedback.next(res.data);
          this.noData = false;
        } else {
          this.feedback.next([
            {
              label: 'Nessun dato',
            },
          ]);
          this.noData = true;
        }
      });
  }

  getImagePath(label: string): string {
    return this.imagePaths[label];
  }

  toCamelCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  explodeWidget() {
    this._utils.handleWidgetClick('/auth/feedback', 9);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-feedback',
    };
    this._loggingService.logClick(data).subscribe((r) => {
      this.router.navigate(['auth/feedback']);
    });
  }
}
