import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  @Output() defaultDateChanged: EventEmitter<string> = new EventEmitter<string>();
  defaultDate: any;
  mode = 'month';

  constructor() {
    const today = new Date();
    this.defaultDate = today.toISOString().split('T')[0];
    this.defaultDateChanged.emit(this.defaultDate);
  }

  ngOnInit(): void {

  }

  panelChange(event: any): void {
    console.log('panelChange', event);
  }

  selectChange(event: any): void {
    console.log('selectChange', event);
  }

  onValueChange(value: Date): void {
    this.defaultDate = value.toISOString().split('T')[0];    
    this.defaultDateChanged.emit(this.defaultDate);
  }

  onPanelChange(change: { date: Date; mode: string }): void {
    console.log(`PANEL CHANGE value: ${change.date}`);
  }
}
