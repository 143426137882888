import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  // getAllUsers(data: any) {
  //   return this.http.post<any>(`${environment.api}/get-all-users`, data);
  //   }

  confirmUser(data: any) {
    return this.http.get<any>(`${environment.api}/confirm-user/${data}`);
  }

  disable(data: any) {
    return this.http.get<any>(`${environment.api}/disable-user/${data}`);
  }

  deleteUser(id: any) {
    return this.http.delete<any>(`${environment.api}/user/${id}`);
  }

  getAllRoles() {
    return this.http.get<any>(`${environment.api}/all-roles`);
  }

  getAllwidget(data: any) {
    return this.http.post<any>(`${environment.api}/widgets`, data);
  }

  getAllwidgetSelectRole(data: any) {
    return this.http.post<any>(`${environment.api}/widgetsRole`, data);
  }



  getAllwidgetRole(role_id: any) {
    return this.http.get<any>(`${environment.api}/widgets_role/${role_id}`);

  }
  attachRole(data: any) {
    return this.http.post<any>(`${environment.api}/attache-role-widget`, data);
  }

  detachRole(data: any, id: any) {
    return this.http.post<any>(`${environment.api}/detach-role-widget/${id}`, data);
  }



}


