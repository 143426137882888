<div class="row energy-container">
  <div class="energy-content">
    <div class="col-6 d-flex justify-content-between title-cont">
      <!-- <div class="title">Energia</div> -->
      <h4 class="header-widget">Energia</h4>
      <!-- <div class="select-field">
        <nz-select [nzPlaceHolder]="placeHolder" [(ngModel)]="selectedField" (ngModelChange)="getData()">
          <nz-option *ngFor="let p of prov" [nzLabel]="p.label" [nzValue]="p.id" class="color">
            {{p}}</nz-option>
        </nz-select>
      </div> -->
      <span style="cursor: pointer;" (click)="explodeWidget()">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>
    <div class="col-12 text-mini-widget">
      <p>Consumo medio per Kg di agrumi</p>
    </div>
    <div class="col-12 data-cont">
      <span class="data">{{kvh ? kvh : 0}}</span>
      <span class="kWh">kWh</span>
    </div>
  </div>
</div>