<div class="row water-container">
  <div class="d-flex justify-content-between mytop">
    <div>
      <h4 class="header-widget mycl">{{ title }}</h4>
    </div>
    <div class="select-field mycl2">
      <nz-select [nzPlaceHolder]="placeholder" [(ngModel)]="selectedField" (ngModelChange)="getData()">
        <nz-option *ngFor="let field of fields" [nzLabel]="field.label" [nzValue]="field.id" class="color">
          {{field}}</nz-option>
      </nz-select>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <div *ngFor="let item of data">
      <div class="label">{{ item.label }}</div>
      <div class="data">{{ item.value }}</div>
    </div>
  </div>
</div>