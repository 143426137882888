import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-container1',
  templateUrl: './container1.component.html',
  styleUrls: ['./container1.component.scss']
})
export class Container1Component {
  widget = new Array;
  is_dashboard = true;
  @Input() widgetsFromPredict: []  = [];

  constructor(
    private authService: AuthService,
    private adminService: AdminService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.authService.getUser().subscribe(r => {
      let role_id = localStorage.getItem('role_id');
      this.getAllWidget(role_id);
    });
  }

  getAllWidget(role_id: any) {
    console.log(this.widgetsFromPredict);
    if (this.widgetsFromPredict.length === 0) {
      this.adminService.getAllwidgetRole(role_id).subscribe(r => {
        r.data.forEach((element: { value: any; }) => {
          this.widget.push(element.value);
        });
        this.cdr.detectChanges(); // Manually trigger change detection
      });
    }
    else {
      this.widget = Object.values(this.widgetsFromPredict);
      console.log(this.widget);
      this.cdr.detectChanges();
    }
  }

}
