import { Component, OnInit } from '@angular/core';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sun-chart',
  templateUrl: './sun-chart.component.html',
  styleUrls: ['./sun-chart.component.scss']
})
export class SunChartComponent implements OnInit {


  selectedField!: string;
  fields: any;

  title: string = 'Suolo';
  data: any
  placeholder: string = 'Seleziona una provincia';

  constructor(
    private _chart: ChartService,
    private _loggingService: LoggingService, 
    private router: Router
  ) { }

  ngOnInit() {
    this.getMunicipality()

  }

  getMunicipality() {
    this._chart.getMunicipality().subscribe(r => {
      if (r.data.length > 0) {
        this.fields = r.data
        this.selectedField = this.fields[0].id
        this.getData()
      }else{
        this.placeholder = 'Nessun dato'
        this.data = [
          { label: 'Umidità', value: 'Nessun dato' },
          { label: 'Temperatura', value: 'Nessun dato' }
        ];
      }
      

    })
  }

  getData() {

    this._chart.getSunData(this.selectedField).subscribe(r => {
      this.data = [
        { label: 'Umidità', value: r.data ? r.data.humidity_percentage + '%' : 'Nessun dato' },
        { label: 'Temperatura', value: r.data ? r.data.temperature + '°' : 'Nessun dato' }
      ];


    })

  }

  explodeWidget() {
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-sun-chart'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);
      
    })
  }

}
