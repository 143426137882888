<div class="row mb-4">
  <div class="col-3">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-SalesStatus'); else noData">
      <div class="bg-sale-status border-mini-widget">
        <div class="bg-img-sale-status widget-min-h">
          <app-SalesStatus></app-SalesStatus>
        </div>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-SalesStatus'); else noData">
      <div class="bg-discard-day border-mini-widget widget-min-h">
        <app-DiscardOfTheDay></app-DiscardOfTheDay>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-SalesStatus'); else noData">
      <div class="bg-wite border-mini-widget widget-min-h">
        <app-bestSellingProduct></app-bestSellingProduct>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-SalesStatus'); else noData">
      <div class="bg-add-day-report border-mini-widget widget-min-h bg-img-add-day-report">
        <app-addReport></app-addReport>
      </div>
    </div>
  </div>
</div>

<div class="row mb-4">
  <div class="col-3">
    <div class=" bg-color h-100 " *ngIf="widget.includes('app-ProductsForSale'); else noData">
      <div class="bg-wite border-mini-widget big-widget-h">
        <div>
          <app-ProductsForSale></app-ProductsForSale>
        </div>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card bd-rad bg-color h-100  " *ngIf="widget.includes('app-SalesTimes'); else noData">
      <div class="bg-wite border-mini-widget big-widget-h">
        <div>
          <app-SalesTimes></app-SalesTimes>
        </div>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-SellerInStock'); else noData">
      <div class="bg-wite border-mini-widget big-widget-h">
        <div>
          <app-SellerInStock></app-SellerInStock>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mb-4">
  <div class="col-9">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-sellerUpcomingProducts'); else noData">
      <div class="bg-wite border-mini-widget">
        <app-sellerUpcomingProducts></app-sellerUpcomingProducts>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="card bd-rad bg-feedback h-100" *ngIf="widget.includes('feedback'); else noData">
      <div class="card-body">
        <app-widget-feedback></app-widget-feedback>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row mb-4">
  <div class="col-9">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-distributor-lots'); else noData">
      <div class="card-body">
        <app-distributor-lots></app-distributor-lots>
      </div>
    </div>
  </div>
</div> -->

<div class="row mb-4">
  <div class="col-9">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-latestShipments'); else noData">
      <app-latestShipments [actor]="'venditore_id'"></app-latestShipments>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="card bd-rad bg-no-data h-100 mb-1">
    <div class="card-body">
      <app-no-data></app-no-data>
    </div>
  </div>
</ng-template>