import { Component, Input } from '@angular/core';
import { IDayData } from '../interfaces/IDayData';

@Component({
  selector: 'app-weather-day-card',
  templateUrl: './weather-day-card.component.html',
  styleUrls: ['./weather-day-card.component.scss'],
})
export class WeatherDayCardComponent {
  @Input() dayWeather: IDayData | null = null;
  @Input() isSelected: boolean = false;

  getIconPath(conditionSummary: string): string {
    const iconBasePath = '../../../../assets/icon/previsioni/';
    const iconMap: { [key: string]: string } = {
      Clear: 'soleggiato.svg',
      Clouds: 'nuvoloso.svg',
      Rain: 'piovoso.svg',
      nebbioso: 'nebbioso.svg',
      Thunderstorm: 'temporalesco.svg',
      possibile_pioggia: 'possibile_pioggia.svg',
      neve: 'neve.svg',
    };
    return iconBasePath + (iconMap[conditionSummary] || 'soleggiato.svg');
  }
}
