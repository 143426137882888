import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ComponentService } from 'src/app/components/component.service';
import { BehaviorSubject } from 'rxjs';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-widget-arriving-departing-products',
  templateUrl: './widget-arriving-departing-products.component.html',
  styleUrls: ['./widget-arriving-departing-products.component.scss']
})
export class WidgetArrivingDepartingProductsComponent implements OnInit {

  selectedValue = 'true';
  arriving = new BehaviorSubject<string>('Arrivo');
  loaded: boolean = false

  cols = [
    'Lotto',
    this.arriving.value,
    'Azienda',
    'Prodotto',
    'In magazzino',
    'Quantitá'
  ]

  colsPartenza = [
    'Lotto',
    'Partenza',
    'Prodotto',
    'Quantitá',
    'Destinazione',
    'Mezzo'
  ]

  // data = [
  //   {
  //     batchId : '000347',
  //     shippingDate: '7 aprile 2024',
  //     deliveryDate: '10 aprile 2024',
  //     agency: 'Minoldi Srl',
  //     product: 'Arancia tarocco',
  //     arriving: true,
  //     timeInStock: 6,
  //     quantity: 1

  //   },
  //   {
  //     batchId : '000348',
  //     shippingDate: '7 aprile 2024',
  //     deliveryDate: '10 aprile 2024',
  //     agency: 'Maurizio Vivaldi',
  //     product: 'Limoni Verdello',
  //     arriving: false,
  //     timeInStock: 7,
  //     quantity: 1

  //   },
  //   {
  //     batchId : '000349',
  //     shippingDate: '7 aprile 2024',
  //     deliveryDate: '10 aprile 2024',
  //     agency: 'Mediterraneo Srl.',
  //     product: 'Arancia tarocco',
  //     arriving: true,
  //     timeInStock: 6,
  //     quantity: 1

  //   },
  //   {
  //     batchId : '0002219',
  //     shippingDate: '7 aprile 2024',
  //     deliveryDate: '10 aprile 2024',
  //     agency: 'Mariotti Soc. Coop.',
  //     product: 'Limoni Verdello',
  //     arriving: false,
  //     timeInStock: 2,
  //     quantity: 0.2

  //   }
  // ]

  data!: any

  constructor(
    private _loggingService: LoggingService,
    private router: Router,
    private _componentService: ComponentService,
    private _utils: Utils
  ) { }

  ngOnInit() {
    // console.log('ARRIVO', this.arriving.value);

    this.getData()
  }

  getData() {
    // console.log('COLS', this.cols);
    this._componentService.lastDistributoreShipping(this.selectedValue).subscribe(r => {
      this.loaded = false;
      this.data = r.data
      console.log('TAB LOG', this.data);

      if (this.data[0].arriving) {
        // this.arriving.next('Arrivo');
        this.cols[1] = 'Arrivo'
      } else {
        // this.arriving.next('Partenza');
        this.cols[1] = 'Partenza'
      }

      this.loaded = true;

    })

  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 17);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-arriving-departing-products'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);

    })
  }

}
