<div class="no-scroll" *ngIf="!scroll">
  <div [ngClass]="{'container-lg': containerLg, 'container-sm': containersm}">
    <div class="element-card" *ngFor="let item of products.slice(0, numCard)">
      <nz-card nzHoverable [nzCover]="coverTemplate">
        <nz-card-meta [nzTitle]="item.title" [nzDescription]="item.desc"></nz-card-meta>
      </nz-card>
      <ng-template #coverTemplate>
        <img alt="example" [src]='item.path' />
      </ng-template>
    </div>
  </div>
  <div class="all">
    <p (click)="seeAll()">Vedi tutti</p>
  </div>
</div>

<!-- <div class="scroll" *ngIf="scroll">
  <div class="scroll-container" [ngClass]="{'container-lg': containerLg, 'container-sm': containersm}">
    <div class="element-card" *ngFor="let item of products.slice(0, 10)">
      <nz-card nzHoverable [nzCover]="coverTemplate">
        <nz-card-meta [nzTitle]="item.title" [nzDescription]="item.desc"></nz-card-meta>
      </nz-card>
      <ng-template #coverTemplate>
        <img alt="example" [src]='item.path' />
      </ng-template>
    </div>
  </div>
  <div class="all">
    <p>Vedi tutti</p>
  </div>
</div> -->


<div class="scroll" *ngIf="scroll">
  <!-- <button class="arrow left" (click)="scrollLeft()">&lt;</button> -->
  <button class="arrow left" [ngClass]="{'d-none': containersm}" (click)="scrollLeft()">
    <img src="../../../assets/icon/icon-arrow-left.png" alt="">
  </button>
  <div class="scroll-container" [ngClass]="{'container-lg': containerLg, 'container-sm': containersm}" fxLayout="row"
    fxLayoutWrap fxLayoutAlign="start start">
    <div class="element-card" *ngFor="let item of products.slice(0, numCardScroll)" fxFlex="calc(33.33% - 16px)">
      <nz-card nzHoverable [nzCover]="coverTemplate" class="card">
        <nz-card-meta [nzTitle]="item.title" [nzDescription]="item.desc"></nz-card-meta>
      </nz-card>
      <ng-template #coverTemplate>
        <img alt="example" [src]='item.path' />
      </ng-template>
    </div>
  </div>
  <!-- <button class="arrow right" (click)="scrollRight()">&gt;</button> -->
  <button class="arrow right" [ngClass]="{'d-none': containersm}" (click)="scrollRight()">
    <img src="../../../assets/icon/icon-arrow-right.png" alt="">
  </button>
  <div class="all">
    <p (click)="seeAll()">Vedi tutti</p>
  </div>
</div>