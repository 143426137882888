import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser: User | undefined = undefined;
  isDrawerVisible: boolean = false;

  pages: Page[] = [
    { icon: 'home', name: 'Dashboard', path: 'app/dashboard', selected: false },
  ];

  version = 'v1.2.4';
  constructor(private _auth: AuthService, private router: Router) {
    this.currentUser = this._auth.currentUser.value;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      let _url = event.urlAfterRedirects;

      this.pages.filter(page => page.selected == true).forEach(page => page.selected = false);


      let el = this.pages.find(page => { return _url.includes(page.path?.split('/').pop()) });
      if (el) el.selected = true;
    });
  }

  ngOnInit(): void { }

  // logout() {
  //   this._auth.logout().subscribe((res) => {
  //     this._auth.currentUser.next(null);
  //     localStorage.removeItem('csrf');
  //     this.router.navigate(['/auth/login']);
  //   });
  // }

  logout() {
    this._auth.logout().subscribe((res) => {
      this.deleteCookie('access_token');
      this._auth.currentUser.next(null);

      this.router.navigate(['/auth/login']);
    })

  }

  deleteCookie(name: string) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() - 1);
    const cookieValue = encodeURIComponent(name) + '=;expires=' + expireDate.toUTCString() + ';path=/';
    document.cookie = cookieValue;
  }

  changeDrawerStatus() {
    this.isDrawerVisible = !this.isDrawerVisible;
  }

  selectPage() {
    this.isDrawerVisible = !this.isDrawerVisible;
  }
}
