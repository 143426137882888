import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-ProductsForSale',
  templateUrl: './ProductsForSale.component.html',
  styleUrls: ['./ProductsForSale.component.scss']
})
export class ProductsForSaleComponent implements OnInit {

  // data = [
  //   {
  //     image: '../../../../../../assets/img/icon-orange.svg',
  //     lotto: '005678',
  //     type: 'Arancia tarocco',
  //     prod: 'Nicosia agrumi',
  //     total: 200,
  //     sold: 40,
  //     status: 'Buono',
  //     dayInSale: 3,
  //     rejected: 20
  //   },
  //   {
  //     image: '../../../../../../assets/img/agrumi/lemon-icon.svg',
  //     lotto: '005678',
  //     type: 'Limone tarocco',
  //     prod: 'Orto',
  //     total: 150,
  //     sold: 80,
  //     status: 'Discreto',
  //     dayInSale: 6,
  //     rejected: 15
  //   }
  // ]
  @ViewChild('carouselElement')carouselElement!:ElementRef;

  data: any;
  info: any;

  constructor(
    private router: Router,
    private _loggingService: LoggingService,
    private _componentService: ComponentService,
    private renderer: Renderer2,
    private _utils: Utils
  ) { }

  ngOnInit() {
    this.getLastProductVendit();
  }

  ngAfterViewInit() {
    const carousel = this.carouselElement.nativeElement;
    this.renderer.listen(carousel, 'slid.bs.carousel', (event) => {
      const activeIndex = event.to;
      const currentItem = this.data[activeIndex];
      console.log('Elemento corrente:', currentItem);
      this.info = [
        {
          status: currentItem.status,
          dayInSale: currentItem.dayInSale,
          rejected: currentItem.rejected
        }
      ]
    });
  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 1);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-doughnut-and-pie-chart'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);

    })
  }

  getLastProductVendit() {
    this._componentService.getLastProductVendit().subscribe(r => {
      console.log('CAROUSEL', r);
      this.data = r.data;
      this.info = [
        {
          status: this.data[0].status,
          dayInSale: this.data[0].dayInSale,
          rejected: this.data[0].rejected
        }
      ]
    })
  }


}
