import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ChartService } from '../charts/chart.service';
import { ComponentService } from '../component.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mySystemTransformer',
  templateUrl: './mySystemTransformer.component.html',
  styleUrls: ['./mySystemTransformer.component.scss'],
})
export class MySystemTransformerComponent implements OnInit {
  @Input() roleId!: any;
  @Input() type!: any;
  @Input() plantId!: any;
  @Output() variabileOutput = new EventEmitter<string>();

  systemForm!: FormGroup;

  typeNameForm!: any;

  municipality: any;

  province: any;

  dataRole: any;

  dataRoleId: any;

  switchDefault: any;

  showForm: boolean = false;
  isVisible = false;

  provinceSelected: any;
  municipalitySelected: any;
  productTypeSelected: any;

  productType = [
    { label: 'Arance', id: 1 },
    { label: 'Limoni', id: 2 },
  ];
  constructor(
    private _componentService: ComponentService,
    private _chartService: ChartService,
    private fb: FormBuilder
  ) {
    this.systemForm = this.fb.group({
      systemName: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      cap: new FormControl(null, Validators.required),
      warehouseCapacity: new FormControl(null, Validators.required),
      cultivatedHectares: new FormControl(null),
      KmOfCrops: new FormControl(null),
      cultivatedValue: new FormControl(null),
      soilType: new FormControl(null),
    });
  }

  ngOnInit() {
    this._chartService.getMarketMunicipality().subscribe((r) => {
      this.municipality = r.data;
      switch (this.roleId) {
        case 4:
          this.typeNameForm = 'Nome negozio';
          this.getVendorInfo();
          break;
        case 5:
          this.getTransformerInfo();
          this.typeNameForm = 'Nome impianto';
          break;
        case 6:
          this.typeNameForm = 'Nome sede';
          this.getDistributorInfo();
          break;
        case 2:
          this.typeNameForm = 'Nome sede';
          if (this.plantId) {
            this.getSinglePlantInfo();
          }
          this.addAdditionalFields();

          break;

        default:
          break;
      }
    });
  }

  addAdditionalFields() {
    this.systemForm
      .get('cultivatedHectares')
      ?.setValidators(Validators.required);
    this.systemForm.get('KmOfCrops')?.setValidators(Validators.required);
    this.systemForm.get('cultivatedValue')?.setValidators(Validators.required);
    this.systemForm.get('soilType')?.setValidators(Validators.required);

    this.systemForm.get('cultivatedHectares')?.updateValueAndValidity();
    this.systemForm.get('KmOfCrops')?.updateValueAndValidity();
    this.systemForm.get('cultivatedValue')?.updateValueAndValidity();
    this.systemForm.get('soilType')?.updateValueAndValidity();
  }

  getMunicipalityByProvince(provinceId: string) {
    this._chartService
      .getMunicipalityByProvince(provinceId)
      .subscribe((response) => {
        this.province = response.data;
        if (this.province) {
          const selectedmunicipality = this.province.find(
            (item: any) => item.label === this.dataRole.municipality
          );
          this.municipalitySelected = selectedmunicipality;
        }
      });
  }

  saveSystem() {
    console.log(this.systemForm.value);

    if (
      !this.systemForm.valid ||
      !this.municipalitySelected ||
      !this.provinceSelected
    ) {
      this.error();
      return;
    }

    const data = this.getTransformerOrDistributorData();
    const producerData = this.getProducerData();

    const roleActions: { [key: number]: () => void } = {
      5: () => this.handleRole(data, 'Transformer'),
      6: () => this.handleRole(data, 'Distributor'),
      4: () => this.handleRole(data, 'Vendor'),
      2: () => this.handleProducerRole(producerData),
    };

    if (roleActions[this.roleId]) {
      roleActions[this.roleId]();
    } else {
      this.error();
    }
  }

  getTransformerOrDistributorData() {
    return {
      company_name: this.systemForm.value.systemName,
      address: this.systemForm.value.address,
      municipality_id: this.municipalitySelected.id,
      province_id: this.provinceSelected.id,
      cap: this.systemForm.value.cap,
      capacity: this.systemForm.value.warehouseCapacity,
    };
  }

  getProducerData() {
    return {
      name: this.systemForm.value.systemName,
      address: this.systemForm.value.address,
      municipality_id: this.municipalitySelected.id,
      province_id: this.provinceSelected.id,
      cap: this.systemForm.value.cap,
      capacity: this.systemForm.value.warehouseCapacity,
      product_id: this.productTypeSelected,
      cultivated_hectares: this.systemForm.value.cultivatedHectares,
      km_cultivations: this.systemForm.value.KmOfCrops,
      cultivated_value: this.systemForm.value.cultivatedValue,
      soil_type: this.systemForm.value.soilType,
      default: this.switchDefault,
    };
  }

  handleRole(data: any, roleType: 'Transformer' | 'Distributor' | 'Vendor') {
    const serviceMethods = {
      Transformer: () =>
        this.dataRoleId
          ? this._componentService.updateUserInfoTransformer(
            data,
            this.dataRoleId
          )
          : this._componentService.postUserInfoTransformer(data),
      Distributor: () =>
        this.dataRoleId
          ? this._componentService.updateUserInfoDistributor(
            data,
            this.dataRoleId
          )
          : this._componentService.postUserInfoDistributor(data),
      Vendor: () =>
        this.dataRoleId
          ? this._componentService.updateUserInfoVendor(data, this.dataRoleId)
          : this._componentService.postUserInfoVendor(data),
    };

    serviceMethods[roleType]().subscribe(
      () => this.success(),
      (error) => this.showErrorMessage(error)
    );
  }

  handleProducerRole(producerData: any) {
    const producerMethod = this.plantId
      ? this._componentService.updateProducerPlant(producerData, this.plantId)
      : this._componentService.postProducerPlant(producerData);

    producerMethod.subscribe(
      () => {
        this.success();
        const valore = 'plants';
        this.variabileOutput.emit(valore);
      },
      (error) => this.showErrorMessage(error)
    );
  }

  showErrorMessage(error: any) {
    return Swal.fire({
      title: 'Errore',
      text: error.message,
      icon: 'warning',
      showConfirmButton: false,
      timer: 2000,
    });
  }

  success() {
    Swal.fire({
      title: 'Profilo aggiornato',
      text: 'Aggiornamento effettuato con successo',
      icon: 'success',
      // showCloseButton: true,
      showConfirmButton: false,
      timer: 2000,
    });
  }

  error() {
    return Swal.fire({
      title: 'Attenzione',
      text: 'Completa tutti i campi',
      icon: 'warning',
      // showCloseButton: true,
      showConfirmButton: false,
      timer: 2000,
    });
  }

  resetForm() {
    if (this.roleId == 2) {
      if (this.plantId) {
        this.showModal();
      } else {
        this.systemForm.reset();
        const valore = 'plants';
        this.variabileOutput.emit(valore);
      }
    }
  }

  getTransformerInfo() {
    this._componentService.getUserInfoTransformer().subscribe((r) => {
      this.dataRole = r.data;
      this.dataRoleId = r.data.id;
      this.initForm();
    });
  }

  getDistributorInfo() {
    this._componentService.getUserInfoDistributor().subscribe((r) => {
      this.dataRole = r.data;
      this.dataRoleId = r.data.id;
      this.initForm();
    });
  }

  getVendorInfo() {
    this._componentService.getUserInfoVendor().subscribe((r) => {
      this.dataRole = r.data;
      this.dataRoleId = r.data.id;
      this.initForm();
    });
  }

  getSinglePlantInfo() {
    this._componentService.getSinglePlantInfo(this.plantId).subscribe((r) => {
      this.dataRole = r.data;
      this.dataRoleId = r.data.id;
      this.initFormProducer();
    });
  }

  initForm() {
    this._chartService.getMarketMunicipality().subscribe((r) => {
      this.municipality = r.data;

      console.log('dataRole', this.dataRole);

      if (this.dataRole) {
        const selectedProvince = this.municipality?.find(
          (item: any) => item.label === this.dataRole.province
        );
        this.provinceSelected = selectedProvince;
        console.log('province selected', this.provinceSelected);

        if (this.provinceSelected) {
          this.getMunicipalityByProvince(this.provinceSelected?.label);
        }
        this.systemForm.patchValue({
          systemName: this.dataRole.name,
          address: this.dataRole.address,
          cap: this.dataRole.cap,
          warehouseCapacity: this.dataRole.storage_capacity,
        });
      }
    });
  }

  initFormProducer() {
    this._chartService.getMarketMunicipality().subscribe((r) => {
      this.municipality = r.data;

      console.log('dataRole', this.dataRole);

      if (this.dataRole) {
        const selectedProvince = this.municipality?.find(
          (item: any) => item.label == this.dataRole.province
        );
        console.log(this.municipality);

        this.provinceSelected = selectedProvince;
        console.log('province selected', this.provinceSelected);

        if (this.provinceSelected) {
          this.getMunicipalityByProvince(this.provinceSelected?.label);
        }
        if (this.dataRole.product_id == 1) {
          this.productTypeSelected = 1;
        } else {
          this.productTypeSelected = 2;
        }
        this.switchDefault = this.dataRole.default;
        this.systemForm.patchValue({
          systemName: this.dataRole.name,
          address: this.dataRole.address,
          cap: this.dataRole.cap,
          warehouseCapacity: this.dataRole.storage_capacity,
          cultivatedHectares: this.dataRole.cultivated_hectares,
          KmOfCrops: this.dataRole.km_cultivations,
          cultivatedValue: this.dataRole.cultivated_value,
          soilType: this.dataRole.soil_type,
        });
      }
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this._componentService.deletePlant(this.plantId).subscribe((r) => {
      Swal.fire({
        title: 'Eliminata',
        text: 'Piantaggione eliminata con successo',
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000,
      });


    })
    this.variabileOutput.emit('plants');
    this.isVisible = false;

  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
