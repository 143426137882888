<div class="row main-box margin-x">

    <div class="col-1 bg-left col-rel"
        [ngClass]="{ 'bg-left-lemon': report.product.category == 2, 'bg-left-orange': report.product.category == 1 }">
        <div class="box-image">
            <img [src]="getIconPath(report.product.category)" alt="">
        </div>
    </div>
    <div class="col-11 bg-right"
        [ngClass]="{ 'bg-right-lemon': report.product.category == 2, 'bg-right-orange': report.product.category == 1 }">
        <div class="row mt-3">
            <div class="col-7 d-flex align-items-start">
                <div class="ms-3"><span class="title-card">{{report.activity}}</span></div>
            </div>
            <div class="col-5 d-flex align-items-start">
                <button nz-button [nzSize]="size" *ngIf="!report.note" nzType="primary" nzShape="round"
                    class="button-add" (click)="setModal2(report)">
                    + Aggiungi informazioni
                </button>
                <button nz-button [nzSize]="size" *ngIf="report.note" nzType="primary" nzShape="round"
                    class="button-add" (click)="setModal2(report)">
                    <span nz-icon nzType="edit" nzTheme="outline"></span> Modifica nota
                </button>

            </div>
        </div>

        <div class="row mt-3">
            <div class="col-7">
                <ul style="list-style-type: none;" class="ps-1 mt-2 ms-3" *ngFor="let act of activity_list">
                    <li class="my-1">{{act.type}}</li>
                </ul>
                <ul style="list-style-type: none;" class="ps-1 mt-2 ms-3" *ngIf="report.note">
                    <li class="my-1">Note</li>
                </ul>
            </div>
            <div class="col-5">
                <ul style="list-style-type: none;" class="ps-1 mt-2">
                    <li class="my-1"><span class="fw-500">{{(report.product.batch?.batch_unique_identifier ? report.product.batch.batch_unique_identifier : 'Nessun lotto')}}</span></li>
                </ul>
                <ul style="list-style-type: none;" class="ps-1 mt-2">
                    <li class="my-1"><span class="fw-500">{{report.cultivar}}</span></li>
                </ul>
                <ul style="list-style-type: none;" class="ps-1 mt-2">
                    <li class="my-1"><span class="fw-500">{{report.causal}}</span></li>
                </ul>
                <ul style="list-style-type: none;" class="ps-1 mt-2">
                    <li class="my-1"><span class="fw-500">{{report.product.name}}</span></li>
                </ul>
                <ul style="list-style-type: none;" class="ps-1 mt-2">
                    <li class="my-1"><span class="fw-500">{{report.plot}}</span></li>
                </ul>
                <ul style="list-style-type: none;" class="ps-1 mt-2">
                    <li class="my-1"><span class="fw-500">{{report.amount}} {{ getUnitOfMeasure(report.activity) }}</span></li>
                </ul>
                <ul style="list-style-type: none;" class="ps-1 mt-2">
                    <li class="my-1"><span class="fw-500">{{report.surface}} (ha)</span></li>
                </ul>
                <ul style="list-style-type: none;" class="ps-1 mt-2" *ngIf="report.note">
                    <li class="my-1 d-flex flex-row"><span class="fw-500">{{report.note}}</span>
                        <div class="d-flex align-items-center ms-2"> 
                            <button type="button"
                                class="btn btn-danger ms-1 px-1 py-0" (click)="removeNote(report.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash3" viewBox="0 0 16 16">
                                    <path
                                        d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>




<!-- * Modale aggiunta-->

<div class="modal fade custom-modal" id="exampleModal" #exampleModal tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <form [formGroup]="formReport">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title fs-5" id="exampleModalLabel">Aggiungi informazioni</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        (click)="closeModal2()"></button>
                </div>

                <div class="modal-body">
                    <div class="d-flex flex-rox">
                        <div class="me-3 d-flex align-items-center"><img
                                [src]="getIconPath(getContent()?.product.category)" alt="cytrus">
                        </div>
                        <div class="d-flex flex-column">
                            <div class="mod-subtitle">Lotto</div>
                            <div class="mod-text">{{getContent()?.product.batch?.batch_unique_identifier ? report.product.batch.batch_unique_identifier : 'Nessun lotto'}}</div>
                        </div>
                    </div>

                    <div class="mod-subtitle mt-4">Attività</div>
                    <div class="mod-text">{{getContent()?.activity}}</div>


                    <div class="mod-subtitle mt-4">Aggiungi note</div>
                    <div class="mt-2">
                        <textarea class="form-control bd-rad" formControlName="note" id="exampleFormControlTextarea1"
                            rows="7"></textarea>
                    </div>
                </div>

                <div class="modal-end">
                    <div class="d-flex flex-row justify-content-between">
                        <button nz-button [nzSize]="size" nzType="primary" nzShape="round"
                            class="button-cancel w-50 me-2 text-center" data-bs-dismiss="modal" (click)="closeModal2()">
                            Annulla
                        </button>
                        <button nz-button [nzSize]="size" nzType="primary" nzShape="round"
                            class="button-add w-50 ms-2 text-center" data-bs-dismiss="modal"
                            (click)="insertNote(getContent()?.id)">
                            Salva
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>