<div class="header-con ">
  <div class="d-flex flex-column profile-infos">
    <div class="profile">Profilo</div>
    <div class="company-name">{{userInfo?.company_name}}</div>
    <div class="user-role-name">{{userInfo?.role_name}}</div>
  </div>
</div>

<div class="tabs-container">
  <nz-tabset>
    <nz-tab nzTitle="Informazioni Utente">
      <div class="user-info-tab">
        <div class="user-info-title">Informazioni Utente</div>
        <div class="form-user-info">
          <form [formGroup]="userInfoForm">
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="exampleInputEmail1" class="label-form">Nome</label>
                <input type="text" class="form-control" aria-describedby="name" placeholder="Nome"
                  formControlName="name">
              </div>
              <div class="form-group col-sm-6">
                <label for="exampleInputPassword1" class="label-form">Cognome</label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="cognome"
                  formControlName="surname">
              </div>
              <div class="form-group col-sm-6">
                <label for="exampleInputEmail1" class="label-form">Email</label>
                <input type="email" class="form-control" aria-describedby="emailHelp" placeholder="email"
                  formControlName="email">
              </div>
              <div class="form-group col-sm-6">
                <label for="exampleInputPassword1" class="label-form">Password</label>
                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"
                  formControlName="password">
              </div>

            </div>
          </form>
          <div class="buttons-container-form">
            <button nz-button nzType="default" class="back" (mouseup)="resetForm()">Indietro</button>
            <button nz-button nzType="primary" class="modal-button save" (mouseup)="saveUserInfo()">Salva</button>
          </div>
        </div>
      </div>
    </nz-tab>
    <nz-tab [nzTitle]="roleTab">
      <div *ngIf="userInfo?.role_id == 4 || userInfo?.role_id == 5 || userInfo?.role_id == 6">
        <app-mySystemTransformer [roleId]="userInfo?.role_id"></app-mySystemTransformer>
      </div>
      <div *ngIf="userInfo?.role_id == 2">
        <app-myPlantations></app-myPlantations>
      </div>
    </nz-tab>
  </nz-tabset>
</div>