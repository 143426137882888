import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { config } from "../environments/config";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentComponent } from "./components/content/content.component";
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../app/auth/auth.module').then(m => m.AuthModule) },
  { path: 'app', loadChildren: () => import('../app/components/components.module').then(m => m.ComponentsModule), canActivate: [AuthGuard] },
  { path: 'producer', loadChildren: () => import('../app/modules/producer/producer.module').then(m => m.ProducerModule), canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: () => import('../app/modules/admin/admin.module').then(m => m.AdminModule)},
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  { path: '*', redirectTo: '' }

  // {
  //   path: 'dashboard',
  //   loadComponent: () =>
  //     import('./components/dashboard/dashboard.component').then((m) => m.DashboardComponent),
  // },
  // {
  //   path: 'login',
  //   loadChildren: () =>
  //     import('./components/login/login.component').then((m) => m.LoginComponent),
  // },

];

/*if(config.sidebar) {
  console.log('primo routing');
  routes = [
    { path: '', component: SidebarComponent, children: [
        { path: '', component: ContentComponent, children: []},
      ]},
    { path: '', component: FooterComponent, children: []},
  ];
} else {
  console.log('secondo routing');
  routes = [
    { path: '', component: HeaderComponent, children: [
        { path: '', component: ContentComponent, children: []},
        { path: '', component: FooterComponent, children: []},
      ]},
  ];
}*/

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
