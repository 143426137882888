import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver , Breakpoints , BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  layoutType?: string;
  containerLg?: boolean;
  containersm?: boolean;
  numCard?: number;
  numCardScroll?: number;
  @Input() scroll?: boolean;
  @Input() products?: any;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) { }

  ngOnInit() {
    
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
    ]).subscribe(result => {
      this.layoutType = this.determineLayoutType(result);
    });
  }

  // gestisce i template in base alle dimensioni dello schermo
  determineLayoutType(result: BreakpointState): string {
    switch (true) {
      case result.breakpoints[Breakpoints.XSmall]:
        this.containerLg = false
        this.containersm = true
        this.numCard = 1
        this.numCardScroll = 1
        return 'mobile';
      case result.breakpoints[Breakpoints.Small]:
        this.containerLg = false
        this.containersm = true
        this.numCard = 1
        this.numCardScroll = 1
        return 'mobile';
        case result.breakpoints[Breakpoints.Medium]:
          this.containersm = false
        this.containerLg = true   
          this.numCard = 3
          this.numCardScroll = 10
          return 'tablet';
      case result.breakpoints[Breakpoints.Large]:
        this.containersm = false
        this.containerLg = true   
        this.numCard = 4
        this.numCardScroll = 10
        return 'desktop';
      default:
        this.containersm = false
        this.containerLg = true   
        this.numCard = 4     
        return 'desktop';
    }
  }

  scrollLeft() {
    const container = document.querySelector('.scroll-container');
    if (container) {
      container.scrollLeft -= 200;
    }
  }

  scrollRight() {
    const container = document.querySelector('.scroll-container');
    if (container) {
      container.scrollLeft += 200;
    }
  }

  seeAll() {
    this.router.navigate(['app/see-all']);
  }

}
