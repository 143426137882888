<div class="container-form-lg row">

  <div class="col-6">
    <div class="container-register-done">
      <div class="d-flex mt-4 icon-container">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.9653 2.52995C22.9653 2.33359 22.9266 2.13915 22.8515 1.95774C22.7763 1.77632 22.6662 1.61148 22.5274 1.47263C22.3885 1.33379 22.2237 1.22365 22.0423 1.1485C21.8608 1.07336 21.6664 1.03468 21.47 1.03468C21.3538 1.04718 21.2388 1.06962 21.1263 1.10175C19.5604 1.48948 17.9334 1.43893 16.3454 1.15552C12.4988 0.469057 7.93577 2.10836 5.02207 5.02207C2.10835 7.93578 0.469051 12.4988 1.15552 16.3453C1.43894 17.9334 1.48949 19.5604 1.10175 21.1263C1.06962 21.2387 1.04718 21.3538 1.03468 21.47C1.03468 21.6664 1.07336 21.8608 1.1485 22.0423C1.22364 22.2237 1.33378 22.3885 1.47263 22.5274C1.61148 22.6662 1.77632 22.7764 1.95773 22.8515C2.13915 22.9266 2.33359 22.9653 2.52995 22.9653C2.64627 22.9528 2.76131 22.9304 2.8738 22.8982C4.43966 22.5105 6.06663 22.5611 7.65469 22.8445C11.5012 23.531 16.0642 21.8916 18.9779 18.9779C21.8917 16.0642 23.5309 11.5012 22.8445 7.65463C22.561 6.0665 22.5105 4.43947 22.8983 2.87354C22.9304 2.76113 22.9528 2.64619 22.9653 2.52995Z"
            fill="#FDBC34" />
          <path
            d="M11.1737 9.03985C13.2849 7.82495 14.471 5.92687 13.8227 4.80036C13.1745 3.67385 10.9375 3.7455 8.82621 4.9604C6.71494 6.17529 5.52891 8.07337 6.17714 9.19988C6.82538 10.3264 9.06239 10.2547 11.1737 9.03985Z"
            fill="#FDCF6D" />
          <path
            d="M10.8418 5.7279C11.6482 5.34936 12.1489 4.71651 11.9602 4.31437C11.7714 3.91224 10.9646 3.89311 10.1582 4.27164C9.35184 4.65017 8.85114 5.28303 9.03991 5.68516C9.22867 6.0873 10.0354 6.10643 10.8418 5.7279Z"
            fill="#FEE3AA" />
          <path
            d="M10.5 20C11.3284 20 12 19.3284 12 18.5C12 17.6716 11.3284 17 10.5 17C9.67157 17 9 17.6716 9 18.5C9 19.3284 9.67157 20 10.5 20Z"
            fill="#F59707" />
          <path
            d="M18.5 15C19.3284 15 20 14.3284 20 13.5C20 12.6716 19.3284 12 18.5 12C17.6716 12 17 12.6716 17 13.5C17 14.3284 17.6716 15 18.5 15Z"
            fill="#F59707" />
          <path
            d="M3.5 15C3.77614 15 4 14.5523 4 14C4 13.4477 3.77614 13 3.5 13C3.22386 13 3 13.4477 3 14C3 14.5523 3.22386 15 3.5 15Z"
            fill="#FEE3AA" />
          <path
            d="M7.02583 9.97377C7.56387 9.43572 7.70541 8.70492 7.34197 8.34148C6.97853 7.97804 6.24773 8.11958 5.70969 8.65762C5.17164 9.19567 5.0301 9.92646 5.39354 10.2899C5.75699 10.6533 6.48779 10.5118 7.02583 9.97377Z"
            fill="#FEE3AA" />
          <path
            d="M15.5 4C15.7761 4 16 3.77614 16 3.5C16 3.22386 15.7761 3 15.5 3C15.2239 3 15 3.22386 15 3.5C15 3.77614 15.2239 4 15.5 4Z"
            fill="#FEE3AA" />
          <path
            d="M20.5 7C21.3284 7 22 6.32843 22 5.5C22 4.67157 21.3284 4 20.5 4C19.6716 4 19 4.67157 19 5.5C19 6.32843 19.6716 7 20.5 7Z"
            fill="#F59707" />
          <path
            d="M15 17C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15C14.4477 15 14 15.4477 14 16C14 16.5523 14.4477 17 15 17Z"
            fill="#F59707" />
          <path
            d="M14.5 14C14.7761 14 15 13.7761 15 13.5C15 13.2239 14.7761 13 14.5 13C14.2239 13 14 13.2239 14 13.5C14 13.7761 14.2239 14 14.5 14Z"
            fill="#F59707" />
          <path
            d="M4 21C4.55228 21 5 20.5523 5 20C5 19.4477 4.55228 19 4 19C3.44772 19 3 19.4477 3 20C3 20.5523 3.44772 21 4 21Z"
            fill="#F59707" />
          <path
            d="M15.5 7C15.7761 7 16 6.77614 16 6.5C16 6.22386 15.7761 6 15.5 6C15.2239 6 15 6.22386 15 6.5C15 6.77614 15.2239 7 15.5 7Z"
            fill="#F59707" />
          <path
            d="M11.2464 13.2466C11.965 12.5284 12.2133 11.6118 11.801 11.1994C11.3888 10.787 10.4722 11.035 9.75364 11.7532C9.03512 12.4715 8.78681 13.388 9.19904 13.8004C9.61126 14.2128 10.5279 13.9648 11.2464 13.2466Z"
            fill="#F59707" />
          <path
            d="M22.8444 7.65424C22.561 6.0661 22.5105 4.43909 22.8982 2.87317C22.9303 2.76075 22.9528 2.64579 22.9653 2.52954C22.9647 2.22072 22.8682 1.91971 22.6891 1.66811C22.51 1.41651 22.2572 1.22673 21.9656 1.125C21.8838 1.40015 21.579 1.67389 21.4189 1.77258L21.0576 1.98596L20.957 2.39319C20.5341 4.10364 20.5068 5.93909 20.8759 8.00549C21.4335 11.1256 20.0712 15.0558 17.5634 17.5636C15.4443 19.6832 12.3388 20.9996 9.4599 20.9996C8.97249 21.0008 8.48592 20.9591 8.0058 20.8751C5.96478 20.5104 4.08099 20.5392 2.39252 20.9567L1.84271 21.0934L1.67767 21.6349C1.59403 21.8953 1.48743 22.1479 1.35913 22.3895C1.49768 22.5681 1.67514 22.7128 1.87802 22.8125C2.0809 22.9122 2.30385 22.9644 2.52991 22.965C2.64624 22.9524 2.76128 22.93 2.87378 22.8978C4.43964 22.5101 6.06659 22.5607 7.65466 22.8441C11.5012 23.5306 16.0641 21.8912 18.9779 18.9775C21.8916 16.0638 23.5309 11.5008 22.8444 7.65424Z"
            fill="#F59707" />
        </svg>
        <div class="ms-2">
          <span class="title-color">Ci<b>TRace</b></span>
        </div>
      </div>

      <div class="text-container">
        <h3>Registrazione effettuata!</h3>
        <p>Grazie per esserti registrato su CiTrace. Abbiamo mandato una richiesta di approvazione all’amministratore.
          Controlla la tua casella di posta elettronica per aggiornamenti sullo stato del tuo account.</p>
      </div>

      <div class="btn-cont">
        <button nz-button nzType="primary" (click)="toLogin()">
          <span>Accedi</span>
        </button>
      </div>

    </div>
  </div>

  <div class="col-6">
    <div class=" img-container">
      <img src="../../../assets/img/register/immagine-register-3.png" alt="">
    </div>
  </div>

</div>