import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  alert!: string;
  showAlert: boolean = false;

  constructor(private _chartService: ChartService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils: Utils
  ) { }

  ngOnInit(): void {
    this.getAlert();

  }

  getAlert() {
    this._chartService.getAlertFms().subscribe(r => {
      this.alert = r.data
      this.showAlert = true


    },
    error => {
      this.alert = 'Nessun dato'
      this.showAlert = true

    }
  )
  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 3);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-alert'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);

    })
  }

}
