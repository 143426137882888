import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validator, Validators} from '@angular/forms';
import { ComponentService } from 'src/app/components/component.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-addReport',
  templateUrl: './addReport.component.html',
  styleUrls: ['./addReport.component.scss']
})
export class AddReportComponent implements OnInit {

  formReport!: FormGroup;

  isVisible = false;
  isOkLoading = false;
  selectedValue = null;
  batchToSelect: any;
  type: any;

  productsType = [
    {
      id: 1,
      path: '../../../../../../assets/icon/modal-orange-icon.svg',
      selected: false
    },
    {
      id: 2,
      path: '../../../../../../assets/icon/modal-lemon-icon.svg',
      selected: false
    }
  ]

  constructor(
    private fb: FormBuilder,
    private _componentService: ComponentService
  ) { }

  ngOnInit() {
    this.formReport = this.fb.group({
      // type: [null],
      lotto: [null, Validators.required],
      sold: [null, [Validators.required, Validators.min(1)]],
      rejected: [null, [Validators.required, Validators.min(0)]]
    });
  }

  showModal(): void {
    // this.getMyBatchOnGiacencyPoint();

    this.isVisible = true;
  }

  handleOk(): void {
    if (this.formReport.valid && this.type) {
      let data =
      {
        type: this.type,
        lotto: this.formReport.value.lotto,
        sold_kg: this.formReport.value.sold,
        rejected_kg: this.formReport.value.rejected,
      }

      this._componentService.storeDailyReport(data).subscribe(
        r => {
          Swal.fire({
            title: "Report aggiunto",
            text: "Il report del giorno è stato aggiunto con successo",
            showConfirmButton: false,
            icon: "success",
            timer: 2000
          });
          this._componentService.triggerClickEvent();
          this.formReport.reset()
          this.isVisible = false;
        },
        error => {
          console.log(error);

          Swal.fire({
            title: "Errore",
            text: `${error.error || error}`,
            icon: "error",
            timer: 2000
            // confirmButtonText: "OK"
          });
        }
      );
    }
    else{
      Swal.fire({
        title: "Campi mancanti",
        text: "Compila tutti i campi",
        icon: "error",
        showConfirmButton: false,
        timer: 2000
      });
    }

  }

  handleCancel(): void {
    this.formReport.reset()
    this.isVisible = false;
  }

  getMyBatchOnGiacencyPoint() {
    this.selectedValue = null
    this._componentService.getMyBatchOnGiacencyPoint(this.type).subscribe(r => {
      this.batchToSelect = r.data;
    })
  }

  selectProductType(id: number): void {
    this.productsType.forEach(p => p.selected = false);
    const product = this.productsType.find(p => p.id === id);
    if (product) {
      product.selected = true;
      this.type = product.id
      this.getMyBatchOnGiacencyPoint();
    }

    console.log(this.productsType);

  }

  // sendMessage() {
  //   this._componentService.refreshComponente('Hello from Componente1');
  // }

}
