import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BreakpointObserver , Breakpoints , BreakpointState } from '@angular/cdk/layout';
import { ComponentService} from '../component.service';

const count = 5;
const fakeDataUrl = 'https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo';

@Component({
  selector: 'app-see-all',
  templateUrl: './see-all.component.html',
  styleUrls: ['./see-all.component.scss']
})
export class SeeAllComponent implements OnInit {

  initLoading = false; // bug
  loadingMore = false;
  data: any[] = [];
  list: Array<{ loading: boolean; name: any }> = [];
  products: any
  skip = 0;
  take = 10;

  // @Input() products?: any;

  // products = [
  //   { path: '../../../assets/img/market/10.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 1, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 2, loading: false},
  //   { path: '../../../assets/img/market/11.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et  voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 3, loading: false},
  //   { path: '../../../assets/img/market/5.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 4, loading: false},
  //   { path: '../../../assets/img/market/9.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 5, loading: false},
  //   { path: '../../../assets/img/market/8.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis ', title: 'arancia rossa', id: 6, loading: false},
  //   { path: '../../../assets/img/market/7.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 7, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 8, loading: false},
  //   { path: '../../../assets/img/market/8.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 9, loading: false},
  //   { path: '../../../assets/img/market/7.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud', title: 'arancia rossa', id: 10, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 11, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 12, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 13, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis ', title: 'arancia rossa', id: 14, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 15, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 16, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ', title: 'arancia rossa', id: 17, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 18, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ', title: 'arancia rossa', id: 19, loading: false},
  //   { path: '../../../assets/img/market/6.jpg', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', title: 'arancia rossa', id: 20, loading: false},
  // ];


  layoutType?: string;
  containerLg?: boolean;
  containersm?: boolean;
  desc?: number;
  skipInit = 0;
  takeInit = 10
  productNumber?: Number;

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    private breakpointObserver: BreakpointObserver,
    private _componentService: ComponentService
  ) { }

  ngOnInit() {

    this.getAllProducts(this.skipInit, this.takeInit);

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
    ]).subscribe(result => {
      this.layoutType = this.determineLayoutType(result);
    });

  }


  onLoadMore(): void {
    this.takeInit = this.takeInit + 5
    this.getAllProducts(this.skipInit, this.takeInit);
  }

  fewerItems() {
    this.takeInit = this.takeInit - 5
    this.getAllProducts(this.skipInit, this.takeInit);
  }

  // gestisce i template in base alle dimensioni dello schermo
  determineLayoutType(result: BreakpointState): string {
    switch (true) {
      case result.breakpoints[Breakpoints.XSmall]:
        this.containerLg = false
        this.containersm = true
        this.desc = 50
        return 'mobile';
      case result.breakpoints[Breakpoints.Small]:
        this.containerLg = false
        this.containersm = true
        this.desc = 100
        return 'mobile';
        case result.breakpoints[Breakpoints.Medium]:
          this.containersm = false
        this.containerLg = true
        this.desc = 200   
          return 'tablet';
      case result.breakpoints[Breakpoints.Large]:
        this.containersm = false
        this.containerLg = true
        this.desc = 200   
        return 'desktop';
      default:
        this.containersm = false
        this.containerLg = true   
        return 'desktop';
    }
  }

  getAllProducts(skip: number, take: number) {
    this._componentService.getAllProducts(skip, take).subscribe(r => {
      this.products = r
      this.productNumber = this.products.length
      
    })
  }

}
