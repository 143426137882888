import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { ChartService } from 'src/app/components/charts/chart.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ReportFMSService } from 'src/app/shared/services/report_fms.service';

@Component({
  selector: 'app-report-fms',
  templateUrl: './report-fms.component.html',
  styleUrls: ['./report-fms.component.scss']
})
export class ReportFmsComponent {
  placeholder: string = 'Seleziona un campo';
  selectedField!: string;
  fields: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  is_dashboard: boolean = false;
  formReport!: FormGroup;

  filter: any;
  filterChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  pageIndex: number = 1;
  pageSize: number = 2;
  total: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  reports: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentDate: any;

  defaultDate: any;



  constructor(
    private reportService: ReportFMSService,
    private auth: AuthService,
    private chartService: ChartService,
    private fb: FormBuilder
  ) {
    moment.locale('it');
    const formattedDate = moment().format('DD MMMM YYYY');
    const capitalizedMonth = formattedDate.split(' ')[1].charAt(0).toUpperCase() + formattedDate.split(' ')[1].slice(1);
    this.currentDate = formattedDate.replace(formattedDate.split(' ')[1], capitalizedMonth);

    const today = new Date();
    this.defaultDate = today.toISOString().split('T')[0];

    this.formReport = this.fb.group({
      search: [null],
      note: [null]
    });
  }

  ngOnInit(): void {
    this.getReports();
    this.getMunicipality();
  }

  // getReports() {
  //   let params = this.filter ? { user_id: this.auth.currentUser.value.id, skip: this.pageIndex * this.pageSize, take: this.pageSize, search: this.filter } : { user_id: this.auth.currentUser.value.id, skip: this.pageIndex * this.pageSize, take: this.pageSize };
  //   this.reportService.getTableReportUser(params).subscribe(res => {
  //     this.total.next(res.total)
  //     console.log('Tabella report: ', res);

  //   })
  // }

  getReports() {
    let params = this.filter ? { user_id: this.auth.currentUser.value.id, pageIndex: this.pageIndex, pageSize: this.pageSize, date: this.defaultDate, search: this.filter, municipality_id: this.selectedField } : { user_id: this.auth.currentUser.value.id, pageIndex: this.pageIndex, pageSize: this.pageSize, date: this.defaultDate, municipality_id: this.selectedField };
    this.reportService.getTableReportUser(params).subscribe(res => {
      this.reports.next(res.data.data);
      
      this.total.next(res.total)
    });
  }

  handleReloadReports() {
    this.getReports();
  }

  onSearch() {
    this.getReports();
  }

  getMunicipality() {
    this.chartService.getMunicipality().subscribe(res => {
      if (res.data.length > 0) {
        this.fields.next(res.data);
        this.selectedField = this.fields.value[0].id
        this.getReports()
      }
    })
  };

  handlePageEventNotification(page: number): void {
    this.pageIndex = page;
    this.getReports();
  }

  onDefaultDateChanged(defaultDate: string): void {
    this.defaultDate = defaultDate;
    // console.log('data odierna', defaultDate);

    const formattedDate = moment(defaultDate).format('DD MMMM YYYY');
    const capitalizedMonth = formattedDate.split(' ')[1].charAt(0).toUpperCase() + formattedDate.split(' ')[1].slice(1);
    this.currentDate = formattedDate.replace(formattedDate.split(' ')[1], capitalizedMonth);
    
    this.getReports();
  }


  getFields() {

  }
}
