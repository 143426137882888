<div *ngIf="dayWeather" class="weather-card" [class.selected]="isSelected">
  <div class="weather-card-header">
    <p> {{ dayWeather.created_at | date:'dd' }}</p>
    <img [src]="getIconPath(dayWeather.weather_main)" alt="{{ dayWeather.weather_main }}"
      class="weather-icon" />
    <p>
      <strong>
        {{ dayWeather.temp_main }}C
      </strong>
    </p>
    <p> {{ dayWeather.weather_description }}</p>
  </div>
</div>
