<section class="container-fluid">
    <h1>Impostazioni</h1>

    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
        <!-- <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>Username</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
                <input nz-input formControlName="userName" placeholder="inserisci il tuo username" />
                <ng-template #userErrorTpl let-control>
                    <div *ngIf="control.errors?.required">Please input your username!</div>
                    <div *ngIf="control.errors?.duplicated">The username is redundant!</div>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>E-mail</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="emailErrorTpl">
                <input nz-input formControlName="email" placeholder="email" type="email" />
                <ng-template #emailErrorTpl let-control>
                    <div *ngIf="control.errors?.email">The input is not valid E-mail!</div>
                    <div *ngIf="control.errors?.required">Please input your E-mail!</div>
                </ng-template>
            </nz-form-control>
        </nz-form-item>-->
        <nz-form-item> 
            <nz-form-label [nzSpan]="7" nzRequired>Password corrente</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="Password attuale">
                <input nz-input type="password" formControlName="current_password" placeholder="Password attuale"/>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item> 
            <nz-form-label [nzSpan]="7" nzRequired>Nuova password</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="Inserisci la password">
                <input nz-input type="password" formControlName="password"
                    (ngModelChange)="validateConfirmPassword()" placeholder="Nuova password"/>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>Conferma password</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                <input nz-input type="password" formControlName="password_confirmation" placeholder="Conferma password" />
                <ng-template #passwordErrorTpl let-control>
                    <div *ngIf="control.errors?.required">Conferma la tua password!</div>
                    <div *ngIf="control.errors?.password_confirmation">Password non valida!</div>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-control [nzOffset]="7" [nzSpan]="12">
                <button nz-button nzType="primary" [disabled]="!validateForm.valid">Submit</button>
                <button nz-button (click)="resetForm($event)">Reset</button>
            </nz-form-control>
        </nz-form-item>
    </form>
</section>