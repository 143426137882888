<div class="row water-container">
  <div class="water-content">
    <div class="col-6 d-flex justify-content-between title-cont">
      <!-- <div class="title">Acqua</div> -->
      <h4 class="header-widget">Acqua</h4>
      <span style="cursor: pointer;" (click)="explodeWidget()">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>

    <div class="col-12 text-mini-widget">
      <p>Quantitá media per Kg di agrumi</p>
    </div>
    <div class="col-12 data-cont">
      <span class="data">{{waterLiters ? waterLiters : 0}}</span>
      <span class="kWh">lt</span>
    </div>
  </div>
</div>