<div class="main-container px-0" *ngIf="sowWidget; else noData">
  <div class="d-flex justify-content-between header-container">
    <div>
      <h4 class="fw-500 mb-0">Ultime trasformazioni</h4>
    </div>
    <span style="cursor: pointer;" (click)="explodeWidget()">
      <img src="/assets/img/arrow.svg" alt="">
    </span>
  </div>

  <div class="row mb-3 d-flex align-items-center" *ngFor="let item of list" style="flex-grow: 1;">
    <div class="col-12 d-flex d-flex justify-content-evenly list-container">
      <div class="img d-flex justify-content-center">
        <div><img src="{{item.img}}" alt=""></div>
      </div>
      <div class="d-flex flex-column">
        <div class="data">
          <div class="data-cont">{{item.data}}</div>
          <div class="producer">{{item.producer}}</div>
        </div>
        <div class="cont-lotto">
          <div class="lotto-background ">
            <div class="bg-lotto d-flex flex-column">
              <div class="lotto-label">Lotto</div>
              <div class="ms-2 lotto">
                <span>{{item.lotto}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="type">
        <span
          [ngClass]="{'color-spremiturs': item.type === 'Spremitura', 'color-selezione': item.type === 'Selezione', 'color-confettura': item.type === 'Confettura'}"
          class="px-2 py-1">{{item.type}}</span>
      </div>
      <div class="quantity">{{item.quantity}} kg</div>
    </div>
  </div>
</div>

<ng-template #noData>
  <h1>Nessun dato</h1>
</ng-template>