<div [ngClass]="{'system-tab': roleId == 4 || roleId == 5 || roleId == 6 || type == 'plant'}">
  <div class="system-title">Il mio impianto </div>
  <div class="system-form">
    <form [formGroup]="systemForm">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">{{typeNameForm}}</label>
          <input type="text" class="form-control" aria-describedby="name" placeholder="Nome impianto"
            formControlName="systemName">
        </div>
        <div class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">Indirizzo</label>
          <input type="text" class="form-control" aria-describedby="name" placeholder="Indirizzo"
            formControlName="address">
        </div>
      </div>
    </form>

    <!-- SELECT -->
    <div class="row">
      <div class="form-group col-sm-6">
        <label for="exampleInputEmail1" class="label-form">Provincia</label>
        <!-- {{provinceSelected}} -->
        <nz-select class=" wide-select" nzPlaceHolder="Provincia" nzBorderless [(ngModel)]="provinceSelected"
          (ngModelChange)="getMunicipalityByProvince(provinceSelected.label)">
          <nz-option *ngFor="let item of municipality" [nzValue]="item" [nzLabel]="item.label"></nz-option>
        </nz-select>
      </div>
      <div class="form-group col-sm-6">
        <label for="exampleInputEmail1" class="label-form">Comune</label>
        <nz-select class=" wide-select" nzPlaceHolder="Comune" nzBorderless [(ngModel)]="municipalitySelected">
          <nz-option *ngFor="let prov of province" [nzValue]="prov" [nzLabel]="prov.label"></nz-option>
        </nz-select>
      </div>



    </div>
    <!-- SELECT -->

    <form [formGroup]="systemForm">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">CAP</label>
          <input type="text" class="form-control" aria-describedby="name" placeholder="CAP" formControlName="cap">
        </div>
        <div class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">{{roleId != 2 ? 'Capienza magazzino' : 'Quantità
            coltivata'}}</label>
          <input type="text" class="form-control" aria-describedby="name" placeholder="Capienza magazzino"
            formControlName="warehouseCapacity">
        </div>
      </div>
    </form>

    <!-- FORM ESTESO PRODUTTORE -->
    <div *ngIf="roleId == 2">


      <form [formGroup]="systemForm">
        <div class="row">
          <div class="form-group col-sm-6">
            <label for="exampleInputEmail1" class="label-form">Ettari coltivati</label>
            <input type="text" class="form-control" aria-describedby="name" placeholder="Ettari coltivati"
              formControlName="cultivatedHectares">
          </div>
          <div class="form-group col-sm-6">
            <label for="exampleInputEmail1" class="label-form">Km coltivazione</label>
            <input type="text" class="form-control" aria-describedby="name" placeholder="Km coltivazione"
              formControlName="KmOfCrops">
          </div>

          <div class="form-group col-sm-6">
            <label for="exampleInputEmail1" class="label-form">Valore coltivato</label>
            <input type="text" class="form-control" aria-describedby="name" placeholder="Quantità coltivata"
              formControlName="cultivatedValue">
          </div>
          <div class="form-group col-sm-6">
            <label for="exampleInputEmail1" class="label-form">Tipo di suolo</label>
            <input type="text" class="form-control" aria-describedby="name" placeholder="Tipo di suolo"
              formControlName="soilType">
          </div>
        </div>
      </form>
      <div class="row">
        <div *ngIf="roleId == 2" class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">Tipo di coltivazione</label>
          <nz-select class=" wide-select" nzPlaceHolder="coltivazione" nzBorderless [(ngModel)]="productTypeSelected">
            <nz-option *ngFor="let prod of productType" [nzValue]="prod.id" [nzLabel]="prod.label"></nz-option>
          </nz-select>
        </div>
      </div>

      <div class="default-button d-flex align-items-center">
        <nz-switch [(ngModel)]="switchDefault"></nz-switch>
        <div class="switch-text">{{switchDefault ? 'Rimuovi come piantagione di default' : 'Imposta come piantagione di
          default'}}</div>
      </div>
    </div>
    <div *ngIf="roleId != 2" class="buttons-container-form">
      <button nz-button nzType="default" class="back" (mouseup)="resetForm()">Annulla</button>
      <button nz-button nzType="primary" class="modal-button save" (mouseup)="saveSystem()">Salva</button>
    </div>
    <div *ngIf="roleId == 2" class="buttons-container-form">
      <button nz-button nzType="default" class="back" (mouseup)="resetForm()">{{plantId ? 'Elimina' :
        'Annulla'}}</button>
      <button nz-button nzType="primary" class="modal-button save" (mouseup)="saveSystem()">Salva</button>
    </div>
  </div>
</div>



<!-- MODALE -->

<div class="modal-delete-container">
  <nz-modal [(nzVisible)]="isVisible" nzTitle="" [nzClosable]="false" [nzFooter]="null" (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
      <div class="delete-modal d-flex flex-column align-items-center">
        <h4>Elimina piantagione</h4>
        <p>Sei sicuro di voler eliminare questa piantagione?</p>
        <div class="delete-modal-btn d-flex align-items-center justify-content-between">
          <div class="modal-annulla" (mouseup)="handleCancel()">Annulla</div>
          <div class="modal-elimina" (mouseup)="handleOk()">Elimina</div>
        </div>
      </div>
    </ng-container>
  </nz-modal>
</div>