import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  array = [1, 2, 3, 4];

  images = [
    { path: '../../../assets/img/market/1.jpg' },
    { path: '../../../assets/img/market/2.jpg' },
    { path: '../../../assets/img/market/3.jpg' },
    { path: '../../../assets/img/market/4.jpg' },
  ];

  constructor() { }

  ngOnInit() {
  }

}
