import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ComponentService } from '../component.service';

@Component({
  selector: 'app-myPlantations',
  templateUrl: './myPlantations.component.html',
  styleUrls: ['./myPlantations.component.scss']
})
export class MyPlantationsComponent implements OnInit {

  @ViewChild('editForm') editForm!: ElementRef;

  currentTemplate: string = 'plants';
  showFormPlants: boolean = false;
  currentOlant: any;
  plantId: any;
  giBack: boolean = false;

  plants = [
    { id: 1, default: true, name: 'Nicosia, CT', selected: false },
    { id: 2, default: false, name: 'San Giovanni La Punta CT', selected: false },
    { id: 3, default: false, name: 'Giarre, CT', selected: false },
  ];

  constructor(
    private _componentService: ComponentService,
    private cdr: ChangeDetectorRef  // Inietta ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getAllProducerPlants();
  }

  goTOAddPlant() {
    this.currentTemplate = 'add-plant-form';
  }

  getAllProducerPlants() {
    this._componentService.getAllProducerPlants().subscribe(r => {
      this.plants = r.data;
      this.showFormPlants = false;
    });
  }

  riceviDalFiglio(messaggio: string) {
    if (messaggio == 'reload') {
      this.getAllProducerPlants();
    } else {
      this.currentTemplate = messaggio;
      this.getAllProducerPlants();
      this.showFormPlants = false
    }
  }

  showFormMyPlants(plant: any) {
    this.plants.forEach(p => p.selected = false);
    this.plantId = plant.id;
    if (plant) {
      this.showFormPlants = false;
      plant.selected = true;
      this.currentOlant = plant.id;
      // Aggiorna la vista e esegui lo scroll una volta che le modifiche sono state applicate
      this.cdr.detectChanges(); // Forza l'aggiornamento della vista
      this.showFormPlants = true;
      this.scrollToForm();
      this.giBack = true;
    }
  }


  goBack() {
    this.currentTemplate = 'plants';
    this.giBack = false;
    this.showFormPlants = false;
    this.plants.forEach(p => p.selected = false);
  }

  scrollToForm() {
    const element = document.getElementById('editForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn('Elemento editForm non trovato');
    }
  }

}
