<div *ngIf="showAlert" class="d-flex justify-content-between">
     <div class="d-flex flex-row text-white align-items-center">
          <div><span class="icon-fms"><img src="/assets/icon/alert-fms.svg" alt=""></span></div>
          <div class="d-flex flex-column">
               <div><span class="fw-500">Alert FMS!</span></div>
               <!-- <div><span class="fw-100" nz-tooltip [nzTooltipTitle]="alert">{{ alert.substring(0, 20)}}...</span></div> -->
               <div><p class="fw-100 alert-message">{{alert}}</p></div>
          </div>
     </div>
     <div><span class="expand" style="cursor: pointer;" (click)="explodeWidget()"><img src="/assets/icon/Group-w.svg"
                    alt=""></span></div>
</div>
