import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IFilterWeather } from '../interfaces/IFilterWeather';
import { ComponentService } from '../../component.service';
import * as moment from 'moment';
import { Observable, Subject, isObservable } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBatchWeatherData } from '../interfaces/IBatchWeatherData';
import { IDayData } from '../interfaces/IDayData';
import { IWeatherData } from '../interfaces/IWeatherData';
import { IMonthData } from '../interfaces/IMonthData';
import { IBatchOption } from '../interfaces/IBatchOption';
import { IFilterOption } from '../interfaces/IFilterOption';
import { IBatchesMunicipalities } from '../interfaces/IBatchesMunicipalities';
import { IBatchInfo } from '../interfaces/IBatchInfo';

@Component({
  selector: 'app-historical-weather',
  templateUrl: './historical-weather.component.html',
  styleUrls: ['./historical-weather.component.scss'],
})
export class HistoricalWeatherComponent implements OnInit {
  @ViewChild('dayCardsContainer') dayCardsContainer!: ElementRef;
  @ViewChild('dayCardsInner') dayCardsInner!: ElementRef;
  @Input() municipalityId: number | null = null;
  @Input() municipalityLabel: string | null = null;
  @Input() batchOptions: {
    batch_unique_identifier: string;
    batch_id: number;
  }[] = [];
  private unsubscribe$ = new Subject<void>();

  monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];

  batches$: Observable<any>;
  // batchOptions$: Subject<{ label: string; value: number }[]> = new Subject();
  filtersForm: FormGroup;
  weatherForm: FormGroup;
  months: IMonthData[] = [];
  adjacentMonthIndices: number[] = [];

  batch_history?: IBatchWeatherData | null = null;
  activeMonth?: IMonthData | null = null;
  selectedDay?: IDayData | null = null;
  visibleWeatherInstances: IWeatherData[] | undefined = [];
  selectedDayIndex: number = 0;
  selectedMonthIndex: number | null = null;
  conditionTemp: string = 'soleggiato';

  skip: number = 0;
  take: number = 6;

  filtersWeather: IFilterWeather[] = [
    // {
    //   formControlName: 'category',
    //   placeholder: 'Agrume',
    //   model: 'category',
    //   options: [
    //     { label: 'Agrume', value: null },
    //     { label: 'Arance', value: 1 },
    //     { label: 'Limoni', value: 2 },
    //   ],
    // },
    {
      formControlName: 'year',
      placeholder: '2024',
      model: 'year',
      options: this.generateYearOptions(3),
    },
    // ,
    // {
    //   formControlName: 'batch_id',
    //   placeholder: 'Lotto',
    //   model: 'batch',
    //   options: [],
    // }
  ];

  showMonths: boolean = false; // questo booleano una volta che la tabella collegata sia più ricca di dati andrà rimosso
  batch_info: IBatchInfo | null = null;

  constructor(
    private _componentService: ComponentService,
    private fb: FormBuilder
  ) {
    this.batches$ = this._componentService.batches$;
    this.filtersForm = this.fb.group({
      category: [null, Validators.required],
      year: [null, Validators.required],
      batch_id: [null, Validators.required],
    });

    this.weatherForm = this.fb.group({
      skip: [null, Validators.required],
      take: [null, Validators.required],
      municipality_id: [null, Validators.required],
      year: [null, Validators.required],
      month: [null, Validators.required],
      category: [null, Validators.required],
      day: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.initializeBatchOptions();
    this.generateMonths();
    this._componentService.getMyBatches(1899);

    this.getBatchHistory();

    this.filtersForm.valueChanges
      .pipe(
        debounceTime(600),
        filter((values) => this.areAllSelectsValid(values))
      )
      .subscribe((values) => {
        this.getBatchHistory();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  generateBatchOptions2(): { label: string; value: number }[] {
    return Array.from({ length: this.batchOptions.length }, (_, i) => {
      return {
        label: this.batchOptions[i].batch_unique_identifier,
        value: this.batchOptions[i].batch_id,
      };
    });
  }

  generateYearOptions(range: number): IFilterOption[] {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: range }, (_, i) => ({
      label: (currentYear + i).toString(),
      value: currentYear + i,
    }));
  }

  areAllSelectsValid(values: any): boolean {
    return (
      // values.category !== null &&
      // values.category !== 'category' &&
      values.year !== null && values.year !== 'year'
      // values.batch_id !== null &&
      // values.batch_id !== 'batch'
    );
  }

  generateBatchWeatherData() {
    return {
      batch_id: 1,
      batch_unique_identifier: '006789',
      last_sown: '2023-03-15',
      last_reaped: '2023-09-20',
      weather_data: this.generateWeatherData(),
    };
  }

  private generateWeatherData() {
    const weatherData = [];
    const startDate = moment().startOf('year');
    const endDate = moment().endOf('year');

    let currentMonth = startDate.clone().startOf('month');
    while (currentMonth.isBefore(endDate)) {
      const days = [];
      let currentDay = currentMonth.clone().startOf('month');
      while (currentDay.isBefore(currentMonth.endOf('month'))) {
        const weatherInstances = [
          {
            time: '06:00',
            temperature: this.randomTemperature(),
            humidity: this.randomHumidity(),
            condition: this.randomCondition(),
            icon: this.getIconPath(this.conditionTemp),
          },
          {
            time: '12:00',
            temperature: this.randomTemperature(),
            humidity: this.randomHumidity(),
            condition: this.randomCondition(),
            icon: this.getIconPath(this.conditionTemp),
          },
          {
            time: '18:00',
            temperature: this.randomTemperature(),
            humidity: this.randomHumidity(),
            condition: this.randomCondition(),
            icon: this.getIconPath(this.conditionTemp),
          },
          {
            time: '24:00',
            temperature: this.randomTemperature(),
            humidity: this.randomHumidity(),
            condition: this.randomCondition(),
            icon: this.getIconPath(this.conditionTemp),
          },
        ];

        const averageTemperature =
          this.calculateAverageTemperature(weatherInstances);
        const conditionSummary = this.summarizeCondition(weatherInstances);
        const icon = this.getIconPath(conditionSummary);

        days.push({
          day: currentDay.date(),
          average_temperature: averageTemperature,
          condition_summary: conditionSummary,
          weather_instances: weatherInstances,
          icon: icon,
        });

        currentDay = currentDay.add(1, 'day');
      }
      weatherData.push({ month: currentMonth.format('MMMM'), days });
      currentMonth = currentMonth.add(1, 'month');
    }

    return weatherData;
  }

  private randomTemperature() {
    return Math.floor(Math.random() * 30);
  }

  private randomHumidity() {
    return Math.floor(Math.random() * 100);
  }

  private calculateAverageTemperature(
    weatherInstances: { temperature: number }[]
  ): number {
    const totalTemperature = weatherInstances.reduce(
      (total, instance) => total + instance.temperature,
      0
    );
    return totalTemperature / weatherInstances.length;
  }

  private summarizeCondition(
    weatherInstances: { condition: string }[]
  ): string {
    const conditionCount: { [key: string]: number } = {};
    weatherInstances.forEach((instance) => {
      conditionCount[instance.condition] =
        (conditionCount[instance.condition] || 0) + 1;
    });

    const sortedConditions = Object.entries(conditionCount).sort(
      (a, b) => b[1] - a[1]
    );
    return sortedConditions[0][0]; //
  }

  private randomCondition(): string {
    const conditions = [
      'soleggiato',
      'nuvoloso',
      'piovoso',
      'nebbioso',
      'temporalesco',
      'possibile_pioggia',
      'neve',
    ];

    this.conditionTemp =
      conditions[Math.floor(Math.random() * conditions.length)];
    return conditions[Math.floor(Math.random() * conditions.length)];
  }

  getBatchHistory() {
    const currentDate = moment();
    const currentDay: string = currentDate.format('DD');
    const currentMonth: string = currentDate.format('MM');
    const currentYear: string = currentDate.format('YYYY');
    const selectedDayDate: string = moment(this.selectedDay?.created_at).format(
      'DD'
    );
    if (this.activeMonth === null) {
      this.activeMonth = this.months.find((m) => m.value === currentMonth);

      const currentMonthIndex = this.months.findIndex(
        (m) => m.value === currentMonth
      );

      this.updateAdjacentMonths(currentMonthIndex);
    }
    // cerchiamo di mettere un un elemento singolo con validazione e poi si vede
    this.weatherForm.patchValue({
      skip: this.skip,
      take: this.take,
      municipality_id: this.municipalityId ?? 1899,
      year: this.filtersForm.get('year')?.value ?? currentYear,
      month: this.activeMonth?.value ?? currentMonth,
      category: this.filtersForm.get('category')?.value ?? 1,
      day: selectedDayDate ?? currentDay,
    });

    this._componentService
      .getWeather(this.weatherForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: IBatchWeatherData) => {
        this.batch_history = response;
        if (this.batch_history) {
          this.setData();
        }
      });
  }

  setData() {
    const defaultMonth = this.batch_history?.data.monthly_data;
    if (defaultMonth) {
      this.selectedDay = this.selectedDay ?? defaultMonth[0];
      this.visibleWeatherInstances = this.batch_history?.data?.daily_data;
      this.showMonths = true;

      const currentMonthIndex = this.months.findIndex(
        (m) => m.value === this.activeMonth?.value
      );

      this.updateAdjacentMonths(currentMonthIndex);
    } else {
      this.selectedDay = null;
    }
  }

  selectMonth(month: IMonthData, index: number, event?: any): void {
    const isKeyboardEvent =
      event instanceof KeyboardEvent &&
      (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event?.preventDefault();

    if (this.activeMonth?.value === month.value) {
      return;
    }

    this.selectedMonthIndex = index;
    this.activeMonth = month;
    this.selectedDay = null;
    this.getBatchHistory();
  }

  selectDay(day: IDayData, i: number, event?: any): void {
    const isKeyboardEvent =
      event instanceof KeyboardEvent &&
      (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event?.preventDefault();

    if (this.selectedDay?.id === day.id) {
      return;
    }

    this.selectedDay = day;
    this.selectedDayIndex = i;
    this.getBatchHistory();
  }

  scrollDays(direction: number): void {
    if (direction === -1 && this.skip > 0) {
      this.skip -= this.take;
    } else if (
      direction === 1 &&
      this.skip + this.take < this.batch_history?.total_month
    ) {
      this.skip += this.take;
    }
    this.getBatchHistory();
  }

  getIconPath(conditionSummary: string): string {
    const iconBasePath = '../../../../assets/icon/previsioni/';
    const iconMap: { [key: string]: string } = {
      Clear: 'soleggiato.svg',
      Clouds: 'nuvoloso.svg',
      Rain: 'piovoso.svg',
      nebbioso: 'nebbioso.svg',
      Thunderstorm: 'temporalesco.svg',
      possibile_pioggia: 'possibile_pioggia.svg',
      neve: 'neve.svg',
    };
    return iconBasePath + (iconMap[conditionSummary] || 'soleggiato.svg');
  }

  generateWeatherData2() {
    const weatherData: IDayData[] = [];
    const startDate = moment().startOf('year');
    const endDate = moment().endOf('year');

    let currentMonth = startDate.clone().startOf('month');
    while (currentMonth.isBefore(endDate)) {
      const days: IDayData[] = [];
      let currentDay = currentMonth.clone().startOf('month');
      while (currentDay.isBefore(currentMonth.endOf('month'))) {
        const weatherInstances: IWeatherData[] = [
          {
            id: 1,
            municipality_id: 1,
            weather_description: 'Sunny',
            weather_main: 'Clear',
            temp_main: '25',
            temp_min: '20',
            temp_max: '30',
            pressure: '1012',
            humidity: '50',
            wind_speed: '5',
            deleted_at: null,
            created_at: '2024-01-01T00:00:00Z',
            updated_at: '2024-01-01T00:00:00Z',
          },
          // more instances
        ];
        days.push({
          id: 1,
          municipality_id: 1,
          weather_description: 'Sunny',
          weather_main: 'Clear',
          temp_main: '25',
          temp_min: '20',
          temp_max: '30',
          pressure: '1012',
          humidity: '50',
          wind_speed: '5',
          deleted_at: null,
          created_at: currentDay.format(),
          updated_at: currentDay.format(),
          weather_instances: weatherInstances,
        });
        currentDay = currentDay.add(1, 'day');
      }
      weatherData.push(...days);
      currentMonth = currentMonth.add(1, 'month');
    }

    return weatherData;
  }

  generateMonths(): void {
    for (let i = 0; i < 12; i++) {
      this.months.push({
        label: this.monthNames[i],
        value: moment().month(i).format('MM'),
      });
    }
  }

  updateAdjacentMonths(currentMonthIndex: number) {
    this.adjacentMonthIndices = [];
    if (currentMonthIndex > 0) {
      this.adjacentMonthIndices.push(currentMonthIndex - 1);
    }
    if (currentMonthIndex < this.months.length - 1) {
      this.adjacentMonthIndices.push(currentMonthIndex + 1);
    }
  }

  isAdjacentMonth(index: number): boolean {
    return this.adjacentMonthIndices.includes(index);
  }

  isObservable(obj: any): obj is Observable<any> {
    return isObservable(obj);
  }

  initializeBatchOptions(): void {
    this.batches$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((batches: IBatchesMunicipalities) => {
        const batchFilter = this.filtersWeather.find(
          (filter) => filter.formControlName === 'batch_id'
        );

        if (batchFilter && batches.data && batches.data.length > 0) {
          batchFilter.options = batches.data.map((batch: IBatchOption) => ({
            label: batch.batch_unique_identifier,
            value: batch.id,
          }));
        }
      });
  }

  getInfoField() {
    this._componentService
      .getBatchFieldSowingAndReaping(this.filtersForm.get('batch_id')?.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.code === 200) {
          this.batch_info = response.data;
        }
      });
  }
}
