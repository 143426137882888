import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NonNullableFormBuilder, FormControl } from '@angular/forms';
import { ComponentService } from 'src/app/components/component.service';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-latestShipments',
  templateUrl: './latestShipments.component.html',
  styleUrls: ['./latestShipments.component.scss']
})
export class LatestShipmentsComponent implements OnInit {

  @Input() actor!: string;
  visible: boolean = false;
  selectedItemIndex: number = -1;
  batchToSelect: any;
  item!: any;
  lastBatch!: string;
  loaded: boolean = false;

  // shipment = {
  //     lotto: '004588',
  //     status: 'In lavorazione',
  //     cultivar: 'Arancia Tarocco',
  //     quantity: '240',
  //     steps : [
  //       {
  //         name: 'Produttore',
  //         path: '../../../assets/icon/produttore.svg',
  //         status: 'finish',
  //         shipmentStatus: 'Spedito',
  //         shippingDate: '7 aprile 2024',
  //         transit: false,
  //         deliveryDate: null,
  //         visible: false,
  //         otherInfo: [
  //           {
  //             field: 'Nicosia (CT)',
  //             site: 'Nicosia (CT)',
  //             shippingType: 'Nicosia (CT)',
  //             marketPoint: 'Nicosia (CT)',
  //             consSite: 'Nicosia (CT)',
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Selezione e stoccaggio',
  //         path: '../../../assets/icon/trasformatore.svg',
  //         status: 'progress',
  //         shipmentStatus: 'Consegnato',
  //         shippingDate: null,
  //         transit: false,
  //         deliveryDate: '12 aprile 2024',
  //         visible: false,
  //         otherInfo: [
  //           {
  //             field: 'Nicosia (CT)',
  //             site: 'Nicosia (CT)',
  //             shippingType: 'Nicosia (CT)',
  //             marketPoint: 'Nicosia (CT)',
  //             consSite: 'Nicosia (CT)',
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Distributore',
  //         path: '../../../assets/icon/distributore.svg',
  //         status: 'wait',
  //         shipmentStatus: null,
  //         shippingDate: null,
  //         transit: false,
  //         deliveryDate: null,
  //         visible: false,
  //         otherInfo: [
  //           {
  //             field: 'Nicosia (CT)',
  //             site: 'Nicosia (CT)',
  //             shippingType: 'Nicosia (CT)',
  //             marketPoint: 'Nicosia (CT)',
  //             consSite: 'Nicosia (CT)',
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Punto Vendita',
  //         path: '../../../assets/icon/venditore.svg',
  //         status: 'wait',
  //         shipmentStatus: null,
  //         shippingDate: null,
  //         transit: false,
  //         deliveryDate: null,
  //         visible: false,
  //         otherInfo: [
  //           {
  //             field: 'Nicosia (CT)',
  //             site: 'Nicosia (CT)',
  //             shippingType: 'Nicosia (CT)',
  //             marketPoint: 'Nicosia (CT)',
  //             consSite: 'Nicosia (CT)',
  //           }
  //         ]
  //       }
  //     ]
  //   }

  shipment: any

  constructor(
    private fb: FormBuilder,
    private _ComponentService: ComponentService,
    private router: Router,
    private _loggingService: LoggingService,
    private _utils: Utils
  ) { }

  ngOnInit() {
    this.getLatestLotto();
  }

  getData() {
    this._ComponentService.timeLine(this.lastBatch).subscribe(r => {
      console.log('TIMELINE', r);
      this.shipment = r.data
      this.loaded = true
    })
  }

  getLatestLotto() {
    this._ComponentService.lastBatchShipping(this.actor).subscribe(r => {
      this.lastBatch = r.data
      this.getData()
    })
  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 33);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-latestShipments'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);

    })
  }

}
