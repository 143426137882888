<div class="mx-2">

    <h4 class="header-widget">Spedizione in corso</h4>
    <div class="fs-12 mt-4">
        Lotto: <span class="lotto ms-2 px-3 py-1">{{product?.batch_id}}</span>
    </div>

    <!-- Barra d'avanzamento -->
    <div class="row mt-4">
        <div class="col-2 d-flex align-items-center justify-content-center box-perc">
            <span class="fs-12 me-1">{{ product?.percentage }}%</span>
        </div>

        <div class="col-2 pe-0">
            <div class="progress progress-vertical">
                <div class="progress-bar bg-success" role="progressbar" [style.height.%]="product?.percentage"
                    [attr.aria-valuenow]="product?.percentage" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>

        <div class="col-8 d-flex flex-column justify-content-between  ps-0">
            <div class="address-box">
                <div class="fw-500 fs-14">
                    Partenza
                </div>
                {{ this.product?.partenza }} <br>
                {{ this.product?.departure_time }}
            </div>
            <div class="address-box">
                <div class="fw-500 fs-14">
                    Destinazione
                </div>
                {{ this.product?.destinazione }} <br>
                {{ this.product?.arrived_time }}
            </div>
        </div>
    </div>

    <!-- Box trasporto -->
    <!-- <button data-bs-toggle="modal" data-bs-target="#exampleModal">apri</button> -->
    <div *ngIf="this.product?.transport!=null; else add">

        <div class="mt-4 d-flex justify-content-between fs-14">
            <div class="fw-500">Modalità di trasporto</div>
            <div class="d-flex flex-row" style="color: #056564; cursor: pointer;" data-bs-toggle="modal"
                data-bs-target="#exampleModal" (click)="patchForm()">
                Modifica <img src="/assets/icon/right-arrow.svg">
            </div>
        </div>

        <div class="bd-rad mt-2">
            <div class="pt-2 ps-2">
                <span class="camion px-2 py-1">{{this.product?.transport}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center mx-3">
                <div>
                    <img src="/assets/img/camion.svg">
                </div>
                <div>
                    <div class="fw-500 fs-14">Temperatura</div>
                    <div class="fs-1 fw-500">
                        {{this.product?.temperature}}&deg;
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #add>
        <div class="mt-4">
            <span class="mod-subtitle fw-500 fs-14">Mezzo di trasporto</span>
        </div>

        <div class="bd-rad mt-2 add-box">
            <div class="d-flex flex-row cursor-pointer align-items-center" data-bs-toggle="modal"
                data-bs-target="#exampleModal">
                <div><img src="/assets/img/add-button.svg"></div>
                <div class="ms-2 fw-500">Aggiungi</div>
            </div>
        </div>
    </ng-template>

</div>


<!-- * Modale aggiunta-->

<div class="modal fade custom-modal" id="exampleModal" #exampleModal tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <form [formGroup]="formShipment">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title fs-5" id="exampleModalLabel">Imposta le modalità di trasporto</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        (click)="closeModal()"></button>
                </div>

                <div class="modal-body">
                    <div class="d-flex flex-rox">
                        <div class="me-3 d-flex align-items-center">
                            <img [src]="getIconPath(product?.category)" alt="cytrus">
                        </div>
                        <div class="d-flex flex-column">
                            <div class="mod-subtitle">Lotto</div>
                            <div class="mod-text">{{product?.batch_id}}</div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                        <div class="w-50 me-2">
                            <div class="mod-subtitle">Mezzo di trasporto</div>
                            <select class="form-select" formControlName="mezzo" aria-label="Default select example">
                                <option *ngFor="let item of array_select" [value]="item.value">{{ item.label }}
                                </option>
                            </select>
                        </div>
                        <div class="w-50 ms-2">
                            <div class="mod-subtitle">Temperatura &deg;</div>
                            <input class="form-control" type="number" formControlName="temperatura"
                                placeholder="Inserisci temperatura" aria-label="Temperature input" min="-40" max="40">
                        </div>
                    </div>
                </div>

                <div class="modal-end">
                    <div class="d-flex flex-row justify-content-between">
                        <button nz-button [nzSize]="size" nzType="primary" nzShape="round"
                            class="button-cancel w-50 me-2 text-center" data-bs-dismiss="modal" (click)="closeModal()">
                            Annulla
                        </button>
                        <button nz-button [nzSize]="size" nzType="primary" nzShape="round"
                            class="button-add w-50 ms-2 text-center" data-bs-dismiss="modal" (click)="save()">
                            Salva
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>