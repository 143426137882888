import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NonNullableFormBuilder, FormControl } from '@angular/forms';
import { ComponentService } from '../component.service';

@Component({
  selector: 'app-shipment-tracking',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss']
})
export class ShipmentTrackingComponent implements OnInit {

  form!: FormGroup;
  visible: boolean = false;
  selectedItemIndex: number = 0;
  batchToSelect: any;
  item!: any;



  shipment: any;

  constructor(
    private fb: FormBuilder,
    private _componentAervice: ComponentService
  ) { }

  ngOnInit() {
    this.getMyBatches();
    this.form = this.fb.group({
      field: new FormControl(null),
      lotto: new FormControl(null),
      search: new FormControl(null)
    });
  }

  submitForm() {
    this.getTimelineData();
  }

  closeDetail(): void {
    this.visible = false;
  }

  change(value: boolean): void {

  }

  openDetails(item: any, index: any): void {
    this.visible = true;
    setTimeout(() => {
      this.selectedItemIndex = index;
      this.item = item

    });
  }



  getMyBatches() {
    this._componentAervice.getMyTimelineBatch().subscribe(r => {
      this.batchToSelect = r.result;
      this.form.patchValue({ lotto: this.batchToSelect[0] });
      this.getTimelineData();
    })
  }

  getTimelineData() {
    this._componentAervice.timeLine(this.form.value.lotto).subscribe(r => {
      console.log('TIMELINE', r);
      r.data.steps = r.data.steps.map((item: any) => ({ ...item, visible: false }));
      this.shipment = r.data

    })
  }


}
