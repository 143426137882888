<div *ngIf="showWidget">
  <div *ngIf="!isEmpty">
    <div class="d-flex justify-content-evenly header-container">
      <div>
        <h4 class="header-widget">Prezzi di mercato</h4>
      </div>
      <div class="select-prov">
        <nz-select class="test" nzPlaceHolder="Seleziona una provincia" [(ngModel)]="selectedField" (ngModelChange)="getData()">
          <nz-option *ngFor="let p of province" [nzLabel]="p.label" [nzValue]="p" class="color">
            {{ p.label }}
          </nz-option>
        </nz-select>
      </div>
      <span style="cursor: pointer;" (click)="explodeWidget2()">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>





    <div class="d-flex justify-content-center">
      <canvas #baseChars baseChart height="400" [type]="'line'" [data]="lineChartData" [options]="lineChartOptions"
        [legend]="lineChartLegend">
      </canvas>
    </div>
    <div *ngIf="isEmpty">
      <h1>No data</h1>
    </div>
  </div>
