import { Component, Input } from '@angular/core';
import { IWeatherData } from '../interfaces/IWeatherData';

@Component({
  selector: 'app-weather-instance-card',
  templateUrl: './weather-instance-card.component.html',
  styleUrls: ['./weather-instance-card.component.scss'],
})
export class WeatherInstanceCardComponent {
  @Input() weatherInstance: IWeatherData | null = null;

  getIconPath(conditionSummary: string): string {
    const iconBasePath = '../../../../assets/icon/previsioni/';
    const iconMap: { [key: string]: string } = {
      Clear: 'soleggiato.svg',
      Clouds: 'nuvoloso.svg',
      Rain: 'piovoso.svg',
      nebbioso: 'nebbioso.svg',
      Thunderstorm: 'temporalesco.svg',
      possibile_pioggia: 'possibile_pioggia.svg',
      neve: 'neve.svg',
    };
    return iconBasePath + (iconMap[conditionSummary] || 'soleggiato.svg');
  }
}
