import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-salePoint-home',
  templateUrl: './salePoint-home.component.html',
  styleUrls: ['./salePoint-home.component.scss']
})
export class SalePointHomeComponent implements OnInit {

  widget = new Array;

  constructor(
    private authService: AuthService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.authService.getUser().subscribe(r => {
      let role_id = localStorage.getItem('role_id');
      this.getAllWidget(role_id);
    });
  }

  getAllWidget(role_id: any) {
    this.adminService.getAllwidgetRole(role_id).subscribe(r => {
      r.data.forEach((element: { value: any; }) => {
        this.widget.push(element.value);
      });
      console.log('tutti i wigdet trasformatore', this.widget);
    });
  }

}
