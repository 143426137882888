import { Component, OnInit } from '@angular/core';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { ComponentService } from '../component.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

  // text: any;
  selectedYear: any;
  selectedMonth: number ;
  selectedDate = 'Gennaio 2024'
  loadWidget: boolean = false;
  entrance!: any;
  exit!: any;
  occupation: any;
  role: any;

  years: any;
  months: any;

  months2 = [
    { label: 'Gennaio', value: 1 },
    { label: 'Febbraio', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Aprile', value: 4 },
    { label: 'Maggio', value: 5 },
    { label: 'Giugno', value: 6 },
    { label: 'Luglio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Settembre', value: 9 },
    { label: 'Ottobre', value: 10 },
    { label: 'Novembre', value: 11 },
    { label: 'Dicembre', value: 12 },
  ];


  chartLegend: any

  cols = ['Lotto', 'Produttore', 'Giacenza', 'Quantitá', 'Date di ingresso e uscita', 'Destinazione'];

  data: any;

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: [''],
    datasets: [
      {
        data: [],
        backgroundColor: [''],
        hoverOffset: 4,
      }
    ]
  };

  public doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '70%',
    radius: '60%',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
    },

  };

  public doughnutChartPlugins: Plugin[] = [{
    id: 'customPlugin',
    beforeDraw: (chart) => {
      const width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      const fontSize = Math.min(height, width) * 0.08;
      ctx.font = fontSize + 'px sans-serif';
      ctx.textBaseline = 'middle';

      const text = this.occupation + '/100%',
        textWidth = ctx.measureText(text).width,
        textX = (width - textWidth) / 2,
        textY = height / 2;

      ctx.fillText(text, textX, textY);

      const subTextFontSize = fontSize * 0.5;
      ctx.font = subTextFontSize + 'px sans-serif';
      ctx.save();
    }
  }];


  constructor(
    private _componentService: ComponentService,
    private route: ActivatedRoute,
  ) {
    const currentDate = moment();
    this.selectedMonth = Number(currentDate.format('MM'));
    }

  ngOnInit() {
    this.getLastThreeYears();
    this.role = this.route.snapshot.paramMap.get('role');
  }

  getInfoInStock() {
    this.loadWidget = false;
    this._componentService.getInfoInStock(this.selectedYear, this.selectedMonth).subscribe(r => {

      this.doughnutChartData.labels = r.data.chart.labels;
      this.doughnutChartData.datasets[0].data = r.data.chart.datasets[0].data;
      this.doughnutChartData.datasets[0].backgroundColor = r.data.chart.datasets[0].backgroundColor;
      this.occupation = Math.floor(r.data.chart.occupation);

      this.loadWidget = true;

      this.chartLegend = r.data.percentage;

      this.entrance = r.data.type_total_sum.receiving;
      this.exit = r.data.type_total_sum.requesting;

      this.data = r.data.data;

    })
  }


  getMonths() {
    if (!this.selectedYear) {
      this.selectedYear = this.years[0];
    }
    this._componentService.getMonths(this.selectedYear).subscribe(r => {
      this.months = r.data;

      const monthKeys = this.months.map((month: any) => month.key);
      if (!monthKeys.includes(this.selectedMonth)) {
        this.selectedMonth = this.months[this.months.length - 1].key;
      }

      this.getInfoInStock();
    });
  }

  getLastThreeYears(): number[] {
    const currentYear = new Date().getFullYear();
    let date = [currentYear, currentYear - 1, currentYear - 2];
    this.years = date;
    this.getMonths();
    return date

  }

  stringMonth(month : number) {
    let label = this.months2.find((m) => m.value == month)?.label;
    return label;
  }

}
