<div class="row mb-3" *ngIf="widget.includes('app_alert_fms')">
    <div class="col-12">
        <div class="card h-100 bd-rad box-alert">
            <div class="card-body">
                <app-alert></app-alert>
            </div>
        </div>
    </div>
</div>
<div class="row mb-3" >
    <div class="col-12">
        <div class="card h-100 bd-rad bg-color" *ngIf="widget.includes('app-line-chart'); else noData">
            <div class="card-body">
                <app-line-chart></app-line-chart>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card h-100 bd-rad bg-feedback" *ngIf="widget.includes('feedback'); else noData">
            <div class="card-body">
                <app-widget-feedback></app-widget-feedback>
            </div>
        </div>
    </div>
</div>

<ng-template #noData>
    <div class="card bd-rad bg-no-data h-100">
        <div class="card-body">
            <app-no-data></app-no-data>
        </div>
    </div>
</ng-template>