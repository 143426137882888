import { Component, OnInit } from '@angular/core';
import { ComponentService } from '../component.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lotHistory',
  templateUrl: './lotHistory.component.html',
  styleUrls: ['./lotHistory.component.scss']
})
export class LotHistoryComponent implements OnInit {

  status!: string;
  statusArray: any = [];
  alert = 0
  userRole: any;

  productsType = [
    {
      id: 0,
      // path: '../../../assets/icon/modal-orange-icon.svg',
      label: 'Tutti',
      selected: true
    },
    {
      id: 1,
      path: '../../../assets/icon/modal-orange-icon.svg',
      label: 'Arance',
      selected: false
    },
    {
      id: 2,
      path: '../../../assets/icon/modal-lemon-icon.svg',
      label: 'Limoni',
      selected: false
    }
  ]

  cols: any
  data: any;
  type: any;
  search: any;
  active: boolean = false;
  constructor(
    private _componentService: ComponentService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getUserRole();
    this.getData();
  }

  getUserRole() {
    this._componentService.getUserRole().subscribe(r => {
      this.userRole = r;
      if (this.userRole == 2) {
        this.cols = ['Lotto', 'Prodotto', 'Produttore', 'Quantitá', 'Stato'];
      }
      else {
        this.cols = ['Lotto', 'Prodotto', 'Produttore', 'Quantitá', 'Stato', ''];
      }
    })
  }

  getData(type?: any, search?: any, status?: string) {
    this.statusArray = []
    this._componentService.getMyBatchesOnTimeLine(type, search, status).subscribe(r => {
      if (r.data.length > 0) {
        this.data = r.data;
        console.log('Data', this.data);
        this.data.forEach((element: any) => {
          console.log('Data for', element);
          if (element.is_waiting == 1 &&  (element.confirmed == 0 || element.confirmed == null)) {
            this.statusArray.push(element)
            console.log('DENTRO');
            
          }
        });
        this.alert = this.statusArray.length
        this.active = true;
      } else {
        this.data = [{
          batch_id: 'Nessun dato'
        }]
        this.active = false;
      }


    })
  }

  selectProductType(id: number): void {
    this.productsType.forEach(p => p.selected = false);
    const product = this.productsType.find(p => p.id === id);
    if (product) {
      product.selected = true;
      this.type = product.id
      let src
      if (this.search) {
        src = this.search
      } else {
        src = ''
      }
      if (this.status) {
        this.getData(this.type, src, this.status);
      }
      else {
        this.getData(this.type, src);
      }
    }

  }

  searchData() {
    if (this.status) {
      this.getData(this.type, this.search, this.status);
    }
    else {
      this.getData(this.type, this.search);
    }
  }

  goTo(id: any) {
    if (this.active) {
      this.router.navigate(['/lot-history', id]);
    }

  }

  getFilterDataStatus() {
    console.log(this.status);
    if (this.search) {
      this.getData(this.type, this.search, this.status);
    }
    else {
      this.getData(this.type, null, this.status);
    }
  }

  conferm(batch_id: any) {
    let data = { batch_id: batch_id }
    this._componentService.confermBatch(data).subscribe(r => {
      this.getData();
      Swal.fire({
        title: "Spedizione confermata",
        text: "Spedizione confermata",
        html: '<img src="assets/icon/success-icon.svg" alt="Custom Icon">',
        showConfirmButton: false,
        timer: 2000
      });
    })

  }

}
