import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { SeeAllComponent } from './see-all/see-all.component';
import { FeedbackListComponent } from './feedback-list/feedback-list.component';
import { ShipmentTrackingComponent } from './shipment-tracking/shipment-tracking.component';
import { LotHistoryComponent } from './lotHistory/lotHistory.component';
import { LotHistoryTimelineComponent } from './lotHistoryTimeline/lotHistoryTimeline.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { LotHistoryMobileComponent } from './lotHistoryMobile/lotHistoryMobile.component';
import { ConsumerProductsComponent } from '../modules/producer/components/consumerProducts/consumerProducts.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { UserProfileComponent } from './userProfile/userProfile.component';
import { ChatbotComponent } from './chatbot/chatbot.component';

const routes: Routes = [
  { path: 'producer', loadChildren: () => import('../modules/producer/producer.module').then(m => m.ProducerModule), canActivate: [AuthGuard] },
  { path: 'trasformer', loadChildren: () => import('../modules/trasformer/trasformer.module').then(m => m.TrasformerModule), canActivate: [AuthGuard] },
  { path: 'vendor', loadChildren: () => import('../modules/venditore/venditore.module').then(m => m.VenditoreModule) },
  { path: 'profile', title: 'Profilo', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', title: 'Dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'see-all', component: SeeAllComponent, canActivate: [AuthGuard] },
  { path: 'feedback', component: FeedbackListComponent, canActivate: [AuthGuard] },
  { path: 'shipment-tracking', component: ShipmentTrackingComponent, canActivate: [AuthGuard] },
  { path: 'lot-history', component: LotHistoryComponent, canActivate: [AuthGuard] },
  { path: 'lot-history/:lotto', component: LotHistoryTimelineComponent, canActivate: [AuthGuard] },
  { path: 'lot-history-consumer/:lotto', component: LotHistoryMobileComponent, canActivate: [AuthGuard] },
  { path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuard] },
  { path: 'consumer/products', component: ConsumerProductsComponent, canActivate: [AuthGuard] },
  { path: 'warehouse/:role', component: WarehouseComponent, canActivate: [AuthGuard] },
  { path: 'user-profile/:id', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'chatbot', component: ChatbotComponent},
  { path: '', component: DashboardComponent, pathMatch: 'full' },
  { path: '*', redirectTo: '' }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComponentsRoutingModule { }
