<div class="container" *ngIf="showWidget">
  <div class="d-flex justify-content-between">
    <div>
      <h4 class="header-widget">Tempi di vendita</h4>
    </div>
    <div class="select-type">
      <nz-select nzPlaceHolder="Seleziona tipo" [(ngModel)]="selectType" (ngModelChange)="getData()">
        <nz-option *ngFor="let type of types" [nzLabel]="type.type" [nzValue]="type.value"
          class="color">{{type}}</nz-option>
      </nz-select>
    </div>
    <!-- <span style="cursor: pointer;" (click)="explodeWidget()">
      <img src="/assets/img/arrow.svg" alt="">
    </span> -->
  </div>

  <div class="mit-time">
    Tempo medio: <span>{{average}} giorni</span>
  </div>

  <div class="barr-chart">
    <canvas id="barChart" baseChart [data]="barChartData" [options]="barChartOptions" [type]="barChartType"
      [legend]="barChartLegend">
    </canvas>
  </div>

  <!-- <div style="display: block">
    <canvas id="barChart" baseChart [options]="barChartOptions" [type]="barChartType" [legend]="barChartLegend">
    </canvas>
  </div> -->


</div>