<section class="mb-4 mt-5 mb-xl-0 bg-light d-none d-lg-block">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid py-0 py-xl-auto">
      <div class="d-flex flex-row justify-content-xl-between">
        <a class="navbar-brand" href="app/dashboard"><img src="assets/icon/orange-32.png" alt=""> CiTrace</a>

        <ul nz-menu nzMode="horizontal" class="d-none d-xl-block">
          <!-- <li nz-menu-item *ngFor="let page of pages" [routerLink]="page.path" [nzSelected]="page.selected">
            <span nz-icon [nzType]="page.icon!"></span>
            {{ page.name }}
          </li> -->
        </ul>
      </div>

      <div class="btn-group me-5">
        <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
          aria-expanded="false">
          <button type="button" class="btn">
            <nz-avatar class="text-uppercase mx-2 ant-avatar" [ngStyle]="{ 'background-color': '#FBEAF0', 'color': '#801B3D' }"
              [nzText]="currentUser?.name?.charAt(0) ?? 'U'"></nz-avatar>
            <span>{{ currentUser?.email || 'Utente' }}</span>
          </button>
        </button>

        <ul class="custom-dropdown dropdown-menu">
          <li>
            <a routerLink="/app/profile"><i class="btn fa-solid fa-gear text-muted"></i> Profilo</a>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li class="d-flex flex-row mx-3">
            <span class="cursor-pointer" (click)="logout()">Esci</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="d-xl-none col-12 px-0">
      <ul nz-menu nzMode="horizontal">
        <!-- <li nz-menu-item *ngFor="let page of pages" [routerLink]="page.path" [nzSelected]="page.selected">
          <span nz-icon [nzType]="page.icon!"></span>
          {{ page.name }}
        </li> -->
      </ul>
    </div>
  </nav>
</section>

<!-- ! SECONDA PARTE  -->

<nav class="navbar bg-white fixed-top d-block d-lg-none">
  <div class="container justify-content-between">
    <a class="navbar-brand ms-3" href="app/dashboard">
      <img src="assets/icon/orange-32.png" alt=""> CiTrace
    </a>

    <button class="btn btn-outline-secundary" type="button" aria-controls="offcanvasNavbar"
      (click)="changeDrawerStatus()">
      <!-- data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" -->
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- </div> -->
  </div>
</nav>

<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" nzPlacement="right" nzTitle="Menu"
  [nzCloseOnNavigation]="true" (nzOnClose)="changeDrawerStatus()">
  <!-- <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel"> 
  <div class="offcanvas-header justify-content-end">
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body"> -->
  <ng-container *nzDrawerContent>
    <ul class="navbar-nav justify-content-start flex-grow-1 pe-3">
      <li class="nav-item">
        <a class="nav-link active" aria-current="page" routerLink="app/dashboard" (click)="selectPage()">
          <span class="text-capitalize">Dashboard</span>
        </a>
      </li>

      <li class="nav-item" (click)="logout()">
        <a class="nav-link active" aria-current="page">
          <span class="text-capitalize">Esci</span>
        </a>
      </li>
    </ul>
  </ng-container>
  <!-- </div> -->
</nz-drawer>