<div class="statistics-container">
  <div class="d-flex justify-content-between align-items-center">
    <div class="title">Statistiche</div>
    <div *ngIf="selectedTab === 1" class="header-select">
      <nz-select nzPlaceHolder="Campo" [(ngModel)]="selectedField" nzBorderless class="wide-select"
        (ngModelChange)="filterDataField()">
        <nz-option [nzValue]="null" nzLabel="tutti"></nz-option>
        <nz-option *ngFor="let field of fields" [nzValue]="field" [nzLabel]="field.name"></nz-option>
      </nz-select>
    </div>
    <div *ngIf="selectedTab === 2 " class="header-select">
      <nz-select nzPlaceHolder="Comune" [compareWith]="compareFn" [(ngModel)]="selectedFieldForWeather" nzBorderless
        class="wide-select" (ngModelChange)="filterDataField()">
        <nz-option *ngFor="let municipality of municipalities" [nzValue]="municipality"
          [nzLabel]="municipality.label"></nz-option>
      </nz-select>
    </div>
    <div *ngIf="selectedTab === 3 " class="header-select">
      <nz-select nzPlaceHolder="Comune" [compareWith]="compareFn" [(ngModel)]="selectedFieldForMarketPrices"
        nzBorderless class="wide-select" (ngModelChange)="filterDataField()">
        <nz-option *ngFor="let m of municipalities_market" [nzValue]="m" [nzLabel]="m.label"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="tabs d-flex align-items-center">
    <div class="tab" *ngFor="let tab of tabs" (click)="selectTab(tab.id)" [ngClass]="{'selected-tab' : tab.selected}">
      {{tab.tab}}
    </div>
  </div>

  <div [ngSwitch]="selectedTab">
    <!-- Template for "Andamento produzione" -->
    <div *ngSwitchCase="1">
      <div class="prod-trend-container">
        <div class="field">
          <span *ngIf="selectedField">
            {{selectedField ? 'Campo:' : ''}}
            <span class="field-selected">{{ selectedField?.name }}</span>
          </span>
          <div class="content-tab">
            <div class="d-flex justify-content-between align-items-center title-tab-container">
              <div class="title-tab">Andamento Produzione</div>
              <div class="tab-select size-sel">
                <nz-select nzPlaceHolder="Anno" [(ngModel)]="selectedYear" class="custom-select placeholder-color"
                  style="display: flex; align-items:center" (ngModelChange)="filterForYear()">
                  <nz-option *ngFor="let year of lastThreeYears" [nzLabel]="year" [nzValue]="year"
                    class="color"></nz-option>
                </nz-select>
              </div>
            </div>
            <div *ngIf="showWidget" class="header-barr d-flex justify-content-between align-items-center">
              <div class="total d-flex align-items-center">
                <div class="box d-flex align-items-center" *ngFor="let type of productionTrendCards">
                  <div class="box-img">
                    <img [src]="type.image" alt="">
                  </div>
                  <div class="box-data">
                    <div class="box-label">Totale {{type?.type}} prodotte</div>
                    <div class="box-value">{{type?.total}} kg</div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="showWidget" class="barr-chart-container">
              <canvas id="barChart" baseChart [data]="barChartData" [options]="barChartOptions" [type]="barChartType"
                [legend]="barChartLegend">
              </canvas>
            </div>

            <div class="accordion-tab-container">

              <div class="selectType">
                <div class="filters d-flex align-items-center">
                  <div *ngFor="let item of productsType" class="d-flex flex-row align-items-center filter"
                    [ngClass]="{'selected': item.selected, 'not-selected': !item.selected}"
                    (click)="selectProductType(item.id)">
                    <div *ngIf="item.path" class="img">
                      <img [src]="item.path" alt="">
                    </div>
                    <div class="label">{{item.label}}</div>
                  </div>
                </div>
              </div>

              <!-- <div class="table-cont">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="font-title" *ngFor="let col of cols">{{col}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of tableData">
                    <td class="text-style-big padd">
                      <div class="d-flex align-items-center">
                        <div class="icon-tab">
                          <img [src]="item.icon" alt="">
                        </div>
                        <div class="tab">{{item.type}}</div>
                      </div>
                    </td>
                    <td>
                      <div class="tab">{{ item?.yearTotal}} kg</div>
                    </td>
                    <td>
                      <div class="tab">{{ item?.monthlyEverage }} kg</div>
                    </td>
                    <td>
                      <div class="number-tab">{{ item?.lotsNumber}}</div>
                    </td>
                    <td class=" d-flex align-items-center">
                      <div *ngFor="let tred of item.productionPeaks" class=" d-flex">
                        <div *ngIf="tred.trend == 'up'">
                          <img src="../../../assets/icon/trend-up.svg" alt="">
                        </div>
                        <div *ngIf="tred.trend == 'down'">
                          <img src="../../../assets/icon/trend-down.svg" alt="">
                        </div>
                        <div class="tab">{{tred.month}}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->

              <div *ngIf="showWidget" class="table-cont">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="font-title" *ngFor="let col of cols">{{col}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let item of tableData; let i = index">
                      <tr>
                        <td class="text-style-big padd">
                          <div class="d-flex align-items-center">
                            <div class="icon-tab">
                              <img [src]="item.icon" alt="">
                            </div>
                            <div class="tab-item">{{item.type}}</div>
                          </div>
                        </td>
                        <td>
                          <div class="tab-item">{{ item?.yearTotal }} kg</div>
                        </td>
                        <td>
                          <div class="tab-item">{{ item?.monthlyAverage }} kg</div>
                        </td>
                        <td>
                          <div class="d-flex">
                            <div class="number-tab">{{ item?.batchsNumber }}</div>
                            <div class="expand-tab" (click)="toggleRow(i)">
                              <img src="../../../assets/icon/arrow-down-icon.svg" alt="">
                            </div>
                          </div>

                        </td>
                        <td class="d-flex align-items-center">
                          <div *ngFor="let tred of item.productionPeaks" class="d-flex">
                            <div *ngIf="tred.trend === 'up'">
                              <img src="../../../assets/icon/trend-up.svg" alt="">
                            </div>
                            <div *ngIf="tred.trend === 'down'">
                              <img src="../../../assets/icon/trend-down.svg" alt="">
                            </div>
                            <div class="tab-item">{{ tred.month }}</div>
                          </div>
                        </td>

                      </tr>
                      <ng-container *ngIf="selectedRowIndex === i">
                        <tr *ngFor="let details of item.details; let i = index">
                          <td class="">
                            <div class="icon-tab detail-item">
                              {{details.batch}}
                            </div>
                          </td>
                          <td class="tab-item">{{details.totYear}}</td>
                          <td class="tab-item">{{details.totMonth}}</td>
                          <td class="tab-item"></td>
                          <td class="tab-item"></td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Template for "Storico metereologico" -->
    <div *ngSwitchCase="2">
      <app-historical-weather [municipalityId]="municipality_id" [municipalityLabel]="municipality_desc"
        [batchOptions]="batches">
      </app-historical-weather>
    </div>

    <!-- Template for "Prezzi di mercato" -->
    <div *ngSwitchCase="3">
      <app-statistics-market-prices [municipalityId]="municipality_id"
        [municipalityLabel]="municipality_desc"></app-statistics-market-prices>
    </div>
  </div>