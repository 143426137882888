import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from '../helpers/interceptor.service';
import { Credentials } from '../models/credentials.model';
import { FormattedResponse } from '../models/formatted-response';
import { HttpContextConfig } from '../models/http-context-config';
import { User } from '../models/user.model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  currentUser: BehaviorSubject<User | any> = new BehaviorSubject(null);


  generateVerificationCode(email: string) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/mail/code/generate`, { email: email });
  }

  login(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/login`, credentials);
  }

  logout(params?: any) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/logout`, { params });
  }

  me(params?: any) {
    return this.http.post<FormattedResponse<User>>(`${environment.api}/me`, { params });
  }

  forgottenPassword(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/forgotten_password`, { params });
  }

  resetPassword(id: string): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/reset-password/${id}`);
  }

  generateCode(email: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/public/recover/generateCode`, { params: { email } });
  }

  confirmCode(email: string, code: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/public/recover/confirmCode`, { params: { email, code } });
  }

  passwordRecovery(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/recover/password`, credentials);
  }

  updatePassword(params: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/update-password`, params);
  }

  getUserEmail(): string {
    return localStorage.getItem('email') ?? localStorage.getItem('emailPasswordRecovery')!;
  }

  setUserEmail(email: string) {
    localStorage.setItem('emailPasswordRecovery', email);
  }

  getCurrentUser(user: User) {
    this.currentUser.next(user);
  }

  getUser(isInitializer = false): Observable<FormattedResponse<User>> {
    let config = new HttpContextConfig();
    config.isInitializer = isInitializer;

    const context = new HttpContext()
      .set(CONFIG, config);

    return this.http.post<FormattedResponse<User>>(`${environment.api}/me`, {}, { context });
  }


}
