<div *ngIf="showWidget" class="main-cont">
    <div class="d-flex justify-content-between ">
        <div>
            <h4 class="header-widget">Ultime Attività</h4>
        </div>
        <div (click)="explodeWidget()" *ngIf="is_dashboard">
            <img src="/assets/img/arrow.svg" alt="" class="cursor-pointer">
        </div>
    </div>

    <div class="my-3 d-flex align-items-center" style="flex-grow: 1;"
        *ngFor="let report of (reportsFMS | async)?.slice(0, 4); let i=index">
        <ng-container *ngIf="i < 4">
            <div class="col-2 pe-0 d-flex justify-content-center align-items-center">
                <img [src]="getCategoryImage(report.product.category)">
            </div>
            <div class="col-5">
                <div class="d-flex flex-column justify-content-start px-0">
                    <div class="fs-12">{{report.date | date: 'dd/MM/YYYY'}}</div>
                    <div class="fw-500 fs-16">{{report.product.name}}</div>
                    <div class="fs-14">{{(report.product.batch?.batch_unique_identifier ?
                        report.product.batch.batch_unique_identifier : 'Nessun lotto')}}</div>
                </div>
            </div>
            <div class="fs-14 d-flex justify-content-center px-0"
                [ngClass]="{'col-3': is_dashboard, 'col-5': !is_dashboard}">
                <div [class]="getActivityClass(getFirstWord(report.activity))" class="box">
                    <!-- {{ getActivityClass(report.activity) }} -->
                    {{ getFirstWord(report.activity) }}
                </div>
            </div>
            <div class="col-2 fs-14 text-g d-flex justify-content-center" *ngIf="is_dashboard">
                {{report.amount}} {{ getUnitOfMeasure(report.activity) }}
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="!showWidget">
    <h1>No data</h1>
</div>