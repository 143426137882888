<div class="statistics-market-prices">
  <p *ngIf="municipalityLabel"  class="top-field"> Comune :
    <strong>
      {{ municipalityLabel }}
    </strong>
  </p>

  <div *ngIf="municipalityId" class="white-bordered-container">

    <div class="d-flex header-graph align-items-center justify-content-between">
      <div>
        <h4 class="header-graph-title">Prezzi di mercato</h4>
      </div>
      <div class="d-flex align-items-center">
        <div class="span-filter-container">
          <span class="span-filter"> <img src="../../../assets/icon/Ellipse 2.svg" alt="arancio chiaro"> arance </span>
          <span> <img src="../../../assets/icon/Ellipse 1.svg" alt="giallino chiaro"> limoni </span>
        </div>
        <form [formGroup]="filtersForm">
          <div class="d-flex">
            <nz-select id="left-select-month" class="custom-select col-4 " formControlName="month" nzPlaceHolder="Mese">
              <nz-option *ngFor="let month of months" [nzLabel]="month.label" [nzValue]="month.value"></nz-option>
            </nz-select>

            <nz-select class="custom-select col-4" formControlName="year" [nzPlaceHolder]="selectedYear">
              <nz-option *ngFor="let year of years" [nzLabel]="year.label" [nzValue]="year.value"></nz-option>
            </nz-select>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="showWidget" class="d-flex justify-content-center market-graphic">
      <canvas #baseChars baseChart height="400" width="1100" [type]="'line'" [legend]="marketPriceLegend"
        [data]="lineChartData">
      </canvas>
    </div>

    <section class="selected-month">
      <div class="title-selected-month">
        <p> {{ selectedMonthLabel }} </p>
      </div>

      <div>
        <form [formGroup]="filtersForm">
          <input type="hidden" formControlName="category">
          <div class="filter-buttons">
            <div *ngFor="let filter of filterOptions" class="filter-button">
              <button type="button" (click)="selectFilter(filter)"
                [ngClass]="{'active-filter': filter.active, 'inactive-filter': !filter.active}">
                <img *ngIf="filter.image" [src]="'../../../../../assets/icon/' + filter.image + '.svg'"
                  alt="{{filter.label}}">
                {{filter.label}}
              </button>
            </div>
          </div>
        </form>
      </div>

      <!-- qua va messa la table -->
      <div class="table-responsive">
        <table class="table custom-table">
          <thead>
            <tr>
              <th [ngClass]="{'th-border': fillMarketData}" *ngFor="let column of columns">{{ column }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of marketData">
              <td>{{ translateProductName(item.name) }}</td>
              <td>{{ item.avg_price | number: '1.2-2' }} €</td>
              <td>{{ item.max_price | number: '1.2-2' }} €</td>
              <td>{{ item.min_price | number: '1.2-2' }} €</td>
            </tr>
          </tbody>
        </table>
      </div>

    </section>
  </div>
</div>