import { Component } from '@angular/core';

@Component({
  selector: 'app-production-trend',
  templateUrl: './production-trend.component.html',
  styleUrls: ['./production-trend.component.scss']
})
export class ProductionTrendComponent {

}
