import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NonNullableFormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BreakpointObserver , Breakpoints , BreakpointState } from '@angular/cdk/layout';
import { toast } from 'src/app/app.component';
import Swal from 'sweetalert2';
import { LoggingService } from 'src/app/components/Logging.service';
// import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form!: FormGroup;
  layoutType?: string;
  containerLg?: boolean;
  containersm?: boolean;
  access_token?: any;
  passwordVisible: boolean = false;
  password?: string;
  // sessionID!: any;

  constructor(
    private router: Router, 
    private fb: FormBuilder, 
    private breakpointObserver: BreakpointObserver, 
    private _authService: AuthService,
    private activated: ActivatedRoute,
    private _loggingService: LoggingService
    ) { }

  ngOnInit() {    
    if (this.activated.snapshot.url[1]) {
      
      Swal.fire({
        title: "Mail arrivata?",
        text: "Controlla la tua casella di posta e inserisci la nuova password",
        icon: "question"
      });
    }
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
    ]).subscribe(result => {
      this.layoutType = this.determineLayoutType(result);
    });

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  determineLayoutType(result: BreakpointState): string {
    switch (true) {
      case result.breakpoints[Breakpoints.XSmall]:
        this.containerLg = false
        this.containersm = true
        return 'mobile';
      case result.breakpoints[Breakpoints.Small] || result.breakpoints[Breakpoints.Medium]:
        return 'tablet';
      default:
        this.containersm = false
        this.containerLg = true        
        return 'desktop';
    }
  }
 
  submitForm() {
    this._authService.login(this.form.value).subscribe(r => {
      // console.log('LOGIN DATA',r);
      
      this.access_token = r.access_token
      this._authService.currentUser.next(r.user);
      const sessionId = String(r.session_id);
      localStorage.setItem('session_id', sessionId);
      localStorage.setItem('role_id', r.role_id);
      const token = this.access_token;
      this.setCookie('access_token', token, 100)
      if (r.role_id == 1) {
        this.router.navigate(['admin/user-management']);
      } else {

        const browserInfo = {
          name: navigator.appName,
          version: navigator.appVersion
        };
        sessionStorage.setItem('browserInfo', JSON.stringify(browserInfo));
        
        // Memorizza la grandezza dello schermo nel sessionStorage
        const screenSize = {
          width: window.innerWidth,
          height: window.innerHeight
        };
        sessionStorage.setItem('screenSize', JSON.stringify(screenSize));

        const storedBrowserInfo = sessionStorage.getItem('browserInfo');
        if (storedBrowserInfo) {
          const browserInfo = JSON.parse(storedBrowserInfo);
        } else {
          console.log('Nessuna informazione sul browser memorizzata.');
        }

        // Recupera la grandezza dello schermo dal sessionStorage
        const storedScreenSize = sessionStorage.getItem('screenSize');
        if (storedScreenSize) {
          const screenSize = JSON.parse(storedScreenSize);
        } else {
          console.log('Nessuna informazione sulla grandezza dello schermo memorizzata.');
        }
        
        const data = {
          session_id : sessionId,
          browser_data: {
            brawserName: browserInfo.name,
            brawserVersion: browserInfo.version,
            screenWidth: screenSize.width,
            screenHeight: screenSize.height
          }
        }

        this._loggingService.interacionEngineSessionData(data).subscribe(r =>{
          this.router.navigate(['app/dashboard']);
          // console.log(this._loggingService.sessionId);
          
        })


        
      }
    },
    error => {
      
      toast.fire({
        title: 'Respinto',
        text: error.error,
        icon: 'warning'
      })
    }
    )
    
  }

  private setCookie(name: string, value: string, expireDays: number) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + expireDays);
    const cookieValue = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';expires=' + expireDate.toUTCString() + ';path=/';
    document.cookie = cookieValue;
  }

  toCreate() {
    this.router.navigate(['auth/register']);
  }

}
