<div *ngIf="currentTemplate === 'plants'" class="my-plants-tab">
  <div class="d-flex justify-content-between align-items-center">
    <div class="plants-title">Le mie piantagioni </div>
    <div *ngIf="!giBack" class="add-plant" (mouseup)="goTOAddPlant()">Aggiungi piantagione</div>
  </div>
  <div *ngIf="giBack" class="go-back d-flex" (mouseup)="goBack()">
    <div class="arrow">
      <img src="../../../assets/icon/arrow-left-green.svg" alt="">
    </div>
    <div class="back-text">Indietro</div>
  </div>
  <div class="plant-cards">
    <div *ngFor="let plant of plants" (mouseup)="showFormMyPlants(plant)"
      [ngClass]="{'selected-card': plant.selected}">
      <div class="card-plant" [ngClass]="{'selected-card-width': plant.selected, 'card-width ': !plant.selected}">
        <div class="default" *ngIf="plant.default">
          Default
        </div>
        <div class="plant-name">{{ plant.name }}</div>
      </div>
    </div>
  </div>
  <!-- MODIFICA PLANT -->
  <div *ngIf="showFormPlants" id="editForm">
    <app-mySystemTransformer [roleId]="2" [plantId]="plantId" [type]="'mod-plant'"
      (variabileOutput)="riceviDalFiglio($event)"></app-mySystemTransformer>
  </div>
</div>
<!-- AGGIUNGI PLANT -->
<div *ngIf="currentTemplate === 'add-plant-form'">
  <app-mySystemTransformer [roleId]="2" [type]="'plant'"
    (variabileOutput)="riceviDalFiglio($event)"></app-mySystemTransformer>
</div>

<div *ngIf="currentTemplate === 'template2'">
  <div>Template 2 Content</div>
</div>
