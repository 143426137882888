import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-widget-water-transformer',
  templateUrl: './widget-water-transformer.component.html',
  styleUrls: ['./widget-water-transformer.component.scss']
})
export class WidgetWaterTransformerComponent implements OnInit {

  selectedField!: string;
  prov: any;
  waterLiters: any;
  placeHolder: string = 'Seleziona una provincia';

  constructor(
    private _chartService: ChartService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils: Utils
  ) { }

  ngOnInit() {
    this.getTransformerMunicipality()
  }

  getData() {
    this.getTransformerWaterData()

  }

  getTransformerMunicipality() {
    this._chartService.getTransformerMunicipality().subscribe(r => {
      if (r.data.length > 0) {
        this.prov = r.data;
      this.selectedField = r.data[0]?.id
      this.getTransformerWaterData()
      }else{
        this.placeHolder = 'Nessun dato';
      }


    })
  }

  getTransformerWaterData() {
    this._chartService.getTransformerWaterData(this.selectedField).subscribe(r => {
      this.waterLiters = r.data?.water_liters

    })
  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 13);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-water-transformer'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);

    })
  }

}
