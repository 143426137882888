import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { IClickExplodeWidgetRequest } from 'src/app/components/sidebar/Interfaces/IClickExplodeWidgetRequest';
import { ComponentService } from 'src/app/components/component.service';
import { AuthService } from '../services/auth.service';
import { IWidget } from 'src/app/components/sidebar/Interfaces/IWidget';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  dev: boolean = false; // da dinamizzare per capire se siamo in dev o altri ambienti

  widgets = [
    { id: 1, name: 'piantagioni', value: 'app-doughnut-and-pie-chart' },
    { id: 2, name: 'report fms', value: 'app_report_fms' },
    { id: 3, name: 'alert fms', value: 'app_alert_fms' },
    { id: 4, name: 'prezzi di mercato', value: 'app-line-chart' },
    { id: 5, name: 'meteo', value: 'app_meteo' },
    { id: 6, name: 'suolo', value: 'app-sun-chart' },
    { id: 7, name: 'acqua', value: 'app-water-chart' },
    { id: 8, name: 'andamento produzione', value: 'app-bar-chart' },
    { id: 9, name: 'feedback', value: 'feedback' },
    {
      id: 10,
      name: 'ultime trasformazioni',
      value: 'app-widget-latest-transformations',
    },
    { id: 11, name: 'quantità scartata', value: 'app-quantity-rejected' },
    { id: 12, name: 'energia', value: 'app-consumed-energy' },
    { id: 13, name: 'acqua', value: 'app-widget-water-transformer' },
    { id: 14, name: 'magazzino', value: 'app-widget-in-stock' },
    {
      id: 15,
      name: 'prodotti in arrivo',
      value: 'app-widget-products-coming-soon',
    },
    { id: 16, name: 'feedback', value: 'feedback' },
    {
      id: 17,
      name: 'prodotti in arrivo / partenza',
      value: 'app-widget-arriving-departing-products',
    },
    { id: 18, name: 'ultima spedizione', value: 'app-latestShipments' },
    { id: 19, name: 'ultima spedizione', value: 'app-latestShipments' },
    { id: 20, name: 'feedback', value: 'feedback' },
    { id: 21, name: 'I tuoi lotti', value: 'app-distributor-lots' },
    { id: 22, name: 'Spedizione in corso', value: 'app-shipping-status' },
    { id: 23, name: 'I tuoi trattamenti', value: 'app-distributor-lots' },
    { id: 24, name: 'Stato vendite', value: 'app-SalesStatus' },
    { id: 25, name: 'Scarto del giorno', value: 'app-DiscardOfTheDay' },
    { id: 26, name: 'Più venduto', value: 'app-bestSellingProduct' },
    { id: 27, name: 'Prodotti in vendita', value: 'app-ProductsForSale' },
    { id: 28, name: 'Tempi di vendita', value: 'app-SalesTimes' },
    { id: 29, name: 'In magazzino', value: 'app-SellerInStock' },
    { id: 30, name: 'In arrivo', value: 'app-sellerUpcomingProducts' },
    { id: 31, name: 'feedback', value: 'feedback' },
    { id: 32, name: 'I tuoi Prodotti', value: 'app-distributor-lots' },
    { id: 33, name: 'ultima spedizione', value: 'app-latestShipments' },
    { id: 34, name: 'Aggiungi report', value: 'app-addReport' },
    { id: 35, name: 'ultima spedizione', value: 'app-latestShipments' },
  ];

  constructor(
    private router: Router,
    private _componentsService: ComponentService,
    private _authService: AuthService
  ) { }

  /**
   * Funzione per gestire il click del widget e inviare la chiamata
   * @param route - La route su cui navigare dopo la chiamata
   * @param widget_id - L'id del widget selezionato
   */
  handleWidgetClick(route?: string | null, widget_id?: number) {
    const device_type = this.getEnhancedDeviceType();
    const browserInfo = this.getBrowserInfo();
    const resolution = this.getScreenResolution();
    const zoomLevel = this.getZoomLevel();
    const darkMode = this.isDarkMode();
    const language = this.getLanguage();
    const routeToSend = route ?? this.router.url;
    const widget = this.getComponentWidgetMatch(routeToSend);
    const time_now = new Date().toISOString();

    const env = this.dev
      ? 'https://dev-citrace.darwintech.it'
      : 'localhost:4200';
    this._authService.me().subscribe((response) => {
      const dataToSend: IClickExplodeWidgetRequest = {
        user: {
          user_id: response?.data?.id ? String(response.data.id) : '0c91c753-661e-4c4a-95c4-7b33003e5f2a',
          role: response?.data?.role?.name ?? 'Trasformatore',
        },
        device: {
          device_type: device_type,
          browser: browserInfo.browser,
          browser_version: browserInfo.browser_version,
          resolution: resolution,
          zoom: zoomLevel,
          dark_mode: darkMode,
        },
        session_id:
          localStorage.getItem('session_id') ??
          '0c91c753-661e-4c4a-95c4-7b33003e5f2d',
        page_url: env + routeToSend,
        component: widget?.value,
        element: widget?.name,
        selector: widget?.value,
        widget_id: widget_id ?? 1,
        timestamp: time_now,
        language: language,
      };
      this._componentsService
        .postClickExplodeWidget(dataToSend)
        .subscribe((response) => {
          console.log(response);
        });
    });
  }

  /**
   * Naviga alla nuova route dopo la chiamata API
   * @param route - La route su cui navigare
   */
  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  /**
   * Determina il tipo di dispositivo dell'utente basato solo sulla larghezza dello schermo
   * @returns {string} - Il tipo di dispositivo ('mobile', 'tablet', 'laptop', 'desktop')
   */
  getDeviceType(): string {
    const width = window.innerWidth;

    if (width <= 768) {
      return 'mobile';
    } else if (width > 768 && width <= 1024) {
      return 'tablet';
    } else if (width > 1024 && width <= 1600) {
      return 'laptop';
    } else {
      return 'desktop';
    }
  }

  /**
   * Determina il tipo di dispositivo dell'utente basato su larghezza dello schermo, supporto touch e user agent
   * @returns {string} - Il tipo di dispositivo ('mobile', 'tablet', 'laptop', 'desktop')
   */
  getEnhancedDeviceType(): string {
    const width = window.innerWidth;
    const userAgent = navigator.userAgent.toLowerCase();
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    if (width <= 768 || userAgent.includes('mobile')) {
      return 'mobile';
    } else if (
      (width > 768 && width <= 1024) ||
      userAgent.includes('tablet') ||
      isTouch
    ) {
      return 'tablet';
    } else if (width > 1024 && width <= 1600 && !isTouch) {
      return 'laptop';
    } else {
      return 'desktop';
    }
  }

  /**
   * Recupera le informazioni sul browser dell'utente
   * @returns {Object} - Oggetto con le proprietà 'browser' e 'browser_version'
   */
  getBrowserInfo() {
    const userAgent = navigator.userAgent;
    let browser = 'Unknown';
    let version = 'Unknown';

    // Updated regex patterns to avoid excessive backtracking
    const chromeRegex = /\b(?:Chrome|CriOS)\/(\d+)\b/;
    const firefoxRegex = /\b(?:Firefox|FxiOS)\/(\d+)\b/;
    const safariRegex = /\bVersion\/(\d+)\b.*\bSafari\b/;
    const ieRegex = /\b(?:MSIE |rv:)(\d+)\b/;

    let match;

    match = chromeRegex.exec(userAgent);
    if (match) {
      browser = 'Chrome';
      version = match[1];
    } else {
      match = firefoxRegex.exec(userAgent);
      if (match) {
        browser = 'Firefox';
        version = match[1];
      } else {
        match = safariRegex.exec(userAgent);
        if (match) {
          browser = 'Safari';
          version = match[1];
        } else {
          match = ieRegex.exec(userAgent);
          if (match) {
            browser = 'Internet Explorer';
            version = match[1];
          }
        }
      }
    }
    return { browser, browser_version: version };
  }


  /**
   * Recupera la risoluzione dello schermo dell'utente
   * @returns {string} - La risoluzione dello schermo nel formato 'width x height'
   */
  getScreenResolution(): string {
    return `${window.screen.width}x${window.screen.height}`;
  }

  /**
   * Recupera il livello di zoom del browser dell'utente
   * @returns {number} - Il livello di zoom del browser
   */
  getZoomLevel(): number {
    return window.devicePixelRatio || 1;
  }

  /**
   * Verifica se l'utente preferisce la modalità scura
   * @returns {boolean} - `true` se la modalità scura è preferita, altrimenti `false`
   */
  isDarkMode(): boolean {
    return (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    );
  }

  /**
   * Recupera la lingua preferita del browser dell'utente
   * @returns {string} - La lingua preferita del browser
   */
  getLanguage(): string {
    return navigator.language || 'en';
  }

  /**
   * Recupera il componente associato a un percorso specifico
   * @param {string} routePath - Il percorso della route da cercare
   * @returns {any} - Il componente associato al percorso o `null` se non trovato
   */
  getComponentForRoute(routePath: string): any {
    const routes: Routes = this.router.config;
    const matchingRoute = routes.find((route) => route.path === routePath);

    return matchingRoute?.component || null;
  }

  /**
   * Recupera il nome del componente associato a un percorso specifico
   * @param {string} [routePath] - Il percorso della route da cercare. Se non fornito, usa il percorso corrente.
   * @returns {string | null} - Il nome del componente associato al percorso o `null` se non trovato
   */
  getComponentForRouteStringReturn(routePath?: string): string | null {
    const parts: string[] = routePath?.split('/') || [];
    const routeKey = parts.length > 1 ? parts[1] : parts[0];
    const routes: Routes = this.router.config;
    const matchingRoute = routes.find((route) => route.path === routeKey);

    return matchingRoute?.component?.name || null;
  }

  /**
   * Recupera il valore del widget associato a un percorso specifico
   * @param {string} [routePath] - Il percorso della route da cercare. Se non fornito, usa il percorso corrente.
   * @returns {string | null} - Il valore del widget associato al percorso o `null` se non trovato
   */
  getComponentWidgetMatch(routePath?: string): IWidget | null {
    const parts: string[] = routePath ? routePath.split('/') : [];
    const routeKey = parts.length > 1 ? parts[1] : parts[0];
    const matchingWidget = this.widgets.find(
      (widget) => widget.value === routeKey
    );

    return matchingWidget || null;
  }
}
