import { Component } from '@angular/core';

@Component({
  selector: 'app-market-prices',
  templateUrl: './market-prices.component.html',
  styleUrls: ['./market-prices.component.scss']
})
export class MarketPricesComponent {

}
