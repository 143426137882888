<div class="prodotti-container">
  <div class="d-flex justify-content-between ">
    <div>
      <h4 class="header-widget">Prodotti in arrivo</h4>
    </div>

    <!-- <div class="select-field">
      <nz-select nzPlaceHolder="Seleziona una provincia" [(ngModel)]="selectedField" (ngModelChange)="getData()">
        <nz-option *ngFor="let p of prov" [nzLabel]="p.label" [nzValue]="p.id" class="color">
          {{p}}</nz-option>
      </nz-select>
    </div> -->

    <span style="cursor: pointer;" (click)="explodeWidget()">
      <img src="/assets/img/arrow.svg" alt="">
    </span>
  </div>

  <div *ngIf="showWidget" class="list-container">
    <div class="row list-header">
      <div class="col-2">Lotto</div>
      <div class="col-2">Data</div>
      <div class="col-2">Produttore</div>
      <div class="col-2">Prodotto</div>
      <div class="col-2">Tipologia</div>
      <div class="col-2">Quantitá</div>
    </div>

    <div class="list-item" *ngFor="let item of list; let i=index">
      <div class="row cont" *ngIf="i < 5">
        <div class="col-2 lotto">{{ item.lotto ? item.lotto : 'Nessun dato' }}</div>
        <div class="col-2 data">{{ item.data ? item.data : 'Nessun dato' }}</div>
        <div class="col-2 productor d-flex flex-wrap">{{ item.productor ? item.productor : 'Nessun dato' }}</div>
        <div class="col-2 img">
          <img src="{{ item.img ? item.img : '' }}" alt="">
        </div>
        <div class="col-2 type d-flex flex-wrap">{{ item.type ? item.type : 'Nessun dato' }}</div>
        <div class="col-2 quantity">{{ item.quantity ? item.quantity : 0 }} kg</div>
        <!-- <div class="col-3 days">{{ item.days }}</div> -->
      </div>
    </div>
  </div>

  <div *ngIf="!showWidget">
    <h1>{{noData}}</h1>
  </div>

</div>