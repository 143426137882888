import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { ChartService } from '../charts/chart.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ComponentService } from '../component.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  @Input() municipality_id: number | null = null;
  @Input() municipality_desc: string | null = null;
  @Input() tab: number | null = 1;
  private unsubscribe$ = new Subject<void>();

  tabs = [
    { tab: 'Andamento produzione', id: 1, selected: true },
    { tab: 'Storico metereologico', id: 2, selected: false },
    { tab: 'Prezzi di mercato', id: 3, selected: false },
  ];

  cols = [
    'Prodotto',
    'Totale annuo',
    'Media mensile',
    'Numero lotti',
    'Picchi di produzione',
  ];
  colsDetails = ['Prodotto', 'Totale annuo', 'Media mensile'];

  productsType = [
    {
      id: 0,
      // path: '../../../assets/icon/modal-orange-icon.svg',
      label: 'Tutti',
      selected: true,
    },
    {
      id: 1,
      path: '../../../assets/icon/modal-orange-icon.svg',
      label: 'Arance',
      selected: false,
    },
    {
      id: 2,
      path: '../../../assets/icon/modal-lemon-icon.svg',
      label: 'Limoni',
      selected: false,
    },
  ];

  tableData: any;

  productionTrendCards: any;

  selectedRowIndex: number | null = null;
  fields: any;
  selectedFieldForWeather: any;
  selectedFieldForMarketPrices: any;
  showWidget: boolean = false;
  batches: any;

  selectedTab: number = 1;
  selectedField: any = null;
  selectedYear: number | null = null;
  municipalities: any = [];
  municipalities_market: any = [];
  lastThreeYears: number[] = [];
  type: number = 0;
  isEmpty: boolean = false;

  constructor(
    private _chartService: ChartService,
    private _componentService: ComponentService,
    private _route: ActivatedRoute
  ) {

    if (this.municipality_id == null &&
      this.municipality_desc == null) {
      this._chartService
          .getMarketMunicipality().subscribe(r => {
            this.municipality_id = r.data[0].id;
            this.municipality_desc = r.data[0].label;
          })
    }

    this._route.queryParams.subscribe((params) => {
      this.selectedTab = Number(+params['tab']) || 1;
      this.selectTab(this.selectedTab);

      this.municipality_id = params['municipality_id'] != null ? +params['municipality_id'] : null;
      console.log('CIAOOO 2', this.municipality_id);
      this.municipality_desc = params['municipality_desc'] ?? null;
      if (
        this.selectedTab === 2 &&
        this.municipality_id != null &&
        this.municipality_desc != null
      ) {
        this.selectedFieldForWeather = {
          id: this.municipality_id,
          label: this.municipality_desc,
        };

        this._componentService
          .getMunicipality()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((response) => {
            this.municipalities = response.data;
            this.filterDataField();
          });
      }
      console.log('CIAOOO', this.municipality_id);
      if (
        this.selectedTab === 3 &&
        this.municipality_id != null &&
        this.municipality_desc != null
      ) {
        console.log('CIAOOO', this.municipality_id);
        this.selectedFieldForMarketPrices = {
          id: this.municipality_id,
          label: this.municipality_desc,
        };

        this._chartService
          .getMarketMunicipality()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((response) => {
            this.municipalities_market = response.data;
            this.filterDataField();
          });
      }
      // else if (this.selectedTab === 3 && this.municipality_id == null &&
      //   this.municipality_desc == null) {
      //     this._chartService
      //     .getMarketMunicipality().subscribe(r => {
      //       this.municipality_id = r.data[0].id;
      //       this.municipality_desc = r.data[0].label;
      //     })
      // }
    });
  }

  ngOnInit() {
    this.getYears();
    this.getMyPlante();
    this.getMarketMunicipalities();
    this.selectedTab = 1;
    this.selectedField = null;
    this.getProductionTrendData();
    this.trendProduction();
    this._route.queryParams.subscribe((params) => {
      this.selectedTab = +params['tab'] || 1;
      this.municipality_id = params['municipality_id'] || null;
      this.municipality_desc = params['municipality_desc'] || null;
    });
    this.getMyMunicipalities();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMyPlante() {
    this._componentService.getMyPlante().subscribe((r) => {
      this.fields = r.data;
      // this.selectedField = this.fields[0]
    });
  }

  getYears() {
    const currentYear = new Date().getFullYear();
    this.lastThreeYears = [currentYear, currentYear - 1, currentYear - 2];
    this.selectedYear = this.lastThreeYears[0];
  }

  selectTab(id: number): void {
    let check = this.tabs.find((p) => p.id === id)?.selected;
    if (check) return

    this.selectedField = null;
    this.selectedFieldForWeather = null;
    this.selectedFieldForMarketPrices = null;

    this.tabs.forEach((p) => (p.selected = false));
    const tab = this.tabs.find((p) => p.id === id);
    if (tab) {
      tab.selected = true;
      this.selectedTab = tab.id;
      switch (this.selectedTab) {
        case 1:
          this.getProductionTrendData();
          this.trendProduction();
          break;

        case 2:
          this.getMyBatches();
          break;

        default:
          break;
      }
    }
  }

  selectProductType(id: number): void {
    this.productsType.forEach((p) => (p.selected = false));
    const product = this.productsType.find((p) => p.id === id);
    if (product) {
      product.selected = true;
      this.type = product.id;
      this.trendProduction();
    }
  }

  // ANDAMENTO PRODUZIONE
  public barChartData: ChartData<'bar'> = {
    labels: [
    ],
    datasets: [
      {
        label: 'Arance',
        data: [],
        backgroundColor: 'rgba(236, 160, 37, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 25,
      },
      {
        label: 'Limoni',
        data: [],
        backgroundColor: 'rgba(255, 216, 156, 0.8)',
        borderColor: 'rgba(255, 216, 156, 0.8)',
        borderWidth: 1,
        borderRadius: 25,
      },
    ],
  };
  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    // indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        // align: 'end',
        labels: {
          usePointStyle: true,
        },
      },
    },
  };
  public barChartType: any = 'bar';
  public barChartLegend = true;

  filterDataField() {
    console.log('qua');

    switch (this.selectedTab) {
      case 1:
        console.log('case 1');

        this.getProductionTrendData();
        this.trendProduction();
        break;
      case 2:
        console.log('case 2');

        this.handleMunicipalitySelection();
        this.getMyBatches();
        break;
      case 3:
        console.log('case 3');

        this.handleMunicipalityMarketSelection();
        break;
    }
  }

  handleMunicipalitySelection() {
    const selectedMunicipality = this.municipalities.find(
      (municipality: any) =>
        municipality.id ===
        Number(this.selectedFieldForWeather.id ?? this.selectedFieldForWeather)
    );

    this.updateMunicipalityDesc(selectedMunicipality);
    this.updateMunicipalityId(selectedMunicipality);
  }

  updateMunicipalityDesc(selectedMunicipality: any) {
    if (
      this.municipality_desc === null ||
      (this.municipality_desc != null && this.municipality_desc != selectedMunicipality?.label)
    ) {
      this.municipality_desc = selectedMunicipality ? selectedMunicipality.label : null;
    }
  }

  updateMunicipalityId(selectedMunicipality: any) {
    if (
      this.municipality_id === null ||
      (this.municipality_id != null && this.municipality_id != selectedMunicipality?.id)
    ) {
      this.municipality_id = selectedMunicipality ? Number(selectedMunicipality.id) : null;
    }
  }

  handleMunicipalityMarketSelection() {
    const selectedMunicipality = this.municipalities_market.find(
      (municipality: any) =>
        municipality.id ===
        Number(this.selectedFieldForMarketPrices.id ?? this.selectedFieldForMarketPrices)
    );
    this.updateMunicipalityDesc(selectedMunicipality);
    this.updateMunicipalityId(selectedMunicipality);
  }


  toggleRow(index: number) {
    this.selectedRowIndex = this.selectedRowIndex === index ? null : index;
  }

  getProductionTrendData() {
    this.showWidget = false;

    let field;
    if (this.selectedField) {
      field = this.selectedField.id;
    } else {
      field = null;
    }
    this._chartService
      .getproductionDataStatistics(this.selectedYear, field)
      .subscribe((r) => {
        this.isEmpty = r.data.isEmpty;
        if (!this.isEmpty) {
          this.barChartData.labels = r.data.widgetData.labels;
          this.barChartData.datasets[0].data =
            r.data.widgetData.datasets.data[0];
          this.barChartData.datasets[1].data =
            r.data.widgetData.datasets.data[1];
          this.productionTrendCards = r.data.cardData;
          this.showWidget = true;
        } else {
          this.barChartData.labels = ['Nessun dato'];
          this.barChartData.datasets[0].data = [0];
          this.barChartData.datasets[1].data = [0];
          this.productionTrendCards = r.data.cardData;
          this.showWidget = true;
        }
      });
  }

  getMyBatches() {
    this._componentService
      .getMyBatches(this.municipality_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  getMyMunicipalities() {
    this._componentService
      .getMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.municipalities = response.data;
      });
  }

  getMarketMunicipalities() {
    this._chartService.getMarketMunicipality().subscribe(r => {
      this.municipalities_market = r.data;
      this.selectedFieldForMarketPrices = {
        id: r.data[0].id,
        label: r.data[0].label,
      };
      console.log('MUN', this.selectedFieldForMarketPrices);

    });
  }

  filterForYear() {
    this.getProductionTrendData();
    this.trendProduction();
  }

  trendProduction() {
    let field;
    if (this.selectedField) {
      field = this.selectedField.id;
    } else {
      field = null;
    }
    this.showWidget = false;
    this._componentService
      .trendProduction(this.selectedYear, this.type, field)
      .subscribe((r) => {
        this.tableData = r.data;
        this.showWidget = true;
      });
  }

  compareFn = (o1: any, o2: any): boolean => {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  };

  resetTab() {
    this.selectedField = null;
    this.selectedFieldForWeather = null;
    this.selectedFieldForMarketPrices = null;
  }

  setupSubscriptions(): void {
    this._route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.selectedTab = +params['tab'] || 1;
      this.municipality_id = +params['municipality_id'] || null;
      this.municipality_desc = params['municipality_desc'] || null;
    });
  }
}
