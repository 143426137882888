<div [ngClass]="{'container-form-lg row': containerLg, 'container-form-sm': containersm}">

  <div class="col-6">
    <div class=" img-container">
      <img [src]="currentImg" alt="">
    </div>
  </div>

  <div class="col-6">

    <div class="steps-cont">

      <div class="mt-4 icon-container d-flex align-items-center">
        <img src="/assets/icon/logo-citrace-color-1.svg" alt="">
        <!-- <span class="ms-2 title-color fs-26">Ci<b>TRace</b></span> -->
      </div>

      <h3>Registrati</h3>

      <nz-steps [nzCurrent]="current">
        <nz-step *ngFor="let step of this.steps; trackBy: trackById" [nzTitle]="step.title"
          [nzPercentage]="step.async ? step.percentage : null"></nz-step>
      </nz-steps>

      <div class="form-cont" [ngSwitch]="stepsForm">
        <ng-container *ngSwitchCase=0 [ngTemplateOutlet]="first"></ng-container>
        <ng-container *ngSwitchCase=1 [ngTemplateOutlet]="second"></ng-container>
        <ng-container *ngSwitchCase=2 [ngTemplateOutlet]="third"></ng-container>
        <ng-container *ngSwitchDefault>
          <p>Layout non riconosciuto</p>
        </ng-container>
      </div>

      <div *ngIf="!isDone" class="steps-action">
        <button nz-button nzType="default" (click)="pre()" *ngIf="current > 0">
          <span>precedente</span>
        </button>
        <button nz-button nzType="default" [disabled]="!typeValue" (click)="next()" [nzLoading]="processing"
          *ngIf="current < 2">
          <span>Successivo</span>
        </button>
        <button nz-button nzType="primary" (click)="done()" [nzLoading]="processing" *ngIf="current === 2">
          <span>Registrati</span>
        </button>
      </div>
      <div class="login-cont">
        <span class="login">Hai già un account? </span>
        <span class="to-login" (click)="toLogin()">
          <a>Accedi</a>
        </span>
      </div>
    </div>

  </div>

</div>



<ng-template #first>
  <p class="caregory-p">A quale categoria appartieni?</p>
  <nz-radio-group [(ngModel)]="typeValue">
    <label nz-radio nzValue="consumatore">Sono un consumatore</label>
    <label nz-radio nzValue="to-next">Sono produttore</label>
  </nz-radio-group>
</ng-template>

<ng-template #second>
  <p class="caregory-p">A quale categoria appartieni?</p>
  <nz-radio-group [(ngModel)]="roleValue">
    <label nz-radio nzValue="produttore">Produttore</label>
    <label nz-radio nzValue="selezione e stoccaggio">Selezione e stoccaggio</label>
    <label nz-radio nzValue="distributore">Distributore</label>
    <label nz-radio nzValue="venditore">Punto vendita</label>
  </nz-radio-group>
</ng-template>

<ng-template #third>
  <p class="caregory-p">Inserisci i tuoi dati per completare la registrazione</p>

  <form nz-form [formGroup]="registerForm">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your username!">
            <input type="text" nz-input formControlName="name" placeholder="Nome" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-6 col-sm-12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your username!">
            <input type="text" nz-input formControlName="lastName" placeholder="Cognome" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-6 col-sm-12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Inserisci il nome della tua azienda">
            <input type="text" nz-input formControlName="company" placeholder="Nome azienda" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-6 col-sm-12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your username!">
            <input type="text" nz-input formControlName="vatNumber" placeholder="Partita IVA" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-6 col-sm-12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Inserisci  la tua mail">
            <input type="email" nz-input formControlName="email" placeholder="Email" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-6 col-sm-12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please confirm your Password!">
            <input type="password" nz-input formControlName="password" placeholder="Password" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-6 col-sm-12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please confirm your Password!">
            <input type="password" nz-input formControlName="confirmPassword" placeholder="Conferma Password"
              (ngModelChange)="updateConfirmValidator()" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="passwordMismatch">
          <div nz-form-explain>Please make sure the passwords match.</div>
        </nz-form-item>
      </div>
    </div>
  </form>

</ng-template>

































<!-- <nz-tabset>
  <nz-tab nzTitle="Consumatore" (nzClick)="buildForm('consumatore')">
    <div *ngIf="showConsForm">
      <form nz-form [formGroup]="registerForm" (ngSubmit)="submitForm()">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="Nome">Nome</nz-form-label>
              <nz-form-control nzErrorTip="Please input your username!">
                <input type="text" nz-input formControlName="firstName" placeholder="Nome" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="Cognome">Cognome</nz-form-label>
              <nz-form-control nzErrorTip="Please input your username!">
                <input type="text" nz-input formControlName="lastName" placeholder="Cognome" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="email">email</nz-form-label>
              <nz-form-control nzErrorTip="Inserisci  la tua mail">
                <input type="email" nz-input formControlName="email" placeholder="email" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="password">Password</nz-form-label>
              <nz-form-control nzErrorTip="Please confirm your Password!">
                <input type="password" nz-input formControlName="password" placeholder="Password" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="passwords">Confirm Password</nz-form-label>
              <nz-form-control nzErrorTip="Please confirm your Password!">
                <input type="password" nz-input formControlName="confirmPassword" placeholder="Confirm Password"
                  (ngModelChange)="updateConfirmValidator()" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="passwordMismatch">
              <div nz-form-explain>Please make sure the passwords match.</div>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Data di nascita</nz-form-label>
              <nz-form-control [nzSpan]="24">
                <nz-date-picker [nzFormat]="'dd/MM/YYYY'" class="col-12" formControlName="birthdate"
                  autocomplete="bday"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="vat">Partita iVA</nz-form-label>
              <nz-form-control nzErrorTip="Please input your username!">
                <input type="text" nz-input formControlName="vatNumber" placeholder="Partita iVA" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Luogo di nascita</nz-form-label>
              <nz-input-group>
                <input formControlName="birthplace" [nzAutocomplete]="teacherAutocomplete" class="search-input"
                  (input)="onInput($event, 'city')" nz-input placeholder="{{'Seleziona una città' }}" type="text" />
                <nz-autocomplete #teacherAutocomplete>
                  <nz-auto-option [nzDisabled]="true">
                    <div class="d-flex flex-row justify-content-center">
                      <nz-spin nzSimple></nz-spin>
                    </div>
                  </nz-auto-option>
                  <ng-container>
                    <nz-auto-option *ngFor="let worker of optionsCities" [nzLabel]="worker.user"
                      [nzValue]="worker.denominazione_in_italiano"
                      (click)="onInput = worker.denominazione_in_italiano">
                      {{ worker.denominazione_in_italiano }}
                    </nz-auto-option>
                  </ng-container>
                </nz-autocomplete>
              </nz-input-group>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Provincia</nz-form-label>
              <nz-input-group>
                <input formControlName="prov" [nzAutocomplete]="teacherAutocomplete" class="search-input"
                  (input)="onInput($event, 'prov')" nz-input placeholder="{{'Seleziona una provincia' }}"
                  type="text" />
                <nz-autocomplete #teacherAutocomplete>
                  <nz-auto-option [nzDisabled]="true">
                    <div class="d-flex flex-row justify-content-center">
                      <nz-spin nzSimple></nz-spin>
                    </div>
                  </nz-auto-option>
                  <ng-container>
                    <nz-auto-option *ngFor="let worker of optionsCities" [nzLabel]="worker.user"
                      [nzValue]="worker.denominazione_in_italiano"
                      (click)="onInput = worker.denominazione_in_italiano">
                      {{ worker.denominazione_in_italiano }}
                    </nz-auto-option>
                  </ng-container>
                </nz-autocomplete>
              </nz-input-group>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Via</nz-form-label>
              <nz-input-group>
                <input formControlName="via" [nzAutocomplete]="teacherAutocomplete" class="search-input"
                  (input)="onInput($event, 'via')" nz-input placeholder="{{'Seleziona una via' }}" type="text" />
                <nz-autocomplete #teacherAutocomplete>
                  <nz-auto-option [nzDisabled]="true">
                    <div class="d-flex flex-row justify-content-center">
                      <nz-spin nzSimple></nz-spin>
                    </div>
                  </nz-auto-option>
                  <ng-container>
                    <nz-auto-option *ngFor="let worker of optionsCities" [nzLabel]="worker.user"
                      [nzValue]="worker.denominazione_in_italiano"
                      (click)="onInput = worker.denominazione_in_italiano">
                      {{ worker.denominazione_in_italiano }}
                    </nz-auto-option>
                  </ng-container>
                </nz-autocomplete>
              </nz-input-group>
            </nz-form-item>
          </div>

          <div class="col-12">
            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Registrati</button>
          </div>

        </div>
      </form>
    </div>

  </nz-tab>

  <nz-tab nzTitle="Produttore" (nzClick)="buildForm('produttore')">
    <div *ngIf="showProdForm">
      <form nz-form [formGroup]="registerForm" (ngSubmit)="submitForm()">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="Nome">Nome</nz-form-label>
              <nz-form-control nzErrorTip="Inserisci un nome">
                <input type="text" nz-input formControlName="firstName" placeholder="Nome" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="Cognome">Cognome</nz-form-label>
              <nz-form-control nzErrorTip="Inserisci un cognome">
                <input type="text" nz-input formControlName="lastName" placeholder="Cognome" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="email">email</nz-form-label>
              <nz-form-control nzErrorTip="Inserisci una mail">
                <input type="email" nz-input formControlName="email" placeholder="email" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="password">Password</nz-form-label>
              <nz-form-control nzErrorTip="Inserisci una password">
                <input type="password" nz-input formControlName="password" placeholder="Password" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="password">Confirm Password</nz-form-label>
              <nz-form-control nzErrorTip="Conferma la password">
                <input type="password" nz-input formControlName="confirmPassword" placeholder="Confirm Password"
                  (ngModelChange)="updateConfirmValidator()" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="passwordMismatch">
              <div nz-form-explain>Please make sure the passwords match.</div>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="email">Inserisci nome azienda</nz-form-label>
              <nz-form-control nzErrorTip="Inserisci il nome della tua azienda">
                <input type="text" nz-input formControlName="company" placeholder="nome  azienda" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="vat">Partita iVA</nz-form-label>
              <nz-form-control nzErrorTip="Inserisci la tua partita IVA">
                <input type="text" nz-input formControlName="vatNumber" placeholder="Partita iVA" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Città</nz-form-label>
              <nz-input-group>
                <input formControlName="birthplace" [nzAutocomplete]="teacherAutocomplete" class="search-input"
                  (input)="onInput($event, 'city')" nz-input placeholder="{{'Seleziona una città' }}" type="text" />
                <nz-autocomplete #teacherAutocomplete>
                  <nz-auto-option [nzDisabled]="true">
                    <div class="d-flex flex-row justify-content-center">
                      <nz-spin nzSimple></nz-spin>
                    </div>
                  </nz-auto-option>
                  <ng-container>
                    <nz-auto-option *ngFor="let worker of optionsCities" [nzLabel]="worker.user"
                      [nzValue]="worker.denominazione_in_italiano"
                      (click)="onInput = worker.denominazione_in_italiano">
                      {{ worker.denominazione_in_italiano }}
                    </nz-auto-option>
                  </ng-container>
                </nz-autocomplete>
              </nz-input-group>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Provincia</nz-form-label>
              <nz-input-group>
                <input formControlName="prov" [nzAutocomplete]="teacherAutocomplete" class="search-input"
                  (input)="onInput($event, 'prov')" nz-input placeholder="{{'Seleziona una provincia' }}"
                  type="text" />
                <nz-autocomplete #teacherAutocomplete>
                  <nz-auto-option [nzDisabled]="true">
                    <div class="d-flex flex-row justify-content-center">
                      <nz-spin nzSimple></nz-spin>
                    </div>
                  </nz-auto-option>
                  <ng-container>
                    <nz-auto-option *ngFor="let worker of optionsCities" [nzLabel]="worker.user"
                      [nzValue]="worker.denominazione_in_italiano"
                      (click)="onInput = worker.denominazione_in_italiano">
                      {{ worker.denominazione_in_italiano }}
                    </nz-auto-option>
                  </ng-container>
                </nz-autocomplete>
              </nz-input-group>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Via</nz-form-label>
              <nz-input-group>
                <input formControlName="via" [nzAutocomplete]="teacherAutocomplete" class="search-input"
                  (input)="onInput($event, 'via')" nz-input placeholder="{{'Seleziona una via' }}" type="text" />
                <nz-autocomplete #teacherAutocomplete>
                  <nz-auto-option [nzDisabled]="true">
                    <div class="d-flex flex-row justify-content-center">
                      <nz-spin nzSimple></nz-spin>
                    </div>
                  </nz-auto-option>
                  <ng-container>
                    <nz-auto-option *ngFor="let worker of optionsCities" [nzLabel]="worker.user"
                      [nzValue]="worker.denominazione_in_italiano"
                      (click)="onInput = worker.denominazione_in_italiano">
                      {{ worker.denominazione_in_italiano }}
                    </nz-auto-option>
                  </ng-container>
                </nz-autocomplete>
              </nz-input-group>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="Nome">Descrizione</nz-form-label>
              <nz-form-control nzErrorTip="Please input your username!">
                <nz-textarea-count>
                  <textarea formControlName="description" nz-input rows="2"
                    placeholder="inserisci una descrizione"></textarea>
                </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </div>



         
          <div class="col-12">
            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Registrati</button>
          </div>
        </div>
      </form>
    </div>

  </nz-tab>
</nz-tabset> -->