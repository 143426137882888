<div *ngIf="show" class="row container">
  <div class="col-12 title">
    <h4 class="header-widget">Stato delle vendite</h4>
  </div>
  <div class="col-3"></div>
  <div class="col-4 pb">
    <div class="d-flex flex-column">
      <span class="text-mini-widget">Oggi</span>
      <div>
        <span class="data-mini-widget">{{data.oggi}}</span> kg
      </div>
    </div>
  </div>
  <div class="col-5 pb">
    <div class="d-flex flex-column">
      <span class="text-mini-widget">Mese</span>
      <div>
        <span class="data-mini-widget">{{data.mese}}</span> kg
      </div>
    </div>
  </div>
</div>