import { Component } from '@angular/core';

@Component({
  selector: 'app-card-distributor-lots',
  templateUrl: './card-distributor-lots.component.html',
  styleUrls: ['./card-distributor-lots.component.scss']
})
export class CardDistributorLotsComponent {

}
