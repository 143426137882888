<div class="chat-container">
     <div class="messages">
          <div *ngFor="let message of messages" class="message">
               <div class="message-content"
                    [ngClass]="{'user-message': message.type === 'user', 'bot-message': message.type === 'bot'}">
                    {{ message.text }}
               </div>
          </div>
     </div>
     <div class="input-container">
          <input [(ngModel)]="userInput" (keydown.enter)="sendMessage()" placeholder="Type a message..." />
          <button (click)="sendMessage()">Send</button>
     </div>
</div>