<div class="container">
  <div class="d-flex justify-content-between">
    <h4 class="header-widget">Prodotti in vendita</h4>
    <span style="cursor: pointer;" (click)="explodeWidget()">
      <img src="/assets/img/arrow.svg" alt="">
    </span>
  </div>

  <div class="current-date">
    Aprile 2024
  </div>

  <div class="carousel">
    <div id="carouselExample" class="carousel slide" #carouselElement>
      <div class="carousel-inner">
        <div *ngFor="let item of data; let i = index" class="carousel-item" [class.active]="i === 0"
          class="carousel-item active">
          <div class="item" [id]="i">
            <div class="lotto-info d-flex align-items-center">
              <div class="img">
                <img src="{{item.image}}" alt="">
              </div>
              <div class="info d-flex flex-column">
                <span class="lotto">Lotto {{item.lotto}}</span>
                <span class="type">{{item.type}}</span>
                <span class="prod">{{item.prod}}</span>
              </div>
            </div>
            <div class="progress-container">
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" [style.width.%]="(item.sold / item.total) * 100"
                  [attr.aria-valuenow]="(item.sold / item.total) * 100" [attr.aria-valuemin]="item.sold"
                  [attr.aria-valuemax]="item.total">
                </div>
              </div>
            </div>

            <div class="progress-info">
              <span class="Venduto">Venduto: </span> <span class="data-sold">{{item.sold}}/{{item.total}} kg</span>
            </div>

          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span>
          <img src="../../../../../../assets/icon/previous-icon.svg" alt="">
        </span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span>
          <img src="../../../../../../assets/icon/next-icon.svg" alt="">
        </span>
      </button>
    </div>
  </div>

  <div *ngFor="let item of info" class="additional-info bg-additional-info">
    <h5 class="title">Informazioni aggiuntive</h5>
    <div class="label">
      Stato: <span class="stato-data"
        [ngClass]="{'rovinato': item.status == 'Rovinato', 'buono': item.status == 'Buono', 'discreto': item.status == 'Discreto'}">{{item.status}}</span>
    </div>
    <div class="label">
      Quantitá scartata: <span class="data">{{item.rejected}}</span>
    </div>
  </div>

</div>