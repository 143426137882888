<div *ngIf="showWidget" class="piantagioni-container align-items-center">
  <div class="d-flex justify-content-between">
    <h4 class="header-widget">In magazzino</h4>
    <span style="cursor: pointer;" (click)="explodeWidget()">
      <img src="/assets/img/arrow.svg" alt="">
    </span>
  </div>
  <div>
    <div class="box-chart">
      <canvas id="doughnutChart" baseChart [data]="doughnutChartData" [type]="'doughnut'"
        [options]="doughnutChartOptions">
      </canvas>
    </div>

    <!-- <div *ngFor="let item of legendData">
      <div>{{item.Arancia}}</div>
    </div> -->

    <div class="legenda row">
      <div class="col-12 d-flex justify-content-between" *ngFor="let item of legendData">
        <div class="d-flex flex-row">
          <div class="color-spot" [style.background-color]="item.color"></div>
          <div class=""><strong>{{item.label}}</strong></div>
        </div>
        <div class="d-flex flex-row justify-content-end flex-wrap">
          <div class="value">{{item.value}} Kg</div>
        </div>
      </div>
    </div>

  </div>

</div>

<div *ngIf="!showWidget" class="piantagioni-container align-items-center">
  <h1>{{noData}}</h1>
</div>