import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpContextToken,
} from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { HttpContextConfig } from '../models/http-context-config';

export const CONFIG = new HttpContextToken<HttpContextConfig>(
  () => new HttpContextConfig()
);

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.getAccessTokenFromCookie();

    if (accessToken) {
      request = request.clone({
        withCredentials: true,
        setHeaders: {
          Authorization: 'Bearer ' + accessToken,
        },
      });
    }

    const isSpecial = this.isSpecialUrl(request.url);
    const isLoginRoute = this.isAuthLoginRoute();

    if (request.context.get(CONFIG)) {
      let config = request.context.get(CONFIG);
      if (!config.noSpinner) {
        this.loaderService.setLoading(true, request.url);
      }
      if (config.isPublic) {
      }
    }

    return next.handle(request).pipe(
      map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          if (!isSpecial || isLoginRoute) {
            this.loaderService.setLoading(false, request.url);
          }
        }
        return evt;
      }),
      catchError((err) => {
        if (!isSpecial || isLoginRoute) {
          this.loaderService.setLoading(false, request.url);
        }

        if (err.status === 401) {
          this.router.navigate(['auth/login']);
        }
        return throwError(err.error);
      })
    );
  }

  private getAccessTokenFromCookie(): string | null {
    const accessToken = this.getCookie('access_token');
    if (this.isAccessTokenValid(accessToken)) {
      return accessToken;
    } else {
      return null;
    }
  }

  private isAccessTokenValid(accessToken: string): boolean {
    if (!accessToken) {
      return false;
    }

    const tokenParts = accessToken.split('.');
    if (tokenParts.length !== 3) {
      return false;
    }

    try {
      const payload = JSON.parse(atob(tokenParts[1]));
      const expiration = payload.exp * 1000;

      return expiration > Date.now();
    } catch (error) {
      console.error('Error decoding JWT token:', error);
      return false;
    }
  }

  private getCookie(name: string): string {
    const cookieName = encodeURIComponent(name) + '=';
    const cookieArray = document.cookie.split(';');
    for (let cookie of cookieArray) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return decodeURIComponent(
          cookie.substring(cookieName.length, cookie.length)
        );
      }
    }
    return '';
  }

  private isSpecialUrl(url: string): boolean {
    return url.includes('/me') || url.includes('/get-user-role') || url.includes('/widgets_role');
  }

  private isAuthLoginRoute(): boolean {
    return this.router.url === '/auth/login';
  }
}
