import { Component } from '@angular/core';

@Component({
  selector: 'app-historical-lots',
  templateUrl: './historical-lots.component.html',
  styleUrls: ['./historical-lots.component.scss']
})
export class HistoricalLotsComponent {

  actors = [
    {lable: 'Produttore', link: '/hh', content: 'contenuto'},
    {lable: 'Selezione e stoccaggio', link: '/hh', content: 'contenuto'},
    {lable: 'Distributore', link: '/hh', content: 'contenuto'},
    {lable: 'Punto vendita', link: '/hh', content: 'contenuto'},
  ];

}
