<div class="d-flex justify-content-between">
    <div>
        <h4 class="header-widget">I tuoi trattamenti</h4>
    </div>
    <div class="d-flex flex-row align-items-center size-sel">
        <!-- * Select -->
        <nz-select [nzPlaceHolder]="placeholder" [(ngModel)]="selectedField" (ngModelChange)="getMyBatch()"
            class="custom-select placeholder-color" style="display: flex; align-items:center">
            <nz-option *ngFor="let field of (fields)" [nzLabel]="field" [nzValue]="field" class="color">
                {{field}}</nz-option>
        </nz-select>

        <!-- * Bottone navigazione -->
        <div class="ms-4" (click)="explodeWidget2()">
            <img src="/assets/img/arrow.svg" alt="" class="cursor-pointer">
        </div>
    </div>
</div>

<div class="mt-4 fs-12">
    Lotto: <span class="lotto ms-2 px-3 py-1">{{form.value.lotto}}</span>
</div>

<div class="mt-3 d-flex justify-content-between">
  <div *ngFor="let item of status_card" class="card-content" [ngStyle]="{
    'background-image': 'url(' + item.img + ')',
    'background-position': item.type == 'Prodotto' ? 'left 10% bottom 44%' : 'bottom left',
    'background-repeat': 'no-repeat',
    'background-size': item.type == 'Prodotto' && actor == 5 ? '35%' : actor == 5 ? '40%' : 'contain'
  }">
        <div class="mini-card-title fs-14 mt-2"><span class="ms-4">{{ item.type }}</span> </div>
        <div class="margin-lable fs-14 mt-3">{{ item.label }}</div>
        <div *ngIf="item.date" class="margin-lable fs-20">{{ item.date | date:'dd/MM/yyyy' }}</div>
        <div *ngIf="item.data" class="margin-lable fs-20">{{ item.data }}</div>

    </div>
</div>
