import { Component, OnInit } from '@angular/core';
import { ChartData, ChartEvent, ChartOptions, ChartType, Plugin, PluginChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-SellerInStock',
  templateUrl: './SellerInStock.component.html',
  styleUrls: ['./SellerInStock.component.scss']
})
export class SellerInStockComponent implements OnInit {

  legendData: any;
  showWidget = false;
  noData!: string;
  text: any

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ['#EF7F00', '#FAC36A'],
        hoverOffset: 4,
      }
    ]
  };

  public doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '70%',
    radius: '80%',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        // position: 'top',
        display: false
      },
    },

  };

  constructor(private _chartService: ChartService, private _loggingService: LoggingService, private router: Router,
    private _utils: Utils) { }

  ngOnInit() {
    this.getPlantsWidgetData();
  }

  getPlantsWidgetData() {
    this._chartService.getWidgetVenditoreInStockData().subscribe(r => {
      this.text = r.total;

        this.doughnutChartData.datasets[0].data = r.data.widgetData.datasets.data;
        this.doughnutChartData.labels = r.data.widgetData.labels;
        this.doughnutChartData.datasets[0].backgroundColor = r.data.widgetData.datasets.backgroundColor;
        this.legendData = r.data.percentage;
        console.log('My log', this.legendData);
        this.showWidget = true;

    });
  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 1);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-doughnut-and-pie-chart'
    }
    this._loggingService.logClick(data).subscribe(r =>{
      console.log(r);

    })
  }

}
