import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SidebarService } from 'src/app/components/sidebar.service';
import { GeolocationService } from 'src/app/components/geolocation.service';
import { ComponentService } from 'src/app/components/component.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.component.html',
  styleUrls: ['./consumer.component.scss']
})
export class ConsumerComponent implements OnInit {

  position: GeolocationPosition | null = null;

  products: any;

  objectKeys = Object.keys;

  icons: any = {
    fruits: '../../../../../assets/img/frutta-consumer.svg',
    snacks: '../../../../../assets/img/snack1-consumer.svg',
    sweets: '../../../../../assets/img/dolci-consumer.svg',
    soft_drinks: '../../../../../assets/img/bevamde-consumer.svg',
    confitures: '../../../../../assets/img/marmellate-consumer.svg',
    sauces: '../../../../../assets/img/condimenti-consumer.svg'
  };

  // cards = [
  //   {
  //     icon: '../../../../../assets/img/frutta-consumer.svg',
  //     type: 'Frutta',
  //     quantity: 5
  //   },
  //   {
  //     icon: '../../../../../assets/img/bevamde-consumer.svg',
  //     type: 'Bevande',
  //     quantity: 5
  //   },
  //   {
  //     icon: '../../../../../assets/img/marmellate-consumer.svg',
  //     type: 'Marmellate e conserve',
  //     quantity: 5
  //   },
  //   {
  //     icon: '../../../../../assets/img/snack1-consumer.png',
  //     type: 'Snack e spuntini',
  //     quantity: 5
  //   },
  //   {
  //     icon: '../../../../../assets/img/dolci-consumer.svg',
  //     type: 'Dolci',
  //     quantity: 5
  //   },
  //   {
  //     icon: '../../../../../assets/img/condimenti-consumer.svg',
  //     type: 'Salse e condimenti',
  //     quantity: 5
  //   }
  // ]

  constructor(
    private authService: AuthService,
    private geolocationService: GeolocationService,
    private sidebarService: SidebarService,
    private _ComponentService: ComponentService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getLocation()
    this.sidebarService.hideSidebar();
    this.authService.getUser().subscribe(r => {
      let role_id = localStorage.getItem('role_id');
    });
  }


  ngOnDestroy(): void {
    this.sidebarService.showSidebar();
  }

  getLocation(): void {
    this.geolocationService.getCurrentPosition()
      .then(position => {
        this.position = position;
        let coords = {
          latitude: this.position.coords.latitude,
          longitude: this.position.coords.longitude
        }
        this.getPointProducts(coords)
        
      })
      .catch(error => {
        console.error('Error getting location', error);
      });
  }

  getPointProducts(coords: object) {
    this._ComponentService.venditorCordinate(coords).subscribe(r => {
      console.log('PROD', r);
      
      const groupedProducts = r.data.reduce((acc: { [x: string]: any[]; }, product: { transformation_name: any; }) => {
        const key = product.transformation_name;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(product);
        return acc;
      }, {});
  
      this.products = Object.keys(groupedProducts).map(key => ({
        icon: this.icons[key] || 'default-icon-path',
        type: this.transformKeyToType(key),
        quantity: groupedProducts[key].length,
        qrPats: this.getPath(groupedProducts, key)
      }));

    });
  }

  transformKeyToType(key: string): string {
    const types: any = {
      fruits: 'fruits',
      snacks: 'snacks',
      sweets: 'sweets',
      soft_drinks: 'soft_drinks',
      confitures: 'confitures',
      sauces: 'sauces'
      // Aggiungi altri mapping per altre categorie
    };
    return types[key] || 'Unknown';
  }

  getPath(prod: any, key: any) {
    console.log(key);
    let path: any = []
    switch (key) {
      case 'fruits':
        prod.fruits.forEach((element: any) => {
          let data = 
          {
            qrPath: element.qr_code_path,
            batch: element.batch_unique_identifier,
            prodName: element.product_name
          }
          path.push(data)
        });
        return path;

        case 'snacks':
          prod.snacks.forEach((element: any) => {
            let data = 
          {
            qrPath: element.qr_code_path,
            batch: element.batch_unique_identifier,
            prodName: element.product_name
          }
          path.push(data)
          });
          return path;


          case 'sweets':
          prod.sweets.forEach((element: any) => {
            let data = 
          {
            qrPath: element.qr_code_path,
            batch: element.batch_unique_identifier,
            prodName: element.product_name
          }
          path.push(data)
          });
          return path;

          case 'soft_drinks':
          prod.soft_drinks.forEach((element: any) => {
            let data = 
          {
            qrPath: element.qr_code_path,
            batch: element.batch_unique_identifier,
            prodName: element.product_name
          }
          path.push(data)
          });
          return path;


          case 'confitures':
          prod.confitures.forEach((element: any) => {
            let data = 
          {
            qrPath: element.qr_code_path,
            batch: element.batch_unique_identifier,
            prodName: element.product_name
          }
          path.push(data)
          });
          return path;


          case 'sauces':
          prod.sauces.forEach((element: any) => {
            let data = 
          {
            qrPath: element.qr_code_path,
            batch: element.batch_unique_identifier,
            prodName: element.product_name
          }
          path.push(data)
          });
          return path;

    
      default:
        break;
    }
    
    
  }

  goToProd(qrPats: any, card: any) {

    let data = 
    {
      icon: card.icon,
      type: card.type
    }
    this.router.navigate(['/consumer/products'], { state: { data: data } });
  }

}
