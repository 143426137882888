<div class="container">
  <div class="d-flex justify-content-between align-items-center header">
    <div class="title">Storico lotti</div>
    <div class="search">
      <input type="search" [(ngModel)]="search" (keyup.enter)="searchData()" nz-input placeholder="Cerca" />
    </div>
  </div>

  <div class="d-flex justify-content-between align-content-center">
    <div class="filters d-flex align-items-center">
      <div *ngFor="let item of productsType" class="d-flex flex-row align-items-center filter"
        [ngClass]="{'selected': item.selected, 'not-selected': !item.selected}" (click)="selectProductType(item.id)">
        <div *ngIf="item.path" class="img">
          <img [src]="item.path" alt="">
        </div>
        <div class="label">{{item.label}}</div>
      </div>
    </div>

    <div *ngIf="userRole != 2" class="d-flex align-items-center">
      <div *ngIf="alert > 0" class="notify"> <img src="../../../assets/icon/notifications.svg" alt=""> Hai {{alert}}
        lotti in attesa di conferma
      </div>
      <div class="select-status">
        <nz-select nzPlaceHolder="Seleziona stato" [(ngModel)]="status" (ngModelChange)="getFilterDataStatus()"
          class="select-list custom-select">
          <ng-container>
            <nz-option nzLabel="Confermato" nzValue="confermato"></nz-option>
            <nz-option nzLabel="Da confermare" nzValue="attesa"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>
  </div>

  <div class="table-cont">
    <!-- <drw-data-table [displayedColumns]="cols" ></drw-data-table> -->
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="font-title" *ngFor="let col of cols">{{col}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data" [ngClass]="{'pointer': active}">
          <td class="text-style-big padd" (mouseup)="goTo(item.batch_id)">{{ item?.batch_id}}</td>
          <td class="text-style-medium" (mouseup)="goTo(item.batch_id)">{{ item?.product_name}}</td>
          <td class="text-style-medium" (mouseup)="goTo(item.batch_id)">{{ item?.productor }}</td>
          <td *ngIf="item?.quantity" class="text-style-medium" (mouseup)="goTo(item.batch_id)">{{ item?.quantity}} kg
          </td>
          <td (mouseup)="goTo(item.batch_id)">
            <div
              [ngClass]="{'spedito': item?.status == 'spedito', 'consegnato': item?.status == 'consegnato', 'transito': item?.status == 'in transito', 'in-arrivo': item?.status == 'In arrivo'}">
              <div class="circle"></div>
              <div class="text-style-big">{{ item?.status}}</div>
            </div>
          </td>
          <td *ngIf="userRole != 2 && item.batch_id !== 'Nessun dato'" class="text-style-medium content-conferm">
            <div *ngIf="item.requesting_user_id != item.receiving_user_id" class="conferm-buttton"
              (mouseup)="conferm(item.batch_id)"
              [ngClass]="{'to-conferm': !item.is_waiting, 'confermed': item.is_waiting}">
              {{item.is_waiting == 0 ? 'Confermato' : 'Conferma'}}
            </div>

            <!-- Secondo blocco: se requesting_user_id e receiving_user_id sono entrambi uguali a 5 -->
            <div *ngIf="item.requesting_user_id == item.receiving_user_id" class="conferm-buttton"
              (mouseup)="conferm(item.batch_id)"
              [ngClass]="{'to-conferm': item.confirmed, 'confermed': !item.confirmed}">
              {{item.confirmed == 1 ? 'Confermato' : 'Conferma'}}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>