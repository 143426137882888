<div [ngClass]="{'form-container-lg row': containerLg, 'form-container-sm': containersm}">

  <div class="col-6">
    <div class=" img-container">
      <img src="/assets/img/login.svg" alt="">
    </div>
  </div>

  <div class="col-6">
    <div class="form-cont">
      <div class="mt-4 icon-container d-flex align-items-center">
        <img src="/assets/icon/logo-citrace-color-1.svg" alt="">
        <!-- <span class="ms-2 title-color fs-26">Ci<b>TRace</b></span> -->
      </div>

      <div>
        <h2 class="fs-1">Login</h2>
        <p class="fs-14">Inserisci la tua email e password per accedere alla dashboard</p>
        <form nz-form [formGroup]="form" class="login-form" (ngSubmit)="submitForm()">
          <div class="row">
            <div class="col-12">
              <nz-form-item>
                <nz-form-control nzErrorTip="Inserisci la mail">
                  <nz-input-group>
                    <input type="text" class="h-input" nz-input formControlName="email" placeholder="Email" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-12">
              <!-- <nz-form-item>
                <nz-form-control nzErrorTip="Inserisci la password">
                  <nz-input-group nzPrefixIcon="lock">
                    <input type="password" nz-input formControlName="password" placeholder="Password" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item> -->

              <nz-form-control nzErrorTip="Inserisci la password">
                <nz-input-group [nzSuffix]="suffixTemplate">
                  <input [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Password"
                    formControlName="password" />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <span nz-icon class="font-icon" [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                    (click)="togglePasswordVisibility()"></span>
                </ng-template>
              </nz-form-control>

            </div>

            <div class="col-12 btn-cont">
              <button nz-button class="login-form-button login-form-margin button-principal" [disabled]="!form.valid"
                [nzType]="'primary'">Accedi</button>
            </div>

            <div class="col-12">
              <span class="create-account-cont">Non hai un account? </span>
              <span class="create-account" (click)="toCreate()">
                <a>Registrati</a>
              </span>
            </div>

            <div class="col-12">
              <a class="login-form-forgot" routerLink="/auth/password-forgotten">Password dimenticata?</a>
            </div>

            <!-- <div class="col-12">
              <div class="login-form-margin row">
                <div class="col-lg-6 col-sm-12 ">
                  <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Log in</button>
                  <label class="stay-tuned" nz-checkbox formControlName="remember">
                    <span>Resta collegato</span>
                  </label>
                </div>
                <div class="col-lg-6 col-sm-12 frog-pass ">
                  Oppure
                  <span class="create-account" (click)="toCreate()">
                    <a>crea un account</a>
                  </span>
                </div>
              </div>
              <nz-form-label [nzSpan]="24" nzFor="Email">
                <a class="login-form-forgot">password dimenticata?</a>
              </nz-form-label>
            </div> -->

          </div>
        </form>
      </div>
    </div>
  </div>


</div>