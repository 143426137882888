<div class="row mb-3">
    <div class="col-md-9">
        <div class="card bd-rad bg-color mb-3" *ngIf="widget.includes('app-distributor-lots'); else noData">
            <div class="card-body">
                <app-distributor-lots [actor]="6"></app-distributor-lots>
            </div>
        </div>

        <div class="" *ngIf="widget.includes('app-latestShipments'); else noData">
            <div class="card-body">
                <app-latestShipments [actor]="'distributor_id'"></app-latestShipments>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div *ngIf="widget.includes('app-shipping-status'); else noData">
            <div>
                <div class="card bd-rad bg-color h-100">
                    <div class="card-body">
                        <app-shipping-status></app-shipping-status>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-9">
        <div class="card bd-rad bg-color"
            *ngIf="widget.includes('app-widget-arriving-departing-products'); else noData">
            <div class="card-body">
                <app-widget-arriving-departing-products></app-widget-arriving-departing-products>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card bd-rad bg-feedback" *ngIf="widget.includes('feedback'); else noData">
            <div class="card-body">
                <app-widget-feedback></app-widget-feedback>
            </div>
        </div>
    </div>
</div>


<ng-template #noData>
    <div class="card bd-rad bg-no-data h-100 mb-1">
        <div class="card-body">
            <app-no-data></app-no-data>
        </div>
    </div>
</ng-template>

<!-- <ng-template #noData>
    <div class="card bd-rad bg-no-data h-100 mb-1">
        <div class="card-body">
            <app-no-data></app-no-data>
        </div>
    </div>
</ng-template> -->